import produce from "immer";
import reportApi from "lib/api/7ds";
import { GetListParams } from "types/api";
import { I7DReport } from "types/responses/7d";
import create from "zustand";

interface ReportStore {
  list: Array<I7DReport>;
  detail: Record<string, I7DReport>;
  total: number;
  getList: (params: GetListParams) => void;
  getDetail: (id: number) => void;
  delete: (id: number) => Promise<boolean>;
}

const useReportStore = create<ReportStore>((set, get) => ({
  list: [],
  detail: {},
  total: 0,
  getList: async (params) => {
    const res = await reportApi.getReportList(params);

    if (!res.data) {
      return;
    }

    const { data, error } = res.data;

    if (error) {
      return;
    }

    set({ list: data.data, total: data.total });
  },
  getDetail: async (id: number) => {
    const res = await reportApi.getReportDetail(id);

    if (!res.data) {
      return;
    }

    const { data, error } = res.data;

    if (error) {
      return;
    }

    return set(
      produce((state: ReportStore) => {
        if (data) {
          state.detail[id] = data;
        }
      }),
    );
  },
  delete: async (id: number) => {
    const res = await reportApi.deleteReport(id);

    if (!res.data) {
      return false;
    }

    const { error } = res.data;

    if (error) {
      return false;
    }

    return true;
  },
}));

export default useReportStore;
