import request from "configs/request";
import { APIPromiseResponse } from "types/api";

const ROLE_URL = "/role";

const getRoleModules = (): APIPromiseResponse => {
  return request.get(`${ROLE_URL}/module`);
};

const getRoleDetail = (id: number): APIPromiseResponse => {
  return request.get(`${ROLE_URL}/detail/${id}`);
};

const getRoleList = (
  type: "all" | "mini" = "all",
  page = 1,
  limit = 10,
  query?: string,
): APIPromiseResponse => {
  return request.get(`${ROLE_URL}/list`, {
    params: { type, page, limit, query },
  });
};

const addRole = (data: any): APIPromiseResponse => {
  return request.post(`${ROLE_URL}/add`, data);
};

const updateRole = (id: number, data: any): APIPromiseResponse => {
  return request.put(`${ROLE_URL}/update/${id}`, data);
};

const deleteRole = (id: number): APIPromiseResponse => {
  return request.delete(`${ROLE_URL}/delete/${id}`);
};

export default {
  getRoleModules,
  getRoleDetail,
  getRoleList,
  addRole,
  updateRole,
  deleteRole,
};
