import MasterCategory from "features/master-data/category";
import MainLayout from "global/MainLayout";

function MasterCategoryPage() {
  return (
    <MainLayout>
      <MasterCategory />
    </MainLayout>
  );
}

export default MasterCategoryPage;
