import { Card } from "components/Base";
import InputSearch from "global/InputSearch";
import PageTitle from "global/PageTitle";
import useInput from "hooks/useInput";
import TableLeaderboard from "./TableLeaderboard";

function Leaderboard() {
  const [query, setQuery] = useInput("");
  return (
    <>
      <PageTitle title="Leaderboard Point" />
      <Card>
        <InputSearch value={query} onChange={setQuery} />
        <TableLeaderboard keyword={query} />
      </Card>
    </>
  );
}

export default Leaderboard;
