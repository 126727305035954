import { Text } from "components/Base";

interface LabelProps {
  label: string;
  required?: boolean;
}

function Label({ label, required }: LabelProps) {
  return (
    <Text fontSize="14px" mb="-4px">
      {label}{" "}
      {required && (
        <Text as="span" color="$red400">
          *
        </Text>
      )}
    </Text>
  );
}

export default Label;
