import { Icon, Flex, Text } from "components/Base";

interface PasswordItemProps {
  label: string;
  success?: boolean;
}

function PasswordItem({ label, success }: PasswordItemProps) {
  const textColor = success ? "$teal600" : "$coolGray400";
  return (
    <Flex align="flex-start" mb="16px">
      <Icon alt="check" src="check-circle" color={textColor} mr="10px" />
      <Text align="left" color={textColor} size="$xs" weight="$regular">
        {label}
      </Text>
    </Flex>
  );
}

export default PasswordItem;
