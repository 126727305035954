import { Card } from "components/Base";
import InputSearch from "global/InputSearch";
import PageTitle from "global/PageTitle";
import useInput from "hooks/useInput";
import { useParams } from "react-router-dom";
import TableCerita from "./components/TableCerita";

function CeritaDesa() {
  const { id } = useParams();
  const [query, setQuery] = useInput("");

  if (!id) {
    return null;
  }

  return (
    <>
      <PageTitle title="List Inspirasi Desa" />
      <Card>
        <InputSearch value={query} onChange={setQuery} />
        <TableCerita keyword={query} userId={id} />
      </Card>
    </>
  );
}

export default CeritaDesa;
