import { Card, Grid, Tag } from "components/Base";
import { InfoItem } from "components/Info";
import PageTitle from "global/PageTitle";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import useFAQStore from "./faqStore";

function DetailFAQ() {
  const { id } = useParams();
  const [faq, getDetail] = useFAQStore((s) => [s.detail, s.getDetail]);

  useEffect(() => {
    if (id) {
      getDetail(Number(id));
    }
  }, [id]);

  if (!id || !faq[id]) {
    return null;
  }

  const detail = faq[id];

  return (
    <>
      <PageTitle title="Detail" backUrl="/faq" />
      <Card p="32px">
        <Grid columns="repeat(2, 1fr)" gap="24px" mb="24px">
          <InfoItem label="Judul" value={detail.title} />
          <InfoItem label="Status">
            <Tag rounded color={detail.active ? "green" : "red"}>
              {detail.active ? "Aktif" : "Tidak aktif"}
            </Tag>
          </InfoItem>
        </Grid>
        <InfoItem label="Deskripsi" value={detail.content} />
      </Card>
    </>
  );
}

export default DetailFAQ;
