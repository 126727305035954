import { message } from "antd";
import PageTitle from "global/PageTitle";
import notification from "lib/api/notification";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { notifySuccess } from "utils/notification";
import FormNotif from "./components/FormNotif";
import useNotificationStore from "./notifStore";

function EditNotification() {
  const [detail, getDetail] = useNotificationStore((s) => [
    s.detail,
    s.getDetail,
  ]);

  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (id) {
      getDetail(Number(id));
    }
  }, [id]);

  const handleSubmit = async (data: any) => {
    const res = await notification.update(Number(id), data);

    if (!res.data) {
      message.error("Gagal mengubah notifikasi");
      return;
    }

    const { error } = res.data;

    if (error) {
      message.error(error);
      return;
    }

    notifySuccess({
      message: "Berhasil mengubah notifikasi",
    });

    // Redirect to list page
    navigate("/notification");
  };

  if (!id || !detail[id]) {
    return null;
  }

  const notif = detail[id];

  return (
    <>
      <PageTitle title="Mengubah Notification" backUrl="/notification" />
      <FormNotif initialValues={notif} onSubmit={handleSubmit} />
    </>
  );
}

export default EditNotification;
