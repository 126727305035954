import { Button, Card, Flex } from "components/Base";
import { formatISO } from "date-fns";
import exportFromJSON from "export-from-json";
import ButtonAdd from "global/ButtonAdd";
import InputSearch from "global/InputSearch";
import PageTitle from "global/PageTitle";
import useInput from "hooks/useInput";
import admin from "lib/api/admin";
import { useState } from "react";
import { HiOutlineDownload } from "react-icons/hi";
import { IoMdFolder } from "react-icons/io";
import ModalImport from "./components/ModalImport";
import TableAdmin from "./components/Table";

function UserManagement() {
  const [query, setQuery] = useInput("");
  const [showImport, setShowImport] = useState<boolean>(false);

  const handleDownload = async () => {
    const res = await admin.download();

    if (!res.data) {
      return;
    }

    const { error, data } = res.data;

    if (error) {
      return;
    }

    const fileName = `admin-${formatISO(new Date())}`;
    const exportType = exportFromJSON.types.xls;

    exportFromJSON({ data, fileName, exportType });
  };

  return (
    <>
      <PageTitle title="List User Admin">
        <Flex gap="16px">
          <Button
            color="cyan"
            onClick={() => setShowImport(true)}
            icon={<IoMdFolder />}
          >
            Import data
          </Button>
          <ButtonAdd to="/user-management/add" label="Tambah Data" />
        </Flex>
      </PageTitle>
      <Card>
        <Flex align="center" justify="space-between">
          <InputSearch value={query} onChange={setQuery} />
          <Button
            icon={<HiOutlineDownload size={16} />}
            color="gray"
            onClick={handleDownload}
          >
            Download Data
          </Button>
        </Flex>
        <TableAdmin keyword={query} />
      </Card>
      <ModalImport visible={showImport} onClose={() => setShowImport(false)} />
    </>
  );
}

export default UserManagement;
