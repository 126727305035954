import EditFAQ from "features/faq/edit-faq";
import MainLayout from "global/MainLayout";

function EditFAQPage() {
  return (
    <MainLayout>
      <EditFAQ />
    </MainLayout>
  );
}

export default EditFAQPage;
