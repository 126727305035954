import { Grid } from "components/Base";
import statsApi from "lib/api/stats";
import { useEffect, useState } from "react";
import LeaderboardList from "./components/Leaderboard";
import StatCard from "./components/StatCard";
import Story from "./components/Story";

function Dashboard() {
  const [stats, setStats] = useState({
    user: 0,
    post: 0,
    story: 0,
    post_category: 0,
    report: 0,
  });

  const getStats = async () => {
    const res = await statsApi.getStats();

    if (!res.data) {
      return;
    }

    if (res.status === 200) {
      setStats(res.data.data);
    }
  };

  useEffect(() => {
    getStats();
  }, []);

  return (
    <>
      <Story />
      <Grid columns="repeat(4, 1fr)" gap="36px" mb="40px">
        <StatCard
          label="User"
          value={stats.user}
          url="/users"
          color="lightBlue"
        />
        <StatCard label="Inspirasi Desa" value={stats.post} color="green" />
        <StatCard
          label="Kategori Cerita"
          value={stats.post_category}
          url="/master-data/category"
          color="purple"
        />
        <StatCard
          label="Laporan 7D"
          value={stats.report}
          url="/report-7d"
          color="orange"
        />
      </Grid>
      <LeaderboardList />
    </>
  );
}

export default Dashboard;
