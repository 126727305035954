import DetailReportPost from "features/report-management/post/detail-report-post";
import MainLayout from "global/MainLayout";

function DetailReportPostPage() {
  return (
    <MainLayout>
      <DetailReportPost />
    </MainLayout>
  );
}

export default DetailReportPostPage;
