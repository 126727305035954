import { TableColumnsType } from "antd";
import { Flex, Image, Text } from "components/Base";
import { format } from "date-fns";
import ModalDelete from "global/Modals/ModalDelete";
import TableList from "global/TableList";
import ButtonAction from "global/TableList/ButtonAction";
import { TableBaseProps } from "global/TableList/types";
import useDebounce from "hooks/useDebounce";
import useLoaded from "hooks/useLoaded";
import { useEffect, useState } from "react";
import useStepStore from "../steps/stepStore";

function TableSteps({ keyword }: TableBaseProps) {
  const [list, total, getList, deleteStep] = useStepStore((s) => [
    s.list,
    s.total,
    s.getList,
    s.delete,
  ]);
  const loaded = useLoaded();

  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const debouncedKeyword = useDebounce(keyword, 500);

  const [visible, setVisible] = useState(false);
  const [selectedId, setSelectedId] = useState(0);

  useEffect(() => {
    if (debouncedKeyword !== undefined && loaded) {
      const newPage = 1;
      setPage(newPage);

      const query = debouncedKeyword || undefined;
      getList({ page: newPage, limit, query });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedKeyword, loaded]);

  const handleChangePage = (page: number, limit: number) => {
    setPage(page);
    setLimit(limit);

    const query = debouncedKeyword || undefined;
    getList({ page, limit, query });
  };

  const handleShowDelete = (id: number) => {
    setSelectedId(id);
    setVisible(true);
  };

  const handleDelete = async () => {
    const del = await deleteStep(selectedId);

    if (del) {
      setVisible(false);
      setSelectedId(0);

      const query = debouncedKeyword || undefined;
      getList({ page, limit, query });
    }
  };

  const columns: TableColumnsType<TableBaseProps> = [
    {
      title: "Judul",
      dataIndex: "title",
      key: "name",
      width: "30%",
      render: (title, { image: media }: any) => (
        <Flex gap="12px" alignItems="center">
          <Image src={media} alt={title} br="$rounded-lg" autofit size="42px" />
          <Flex
            css={{
              display: "-webkit-box",
              "-webkit-line-clamp": 2,
              "-webkit-box-orient": "vertical",
              overflow: "hidden",
              w: "calc(100% - 64px)",
            }}
          >
            <Text>{title}</Text>
          </Flex>
        </Flex>
      ),
    },
    {
      title: "Deskripsi",
      dataIndex: "content",
      key: "description",
      width: "20%",
      render: (description) => (
        <Flex
          css={{
            display: "-webkit-box",
            "-webkit-line-clamp": 2,
            "-webkit-box-orient": "vertical",
            overflow: "hidden",
          }}
        >
          <Text>{description}</Text>
        </Flex>
      ),
    },
    {
      title: "Tanggal Buat",
      dataIndex: "created_at",
      key: "created_at",
      render: (date: string) => format(new Date(date), "dd/MM/yyyy"),
    },
    {
      title: "Tanggal Edit",
      dataIndex: "updated_at",
      key: "updated_at",
      render: (date: string) => format(new Date(date), "dd/MM/yyyy"),
    },
    {
      title: "",
      key: "action",
      render: (_, { id }: any) => (
        <ButtonAction
          id={id}
          onDelete={() => handleShowDelete(id)}
          url="/step-7d"
        />
      ),
    },
  ];

  return (
    <>
      <TableList
        columns={columns}
        dataSource={list}
        total={total}
        page={page}
        limit={limit}
        onChangePage={handleChangePage}
        bordered
      />
      <ModalDelete
        visible={visible}
        onCancel={() => setVisible(false)}
        onDelete={handleDelete}
      />
    </>
  );
}

export default TableSteps;
