import { Modal } from "antd";
import { Grid } from "components/Base";
import If from "global/ErrorHandlers/If";
import story from "lib/api/story";
import { useEffect, useState } from "react";
import { BiChevronLeft, BiChevronRight } from "react-icons/bi";
import ReactInstaStories from "react-insta-stories";
import { StoryItem } from "types/responses/story";
import ModalStory from "./ModalStory";
import { Avatar } from "./styled";

function Story() {
  const [stories, setStories] = useState<Array<StoryItem>>([]);
  const [page, setPage] = useState(1);

  const [visible, setVisible] = useState(false);
  const [activeStory, setActiveStory] = useState<StoryItem | null>(null);
  const [storyIndex, setStoryIndex] = useState(-999);

  useEffect(() => {
    story.getAllStory().then((res) => {
      setStories(res.data.data);
    });
  }, []);

  const handleChangePage = (direction: "prev" | "next") => {
    const addition = direction === "prev" ? -1 : 1;

    setPage((prev) => prev + addition);
  };

  const handleShowStory = (story: any, index: number) => {
    // console.log(story);
    setActiveStory(story);
    setStoryIndex(index);
    setVisible(true);
  };

  const handleCloseModal = () => {
    setVisible(false);
    setActiveStory(null);
    setStoryIndex(-999);
  };

  const onStoryEnd = () => {
    if (storyIndex < stories.length - 1) {
      setActiveStory(stories[storyIndex + 1]);
      setStoryIndex((prev) => prev + 1);
    } else {
      handleCloseModal();
    }
  };

  if (stories.length === 0) {
    return null;
  }

  const LIMIT = page === 1 ? 11 : 10;

  return (
    <>
      <Grid columns="repeat(12, 1fr)" gap="22px" mb="40px">
        <If condition={page > 1}>
          <Avatar
            viewed
            size={72}
            icon={
              <BiChevronLeft
                style={{ margin: "13px auto", color: "#9CA3AF" }}
              />
            }
            onClick={() => handleChangePage("prev")}
          />
        </If>
        {stories.slice(page - 1, LIMIT * page).map((story, idx) => (
          <Avatar
            size={72}
            src={story.user.photo}
            onClick={() => handleShowStory(story, idx)}
          />
        ))}
        {/* next button */}
        <If condition={LIMIT * page < stories.length}>
          <Avatar
            viewed
            size={72}
            icon={
              <BiChevronRight
                style={{ margin: "13px auto", color: "#9CA3AF" }}
              />
            }
            onClick={() => handleChangePage("next")}
          />
        </If>
      </Grid>
      <ModalStory
        visible={visible}
        onCancel={handleCloseModal}
        story={activeStory}
        onStoryEnd={onStoryEnd}
      />
    </>
  );
}

export default Story;
