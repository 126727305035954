import EditMasterCategory from "features/master-data/category/edit-category";
import MainLayout from "global/MainLayout";

function EditMasterCategoryPage() {
  return (
    <MainLayout>
      <EditMasterCategory />
    </MainLayout>
  );
}

export default EditMasterCategoryPage;
