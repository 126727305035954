import { Modal } from "components/Feedback";

interface ModalDeactivateProps {
  visible: boolean;
  children?: React.ReactNode;
  title?: string;
  onConfirm: (params?: any) => void;
  onCancel: (params?: any) => void;
}

function ModalDeactivate({
  visible,
  onConfirm,
  onCancel,
  children,
  title = "Konfirmasi Non Aktif",
}: ModalDeactivateProps) {
  return (
    <Modal
      title={title}
      open={visible}
      onOk={onConfirm}
      onCancel={onCancel}
      okText="Ya, Non Aktifkan"
      cancelText="Batalkan"
    >
      {children
        ? children
        : "Apakah Kamu yakin menonaktifkan kategori ini? Aksi ini tidak dapat dibatalkan."}
    </Modal>
  );
}

export default ModalDeactivate;
