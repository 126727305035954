import ReportPost from "features/report-management/post";
import MainLayout from "global/MainLayout";

function ReportPostPage() {
  return (
    <MainLayout>
      <ReportPost />
    </MainLayout>
  );
}

export default ReportPostPage;
