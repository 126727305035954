import request from "configs/request";
import { APIPromiseResponse, GetListParams } from "types/api";
import { IAddNotif, INotifItem } from "types/responses/notif";

const URL = "/notification";

const getList = (params: GetListParams): APIPromiseResponse => {
  return request.get(`${URL}/list`, { params });
};

const getDetail = (id: number): APIPromiseResponse<INotifItem> => {
  return request.get(`${URL}/detail/${id}`);
};

const add = (data: IAddNotif): APIPromiseResponse => {
  return request.post(`${URL}/add`, data);
};

const update = (id: number, data: IAddNotif): APIPromiseResponse => {
  return request.put(`${URL}/update/${id}`, data);
};

const deleteNotification = (id: number): APIPromiseResponse => {
  return request.delete(`${URL}/delete/${id}`);
};

export default {
  getList,
  getDetail,
  add,
  update,
  delete: deleteNotification,
};
