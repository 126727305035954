interface ModuleData {
  id: number;
  name: string;
  icon: string;
  feature: Array<{
    // if it's from getModule it is the feature id, otherwise it's the role_feature id
    id: number;
    // if it's from getDetail role, this is the feature id
    id_feature?: number;
    // id_role is optional
    id_role?: number;
    name: string;
    add_access: boolean;
    update_access: boolean;
    delete_access: boolean;
    view_access: boolean;
  }>;
}

/**
 * Map modules data to array structure to fit the form structure
 * @param modules
 * @returns
 */
export const mapModules = (modules: Array<ModuleData>) => {
  const flatten = modules.flatMap((mod) => mod.feature);
  const mapped = flatten.reduce((obj, item) => {
    const { id: featId, name: featName, id_role, id_feature, ...access } = item;
    const roleAccess = access
      ? // @ts-ignore
        Object.keys(access).filter((key) => access[key])
      : [];

    // use id_feature if it's exist
    const dataId = id_feature || featId;

    const data = {
      id: dataId,
      name: featName,
      access: roleAccess,
    };

    // @ts-ignore
    obj[dataId] = data;
    return obj;
  }, {});

  return mapped;
};

export const calculateModule = (modules: Array<ModuleData>) => {
  const module = modules.map((mod) => {
    const features = mod.feature.map((obj) => {
      const {
        id: featId,
        name: featName,
        id_role,
        id_feature,
        ...access
      } = obj;
      const roleAccess = access
        ? // @ts-ignore
          Object.keys(access).filter((key) => access[key])
        : [];

      const data = {
        id: featId,
        name: featName,
        access: roleAccess,
      };

      return data;
    });

    return {
      id: mod.id,
      name: mod.name,
      icon: mod.icon,
      feature: features,
    };
  });

  return module;
};
