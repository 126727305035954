import produce from "immer";
import configApi from "lib/api/config";
import { GetListParams } from "types/api";
import { IFAQItem } from "types/responses/config";
import create from "zustand";

interface FAQStore {
  list: Array<IFAQItem>;
  detail: Record<string, IFAQItem>;
  total: number;
  getList: (params: GetListParams) => void;
  getDetail: (id: number) => void;
  deleteFAQ: (id: number) => Promise<boolean>;
}

const useFAQStore = create<FAQStore>((set, get) => ({
  list: [],
  detail: {},
  total: 0,
  getList: async (params) => {
    const res = await configApi.getFaqList(params);

    if (!res.data) {
      return;
    }

    const { data, error } = res.data;

    if (error) {
      return;
    }

    set({ list: data.data, total: data.total });
  },
  getDetail: async (id: number) => {
    const res = await configApi.getFaqDetail(id);

    if (!res.data) {
      return;
    }

    const { data, error } = res.data;

    if (error) {
      return;
    }

    return set(
      produce((state: FAQStore) => {
        state.detail[id] = data;
      }),
    );
  },
  deleteFAQ: async (id: number) => {
    const res = await configApi.deleteFaq(id);

    if (!res.data) {
      return false;
    }

    const { error } = res.data;

    if (error) {
      return false;
    }

    return true;
  },
}));

export default useFAQStore;
