import { useMutation } from "@tanstack/react-query";
import report from "lib/api/report";

function useApproveRejectReport() {
  const approveReport = useMutation({
    mutationKey: ["approve-report"],
    mutationFn: async (id: number) => {
      try {
        const res = await report.approveReportPost(id);

        return res.data.data;
      } catch (e) {
        // do something
      }
    },
  });

  const rejectReport = useMutation({
    mutationKey: ["reject-report"],
    mutationFn: async (id: number) => {
      try {
        const res = await report.rejectReportPost(id);

        return res.data.data;
      } catch (e) {
        // do something
      }
    },
  });

  return {
    approveReport,
    rejectReport,
  };
}

export default useApproveRejectReport;
