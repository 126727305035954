import Leaderboard from "features/leaderboard";
import MainLayout from "global/MainLayout";

function LeaderboardPage() {
  return (
    <MainLayout>
      <Leaderboard />
    </MainLayout>
  );
}

export default LeaderboardPage;
