import { PlusIcon } from "@radix-ui/react-icons";
import { Button, Flex } from "components/Base";
import { Modal } from "components/Feedback";
import If from "global/ErrorHandlers/If";
import { useState } from "react";
import { MdAttachment } from "react-icons/md";
import { getMediaName } from "utils/media";

interface ModalImportProps {
  visible: boolean;
  onClose: () => void;
}

function ModalImport({ visible, onClose }: ModalImportProps) {
  const [file, setFile] = useState<File | null>(null);

  const handleSave = () => {
    console.log("Save file");
    onClose();
  };

  const handleOpenFile = () => {
    const input = document.getElementById("upload-file");
    if (input) {
      input.click();
    }
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      setFile(file);
    }
  };

  return (
    <Modal
      open={visible}
      onCancel={onClose}
      title="Import Data"
      okText="Simpan"
      cancelText="Batalkan"
      onOk={handleSave}
    >
      <Flex
        p="24px"
        br="$rounded-sm"
        background="$coolGray100"
        boxShadow="$shadow-sm"
      >
        <If condition={!file}>
          <Flex justify="center" w="100%">
            <Button m="auto" icon={<PlusIcon />} onClick={handleOpenFile}>
              Pilih File
            </Button>
            <input
              id="upload-file"
              type="file"
              hidden
              onChange={handleFileChange}
              style={{ display: "none" }}
            />
          </Flex>
        </If>
        <If condition={!!file}>
          <Flex align="center" gap="8px" color="$primary">
            <MdAttachment />
            {/* @ts-ignore */}
            {getMediaName(file?.name)}
          </Flex>
        </If>
      </Flex>
    </Modal>
  );
}

export default ModalImport;
