import { message } from "antd";
import PageTitle from "global/PageTitle";
import role from "lib/api/role";
import { useNavigate } from "react-router-dom";
import { notifySuccess } from "utils/notification";
import FormRole from "./components/FormRole";

function AddRole() {
  const navigate = useNavigate();

  const handleSubmit = async (data: any) => {
    const res = await role.addRole(data);
    if (!res.data) {
      message.error("Gagal menambahkan role");
      return;
    }
    const { error } = res.data;
    if (error) {
      message.error(error);
      return;
    }
    notifySuccess({
      message: "Berhasil menambahkan role",
    });
    // Redirect to list page
    navigate("/roles");
  };

  return (
    <>
      <PageTitle title="Menambahkan Role Manajemen" backUrl="/roles" />
      <FormRole onSubmit={handleSubmit} />
    </>
  );
}

export default AddRole;
