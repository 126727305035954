import produce from "immer";
import notification from "lib/api/notification";
import { GetListParams } from "types/api";
import { INotifItem } from "types/responses/notif";
import create from "zustand";

interface NotificationStore {
  list: Array<INotifItem>;
  detail: Record<string, INotifItem>;
  total: number;
  getList: (params: GetListParams) => void;
  getDetail: (id: number) => void;
  deleteNotif: (id: number) => Promise<boolean>;
}

const useNotificationStore = create<NotificationStore>((set, get) => ({
  list: [],
  detail: {},
  total: 0,
  getList: async (params) => {
    const res = await notification.getList(params);

    if (!res.data) {
      return;
    }

    const { data, error } = res.data;

    if (error) {
      return;
    }

    set({ list: data.data, total: data.total });
  },
  getDetail: async (id: number) => {
    const res = await notification.getDetail(id);

    if (!res.data) {
      return;
    }

    const { data, error } = res.data;

    if (error) {
      return;
    }

    return set(
      produce((state: NotificationStore) => {
        // @ts-ignore
        state.detail[id] = data;
      }),
    );
  },
  deleteNotif: async (id: number) => {
    const res = await notification.delete(id);

    if (!res.data) {
      return false;
    }

    const { error } = res.data;

    if (error) {
      return false;
    }

    return true;
  },
}));

export default useNotificationStore;
