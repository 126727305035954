import configApi from "lib/api/config";
import { UpdateAbout } from "types/responses/config";
import { notifySuccess } from "utils/notification";
import create from "zustand";

interface AboutStore {
  about: null | Record<string, any>;
  error: null | any;
  updateAbout: (data: UpdateAbout) => void;
  getAbout: () => void;
}

const useAboutStore = create<AboutStore>((set) => ({
  about: null,
  error: null,
  updateAbout: async (data) => {
    try {
      const res = await configApi.updateAbout(data);

      if (res.status !== 200) {
        return set({ error: res.data });
      }

      notifySuccess({
        message: "Update berhasil",
        description: "Tentang Sigap telah berhasil diubah",
      });

      return set({
        error: null,
      });
    } catch (err) {
      return set({ error: "An error occurred" });
    }
  },
  getAbout: async () => {
    try {
      const res = await configApi.getAbout();

      if (res.status !== 200) {
        return set({ error: res.data });
      }

      const { data: resData } = res.data;

      return set({
        about: resData,
        error: null,
      });
    } catch (err) {
      return set({ error: "An error occurred" });
    }
  },
}));

export default useAboutStore;
