/* eslint-disable max-len */
import { LOGIN_STATE } from "constants/app";
import { CardLogin, ErrorBox } from "components/Auth";
import { Button } from "components/Base";
import { Password } from "components/Form";
import useInput from "hooks/useInput";
import authApi from "lib/api/auth";
import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import * as yup from "yup";
import YupPassword from "yup-password";
import PasswordItem from "./PasswordItem";
YupPassword(yup);

// const schema = yup.string().password().min(8).matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/);
const schema = yup.string().password().min(0);

function SetPassword() {
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();
  const [password, setPassword] = useInput("");
  const [confirmPassword, setConfirmPassword] = useInput("");

  const [valid, setValid] = useState(false);
  const [error, setError] = useState(false);

  const email = searchParams.get("email");
  const token = searchParams.get("code");

  const validatePassword = async () => {
    try {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const isValid = await schema.isValid(password);
      setValid(isValid);
    } catch (e: any) {
      // do something
    }
  };

  useEffect(() => {
    validatePassword();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [password]);

  const handleSubmit = async (e: any) => {
    e?.preventDefault();
    if (password !== confirmPassword) {
      setError(true);
      return;
    }

    if (!(email && token)) {
      return;
    }

    const data = {
      email,
      token,
      password,
      confirmPassword,
    };

    const reset = await authApi.resetPassword(data);

    if (!reset) {
      return;
    }

    const { error: errorResponse } = reset.data;

    if (!errorResponse) {
      navigate("/login", { state: LOGIN_STATE.RESET_PASSWORD });
    }
  };

  const validSubmit = valid && password.length >= 8;

  return (
    <CardLogin title="Atur Kata Sandi Baru">
      {error && <ErrorBox error="Kata sandi tidak cocok" />}
      <form onSubmit={handleSubmit}>
        <Password
          label="Kata Sandi Baru"
          placeholder="Ketikan kata sandi baru"
          value={password}
          onChange={setPassword}
        />
        <Password
          label="Ulangi Kata Sandi Baru"
          placeholder="Ketikan ulang kata sandi baru"
          value={confirmPassword}
          onChange={setConfirmPassword}
        />
        <PasswordItem
          label="Kata sandi harus mengandung 8 karakter"
          success={password.length >= 8}
        />
        <PasswordItem
          label="Kombinasi huruf besar, huruf kecil, angka, dan karakter spesial"
          success={valid}
        />
        <Button disabled={!validSubmit} type="primary" htmlType="submit" block>
          Perbarui kata sandi
        </Button>
      </form>
    </CardLogin>
  );
}

export default SetPassword;
