import EditRole from "features/role-management/edit-role";
import MainLayout from "global/MainLayout";

function EditRolePage() {
  return (
    <MainLayout>
      <EditRole />
    </MainLayout>
  );
}

export default EditRolePage;
