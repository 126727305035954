import { ModalProps } from "antd";
import { Text } from "components/Base";
import { Modal } from "components/Feedback";

interface ModalRejectProps extends ModalProps {
  reportType: "post" | "story";
}

function ModalReject({ reportType, ...props }: ModalRejectProps) {
  const wording = reportType === "post" ? "Cerita Desa" : "Story";

  return (
    <Modal
      title="Konfirmasi Tidak Setuju"
      okText="Ya, Yakin"
      cancelText="Batalkan"
      {...props}
    >
      <Text>
        Apakah Kamu yakin ingin {wording} ini tidak melanggar aturan dari SIGAP?
      </Text>
    </Modal>
  );
}

export default ModalReject;
