import AddUser from "features/user-management/add-user";
import MainLayout from "global/MainLayout";

function AddUserPage() {
  return (
    <MainLayout>
      <AddUser />
    </MainLayout>
  );
}

export default AddUserPage;
