import DetailNotification from "features/notification/detail-notification";
import MainLayout from "global/MainLayout";

function DetailNotificationPage() {
  return (
    <MainLayout>
      <DetailNotification />
    </MainLayout>
  );
}

export default DetailNotificationPage;
