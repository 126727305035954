const colors = {
  white: "#ffffff",
  black: "#000000",
  coolGray50: "#f9fafb",
  coolGray100: "#f3f4f6",
  coolGray200: "#e5e7eb",
  coolGray300: "#d1d5db",
  coolGray400: "#9ca3af",
  coolGray500: "#6b7280",
  coolGray600: "#4b5563",
  coolGray700: "#374151",
  coolGray800: "#1f2937",
  coolGray900: "#111827",

  primary50: "#bce1c7",
  primary100: "#bce1c6",
  primary200: "#9bd3aa",
  primary300: "#7ac38e",
  primary400: "#58b471",
  primary500: "#36a554",
  primary600: "#2e8947",
  primary700: "#246f3a",
  primary800: "#1b522a",
  primary900: "#0c2011",

  secondary50: "#fffadf",
  secondary100: "#fff7c8",
  secondary200: "#fff2ae",
  secondary300: "#ffed94",
  secondary400: "#ffe878",
  secondary500: "#ffe45c",
  secondary600: "#d4be4d",
  secondary700: "#aa983e",
  secondary800: "#81712e",
  secondary900: "#332e13",

  primary: "$primary400",
  secondary: "$secondary400",

  // Supportive colors
  red50: "#fef2f2",
  red100: "#fee2e2",
  red200: "#fecaca",
  red300: "#fca5a5",
  red400: "#f87171",
  red500: "#f44444",
  red600: "#dc2626",
  red700: "#b91c1c",
  red800: "#991b1b",
  red900: "#7f1d1d",

  orange50: "#FFF7ED",
  orange100: "#FFEDD5",
  orange200: "#FED7AA",
  orange300: "#FDBA74",
  orange400: "#FB923C",
  orange500: "#F97316",
  orange600: "#EA580C",
  orange700: "#C2410C",
  orange800: "#9A3412",
  orange900: "#7C2D12",

  amber50: "#FFFBEB",
  amber100: "#FEF3C7",
  amber200: "#FDE68A",
  amber300: "#FCD34D",
  amber400: "#FBBF24",
  amber500: "#F59E0B",
  amber600: "#D97706",
  amber700: "#B45309",
  amber800: "#92400E",
  amber900: "#78350F",

  yellow50: "#FEFCE8",
  yellow100: "#FEF9C3",
  yellow200: "#FEF08A",
  yellow300: "#FDE047",
  yellow400: "#FACC15",
  yellow500: "#EAB308",
  yellow600: "#CA8A04",
  yellow700: "#A16207",
  yellow800: "#854D0E",
  yellow900: "#713F12",

  lime50: "#F7FEE7",
  lime100: "#ECFCCB",
  lime200: "#D9F99D",
  lime300: "#BEF264",
  lime400: "#A3E635",
  lime500: "#84CC16",
  lime600: "#65A30D",
  lime700: "#4D7C0F",
  lime800: "#3F6212",
  lime900: "#365314",

  green50: "#F0FDF4",
  green100: "#DCFCE7",
  green200: "#BBF7D0",
  green300: "#86EFAC",
  green400: "#4ADE80",
  green500: "#22C55E",
  green600: "#16A34A",
  green700: "#15803D",
  green800: "#166534",
  green900: "#14532D",

  emerald50: "#ECFDF5",
  emerald100: "#D1FAE5",
  emerald200: "#A7F3D0",
  emerald300: "#6EE7B7",
  emerald400: "#34D399",
  emerald500: "#10B981",
  emerald600: "#059669",
  emerald700: "#047857",
  emerald800: "#065F46",
  emerald900: "#064E3B",

  teal50: "#F0FDFA",
  teal100: "#CCFBF1",
  teal200: "#99F6E4",
  teal300: "#5EEAD4",
  teal400: "#2DD4BF",
  teal500: "#14B8A6",
  teal600: "#0D9488",
  teal700: "#0F766E",
  teal800: "#115E59",
  teal900: "#014451",

  cyan50: "#ECFEFF",
  cyan100: "#CFFAFE",
  cyan200: "#A5F3FC",
  cyan300: "#67E8F9",
  cyan400: "#22D3EE",
  cyan500: "#06B6D4",
  cyan600: "#0891B2",
  cyan700: "#0E7490",
  cyan800: "#1E40AF",
  cyan900: "#164E63",

  lightBlue50: "#F0F9FF",
  lightBlue100: "#E0F2FE",
  lightBlue200: "#BAE6FD",
  lightBlue300: "#7DD3FC",
  lightBlue400: "#38BDF8",
  lightBlue500: "#0EA5E9",
  lightBlue600: "#0284C7",
  lightBlue700: "#0369A1",
  lightBlue800: "#075985",
  lightBlue900: "#0C4A6E",

  indigo50: "#EEF2FF",
  indigo100: "#E0E7FF",
  indigo200: "#C7D2FE",
  indigo300: "#A5B4FC",
  indigo400: "#818CF8",
  indigo500: "#6366F1",
  indigo600: "#4F46E5",
  indigo700: "#4338CA",
  indigo800: "#3730A3",
  indigo900: "#312E81",

  violet50: "#F5F3FF",
  violet100: "#EDE9FE",
  violet200: "#DDD6FE",
  violet300: "#C4B5FD",
  violet400: "#A78BFA",
  violet500: "#8B5CF6",
  violet600: "#7C3AED",
  violet700: "#6D28D9",
  violet800: "#5B21B6",
  violet900: "#4C1D95",

  purple50: "#FAF5FF",
  purple100: "#F3E8FF",
  purple200: "#E9D5FF",
  purple300: "#D8B4FE",
  purple400: "#C084FC",
  purple500: "#A855F7",
  purple600: "#9333EA",
  purple700: "#7E22CE",
  purple800: "#6B21A8",
  purple900: "#581C87",

  fuchsia50: "#FDF4FF",
  fuchsia100: "#FAE8FF",
  fuchsia200: "#F5D0FE",
  fuchsia300: "#F0ABFC",
  fuchsia400: "#E879F9",
  fuchsia500: "#D946EF",
  fuchsia600: "#C026D3",
  fuchsia700: "#A21CAF",
  fuchsia800: "#86198F",
  fuchsia900: "#701A75",

  pink50: "#FDF2F8",
  pink100: "#FCE7F3",
  pink200: "#FBCFE8",
  pink300: "#F9A8D4",
  pink400: "#F472B6",
  pink500: "#EC4899",
  pink600: "#DB2777",
  pink700: "#BE185D",
  pink800: "#9D174D",
  pink900: "#831843",

  rose50: "#FFF1F2",
  rose100: "#FFE4E6",
  rose200: "#FECDD3",
  rose300: "#FDA4AF",
  rose400: "#FB7185",
  rose500: "#F43F5E",
  rose600: "#E11D48",
  rose700: "#BE123C",
  rose800: "#9F1239",
  rose900: "#881337",
};

const linearGradient = (value: string) => ({
  backgroundImage: `linear-gradient(${value})`,
});

const bgColor = (value: string) => ({
  backgroundColor: value,
});

export const backgrounds = {
  linearGradient,
  bgColor,
  bg: bgColor,
};

export default colors;
