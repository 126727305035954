import { TableColumnsType } from "antd";
import { format } from "date-fns";
import ModalDelete from "global/Modals/ModalDelete";
import TableList from "global/TableList";
import ButtonAction from "global/TableList/ButtonAction";
import useDebounce from "hooks/useDebounce";
import useLoaded from "hooks/useLoaded";
import { useEffect, useState } from "react";
import useNotificationStore from "../notifStore";

interface TableNotifProps {
  keyword?: string;
}

function TableNotif({ keyword }: TableNotifProps) {
  const [data, total, getList, deleteNotif] = useNotificationStore((s) => [
    s.list,
    s.total,
    s.getList,
    s.deleteNotif,
  ]);
  const loaded = useLoaded();

  // Table state
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const debouncedKeyword = useDebounce(keyword, 500);

  // Modal delete state
  const [visible, setVisible] = useState(false);
  const [selectedId, setSelectedId] = useState(0);

  useEffect(() => {
    if (debouncedKeyword !== undefined && loaded) {
      const newPage = 1;
      setPage(newPage);

      const query = debouncedKeyword || undefined;
      getList({ page: newPage, limit, query });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedKeyword, loaded]);

  const handleChangePage = (currentPage: number, pageSize: number) => {
    setPage(currentPage);
    setLimit(pageSize);

    // re-fetch the data
    const query = debouncedKeyword || undefined;
    getList({ page: currentPage, limit: pageSize, query });
  };

  const handleShowDelete = (id: number) => {
    setSelectedId(id);
    setVisible(true);
  };

  const handleDelete = async () => {
    const del = await deleteNotif(selectedId);

    if (del) {
      setVisible(false);
      setSelectedId(0);

      // re-fetch the data
      const query = debouncedKeyword || undefined;
      getList({ page, limit, query });
    }
  };

  const handleCancel = () => {
    setVisible(false);
    setSelectedId(0);
  };

  const columns: TableColumnsType<TableNotifProps> = [
    {
      title: "Judul",
      dataIndex: "title",
      key: "title",
      width: "25%",
    },
    {
      title: "Deskripsi",
      dataIndex: "description",
      key: "description",
      width: "20%",
    },
    {
      title: "Platform",
      dataIndex: "platform",
      key: "platform",
    },
    {
      title: "Waktu",
      dataIndex: "created_at",
      key: "time",
      render: (time) => format(new Date(time), "HH:mm"),
    },
    {
      title: "Tanggal",
      dataIndex: "created_at",
      key: "date",
      render: (date) => format(new Date(date), "dd/MM/yyyy"),
    },
    {
      title: "",
      dataIndex: "action",
      key: "action",
      render: (_, { id }: any) => (
        <ButtonAction
          view={false}
          edit={false}
          id={id}
          onDelete={() => handleShowDelete(id)}
        />
      ),
    },
  ];

  return (
    <>
      <TableList
        columns={columns}
        dataSource={data}
        page={page}
        limit={limit}
        total={total}
        loading={!loaded}
        onChangePage={handleChangePage}
      />
      <ModalDelete
        visible={visible}
        onDelete={handleDelete}
        onCancel={handleCancel}
      />
    </>
  );
}

export default TableNotif;
