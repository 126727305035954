import { TableColumnsType } from "antd";
import { Tag, Flex, Text, Button } from "components/Base";
import ModalDelete from "global/Modals/ModalDelete";
import TableList from "global/TableList";
import ButtonAction from "global/TableList/ButtonAction";
import useDebounce from "hooks/useDebounce";
// import roleStore from "lib/stores/slices/role";
import { useState, useEffect } from "react";
import { getRandomColor } from "utils/general";
import roleStore from "../roleStore";

interface TableRoleProps {
  keyword?: string;
}

function TableRole({ keyword }: TableRoleProps) {
  const list = roleStore((state) => state.list);
  const total = roleStore((state) => state.total);
  const getList = roleStore((state) => state.getList);
  const deleteRole = roleStore((state) => state.deleteRole);

  // Table state
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [loaded, setLoaded] = useState(false);
  const debouncedKeyword = useDebounce(keyword, 500);

  // Modal delete state
  const [visible, setVisible] = useState(false);
  const [selectedId, setSelectedId] = useState(0);

  useEffect(() => {
    setLoaded(true);
    const query = debouncedKeyword || undefined;
    getList({ page, limit, query });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (debouncedKeyword !== undefined && loaded) {
      const newPage = 1;
      setPage(newPage);

      const query = debouncedKeyword || undefined;
      getList({ page: newPage, limit, query });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedKeyword]);

  const handleChangePage = (currentPage: number, pageSize: number) => {
    setPage(currentPage);
    setLimit(pageSize);

    // re-fetch the data
    const query = debouncedKeyword || undefined;
    getList({ page: currentPage, limit: pageSize, query });
  };

  const handleShowDelete = (id: number) => {
    setSelectedId(id);
    setVisible(true);
  };

  const handleDelete = async () => {
    const del = await deleteRole(selectedId);

    // TODO: Implement this after API is ready
    if (del) {
      // Re-fetch the list
      const query = debouncedKeyword || undefined;
      getList({ page, limit, query });

      // Close modal
      setVisible(false);
      setSelectedId(0);
    }
  };

  const columns: TableColumnsType<TableRoleProps> = [
    {
      title: "Nama Role",
      dataIndex: "name",
      key: "name",
      render: (name: any) => (
        <Tag color={getRandomColor()} rounded>
          {name}
        </Tag>
      ),
    },
    {
      title: "Jumlah Modul",
      dataIndex: "modules",
      key: "modules",
      render: (modules: Array<any>) => <Text>{modules.length} Menu</Text>,
    },
    {
      title: "Status",
      dataIndex: "active",
      key: "status",
      render: (status: boolean) => (
        <Tag rounded color={status ? "green" : "red"}>
          {status ? "Aktif" : "Non Aktif"}
        </Tag>
      ),
    },
    {
      title: "",
      key: "action",
      render: (text: any, { id }: Record<string, any>) => (
        <ButtonAction
          id={id}
          url={"/roles"}
          onDelete={() => handleShowDelete(id)}
        />
      ),
    },
  ];

  return (
    <>
      <TableList
        columns={columns}
        dataSource={list}
        total={total}
        page={page}
        limit={limit}
        onChangePage={handleChangePage}
      />
      <ModalDelete
        visible={visible}
        onDelete={handleDelete}
        onCancel={() => setVisible(false)}
      >
        Apakah kamu yakin ingin menghapus role ini? Menghapus role akan
        menghapus secara permanen semua data yang berkaitan dengan role ini.
      </ModalDelete>
    </>
  );
}

export default TableRole;
