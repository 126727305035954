import { Card } from "components/Base";
import ButtonAdd from "global/ButtonAdd";
import InputSearch from "global/InputSearch";
import PageTitle from "global/PageTitle";
import useInput from "hooks/useInput";
import TableNotif from "./components/TableNotif";

function Notification() {
  const [query, setQuery] = useInput("");

  return (
    <>
      <PageTitle title="List Notification">
        <ButtonAdd to="/notification/add" />
      </PageTitle>
      <Card>
        <InputSearch value={query} onChange={setQuery} />
        <TableNotif keyword={query} />
      </Card>
    </>
  );
}

export default Notification;
