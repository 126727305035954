import { Card } from "components/Base";
import InputSearch from "global/InputSearch";
import PageTitle from "global/PageTitle";
import useInput from "hooks/useInput";
import TableContact from "./components/TableContact";

function Contact() {
  const [query, setQuery] = useInput("");

  return (
    <>
      <PageTitle title="Bantuan" />
      <Card>
        <InputSearch value={query} onChange={setQuery} />
        <TableContact keyword={query} />
      </Card>
    </>
  );
}

export default Contact;
