import request from "configs/request";
import { APIPromiseResponse, GetListParams } from "types/api";
import { I7DReport, I7DStep, IAdd7DStep } from "types/responses/7d";

const STEPS_URL = "/7d-steps";
const REPORT_URL = "/7d-report";

// Report APIs
const getReportList = (params: GetListParams): APIPromiseResponse => {
  return request.get(`${REPORT_URL}/list`, { params });
};

const getReportDetail = (id: number): APIPromiseResponse<I7DReport> => {
  return request.get(`${REPORT_URL}/detail/${id}`);
};

const deleteReport = (id: number): APIPromiseResponse => {
  return request.delete(`${REPORT_URL}/delete/${id}`);
};

const downloadReport = (start: string, end: string): APIPromiseResponse => {
  return request.get(`${REPORT_URL}/download`, {
    params: { start, end },
  });
};

// Steps APIs

const getStepsList = (params: GetListParams): APIPromiseResponse => {
  return request.get(`${STEPS_URL}/list`, { params });
};

const getStepsDetail = (id: number): APIPromiseResponse<I7DStep> => {
  return request.get(`${STEPS_URL}/detail/${id}`);
};

const addSteps = (data: IAdd7DStep): APIPromiseResponse => {
  return request.post(`${STEPS_URL}/add`, data);
};

const updateSteps = (id: number, data: IAdd7DStep): APIPromiseResponse => {
  return request.put(`${STEPS_URL}/update/${id}`, data);
};

const deleteSteps = (id: number): APIPromiseResponse => {
  return request.delete(`${STEPS_URL}/delete/${id}`);
};

export default {
  // Report APIs
  getReportList,
  getReportDetail,
  deleteReport,
  downloadReport,

  // Steps APIs
  getStepsList,
  getStepsDetail,
  addSteps,
  updateSteps,
  deleteSteps,
};
