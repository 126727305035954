import produce from "immer";
import stepApi from "lib/api/7ds";
import { GetListParams } from "types/api";
import { I7DStep } from "types/responses/7d";
import create from "zustand";

interface StepStore {
  list: Array<I7DStep>;
  detail: Record<string, I7DStep>;
  total: number;
  getList: (params: GetListParams) => void;
  getDetail: (id: number) => void;
  delete: (id: number) => Promise<boolean>;
}

const useStepStore = create<StepStore>((set, get) => ({
  list: [],
  detail: {},
  total: 0,
  getList: async (params) => {
    const res = await stepApi.getStepsList(params);

    if (!res.data) {
      return;
    }

    const { data, error } = res.data;

    if (error) {
      return;
    }

    set({ list: data.data, total: data.total });
  },
  getDetail: async (id: number) => {
    const res = await stepApi.getStepsDetail(id);

    if (!res.data) {
      return;
    }

    const { data, error } = res.data;

    if (error) {
      return;
    }

    return set(
      produce((state: StepStore) => {
        if (data) {
          state.detail[id] = data;
        }
      }),
    );
  },
  delete: async (id: number) => {
    const res = await stepApi.deleteSteps(id);

    if (!res.data) {
      return false;
    }

    const { error } = res.data;

    if (error) {
      return false;
    }

    return true;
  },
}));

export default useStepStore;
