import { TableColumnsType } from "antd";
import { Button, Tag } from "components/Base";
import TableList from "global/TableList";
import { TableBaseProps } from "global/TableList/types";
import useDebounce from "hooks/useDebounce";
import useLoaded from "hooks/useLoaded";
import { useEffect, useState } from "react";
import { FaEdit } from "react-icons/fa";
import { Link } from "react-router-dom";
import useContactStore from "../contactStore";

function TableContact({ keyword }: TableBaseProps) {
  const [data, total, getList] = useContactStore((s) => [
    s.list,
    s.total,
    s.getList,
  ]);
  const isLoaded = useLoaded();

  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const debouncedKeyword = useDebounce(keyword, 500);

  useEffect(() => {
    if (debouncedKeyword !== undefined && isLoaded) {
      const newPage = 1;
      setPage(newPage);

      const query = debouncedKeyword || undefined;
      getList({ page: newPage, limit, query });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedKeyword, isLoaded]);

  const handleChangePage = (page: number, pageSize: number) => {
    setPage(page);
    setLimit(pageSize);

    // re-fetch the data
    const query = debouncedKeyword || undefined;
    getList({ page, limit: pageSize, query });
  };

  const columns: TableColumnsType<TableBaseProps> = [
    {
      title: "Nama Admin",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "No. Handphone",
      dataIndex: "phone",
      key: "phone",
    },
    {
      title: "Status",
      dataIndex: "active",
      key: "active",
      render: (active: boolean) => {
        return (
          <Tag rounded color={active ? "green" : "red"}>
            {active ? "Aktif" : "Non Aktif"}
          </Tag>
        );
      },
    },
    {
      title: "",
      key: "action",
      render: (_, { id }: any) => {
        return (
          <Link to={`/contact/edit/${id}`}>
            <Button icon={<FaEdit size={16} />} />
          </Link>
        );
      },
    },
  ];

  return (
    <TableList
      dataSource={data}
      columns={columns}
      total={total}
      page={page}
      limit={limit}
      onChangePage={handleChangePage}
    />
  );
}

export default TableContact;
