import Dashboard from "features/dashboard";
import MainLayout from "global/MainLayout";

function DashboardPage() {
  return (
    <MainLayout>
      <Dashboard />
    </MainLayout>
  );
}

export default DashboardPage;
