interface SubNavigation {
  key?: string;
  label: string;
  path?: string;
  pattern?: RegExp;
}

interface Navigations extends SubNavigation {
  icon?: string;
  children: SubNavigation[];
}

const navigations: Array<Navigations> = [
  {
    key: "dashboard",
    icon: "home",
    label: "Dashboard",
    path: "/dashboard",
    children: [],
  },
  {
    key: "user-management",
    icon: "user-group",
    label: "User Management",
    children: [
      {
        key: "list-admin",
        label: "List Admin",
        path: "/user-management",
        pattern: /^\/user-management(?:\/(detail|add|edit))?$/g,
      },
      {
        key: "role-admin",
        label: "Role Manajemen",
        path: "/roles",
        pattern: /^\/roles(?:\/(detail|add|edit))?$/g,
      },
    ],
  },
  {
    key: "report-management",
    icon: "user-group",
    label: "Report Management",
    children: [
      {
        key: "report-story",
        label: "Story",
        path: "/report-story",
        pattern: /^\/report-story(?:\/(detail|add|edit))?$/g,
      },
      {
        key: "report-post",
        label: "Cerita Desa",
        path: "/report-post",
        pattern: /^\/report-post(?:\/(detail|add|edit))?$/g,
      },
    ],
  },
  {
    key: "master-data",
    icon: "folder",
    label: "Master Data",
    children: [
      {
        key: "master-data-category",
        label: "Kategori",
        path: "/master-data/category",
        pattern: /^\/master-data\/category(?:\/(detail|add|edit))?$/g,
      },
    ],
  },
  {
    key: "user",
    icon: "user",
    label: "User",
    children: [
      {
        key: "profile-users",
        label: "Profile User",
        path: "/users",
        pattern:
          /^\/users(?:\/(detail|add|edit))(?:\/point-history|story|cerita-desa)?$/g,
      },
      {
        key: "leaderboard",
        label: "Leaderboard Point",
        path: "/leaderboard",
        pattern: /^\/leaderboard(?:\/detail)?(?:\/group)?$/g,
      },
    ],
  },
  {
    key: "7d",
    icon: "document-text",
    label: "7D",
    children: [
      {
        key: "step-7d",
        label: "Tahapan 7D",
        path: "/step-7d",
        pattern: /^\/step-7d(?:\/(detail|add|edit))?$/g,
      },
      {
        key: "report-7d",
        label: "Laporan 7D",
        path: "/report-7d",
        pattern: /^\/report-7d(?:\/(detail|add|edit))?$/g,
      },
    ],
  },
  {
    key: "level-management",
    icon: "star",
    label: "Level Management",
    path: "/levels",
    pattern: /^\/levels(?:\/(detail|add|edit))?$/g,
    children: [],
  },
  {
    key: "notification",
    icon: "bell",
    label: "Notifikasi",
    path: "/notification",
    pattern: /^\/notification(?:\/(detail|add|edit))?$/g,
    children: [],
  },
  {
    key: "config",
    icon: "cog",
    label: "Config",
    children: [
      {
        key: "about-us",
        label: "Tentang Sigap",
        path: "/about",
      },
      {
        key: "faq",
        label: "FAQ",
        path: "/faq",
        pattern: /^\/faq(?:\/(detail|add|edit))?$/g,
      },
      {
        key: "contact",
        label: "Bantuan",
        path: "/contact",
        pattern: /^\/contact(?:\/(edit))?$/g,
      },
    ],
  },
];

export default navigations;
