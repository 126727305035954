import { Divider } from "antd";
import { Card, Flex, Grid } from "components/Base";
import { Input, Switch, Textarea, Upload } from "components/Form";
import { BaseFormProps } from "components/Form/types";
import ButtonSubmit from "global/Form/ButtonSubmit";
import useInput from "hooks/useInput";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { IAdd7DStep } from "types/responses/7d";
import { handleUploadImage } from "utils/image";
import { convertToEventTarget } from "utils/input";

function FormSteps({ initialValues, onSubmit }: BaseFormProps) {
  const navigate = useNavigate();

  const [title, setTitle] = useInput("");
  const [content, setContent] = useInput("");
  const [active, setActive] = useState(false);
  const [image, setImage] = useState<any>("");

  useEffect(() => {
    if (initialValues) {
      setTitle(convertToEventTarget(initialValues.title));
      setContent(convertToEventTarget(initialValues.content));
      setActive(initialValues.active);
      setImage(initialValues.image);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialValues]);

  const handleChangeImage = (img: any) => {
    setImage(img.file);
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    const media = await handleUploadImage(image, "7d-step");

    const data = {
      title,
      content,
      active,
      image: media,
    };

    onSubmit(data);
  };

  const handleCancel = () => {
    navigate("/step-7d");
  };

  return (
    <Card p="32px">
      <form onSubmit={handleSubmit}>
        <Flex direction="column" gap="24px">
          <Grid columns="repeat(2, 1fr)" gap="24px" w="100%">
            <Input label="Judul" value={title} onChange={setTitle} required />
            <Upload
              label="Image"
              helper="Dimensi yang direkomendasikan 500 x 331. Maksimal 3MB dengan format PNG, JPEG"
              accept="image/jpg, image/png, image/jpeg"
              value={image}
              onChange={handleChangeImage}
              required
            />
          </Grid>
          <Switch
            checked={active}
            onChange={setActive}
            label="Status"
            content="Aktif"
            required
          />
          <Textarea
            value={content}
            onChange={setContent}
            label="Deskripsi"
            required
          />
        </Flex>
        <Divider />
        <ButtonSubmit onCancel={handleCancel} />
      </form>
    </Card>
  );
}

export default FormSteps;
