import { Card } from "components/Base";
import InputSearch from "global/InputSearch";
import PageTitle from "global/PageTitle";
import useInput from "hooks/useInput";
import { useParams } from "react-router-dom";
import TableStory from "./components/TableStory";

function UserStory() {
  const { id } = useParams();
  const [query, setQuery] = useInput("");

  if (!id) {
    return null;
  }

  return (
    <>
      <PageTitle title="List Story" />
      <Card>
        <InputSearch value={query} onChange={setQuery} />
        <TableStory keyword={query} userId={id} />
      </Card>
    </>
  );
}

export default UserStory;
