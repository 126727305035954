import { Divider, message } from "antd";
import { Card, Grid } from "components/Base";
import { Input, Switch, Textarea } from "components/Form";
import { BaseFormProps } from "components/Form/types";
import ButtonSubmit from "global/Form/ButtonSubmit";
import ModalDeactivate from "global/Modals/ModalDeactivate";
import useInput from "hooks/useInput";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { convertToEventTarget } from "utils/input";

function FormFAQ({ initialValues, onSubmit }: BaseFormProps) {
  const navigate = useNavigate();

  const [name, setName] = useInput("");
  const [description, setDescription] = useInput("");
  const [active, setActive] = useState(true);

  const [showConfirm, setShowConfirm] = useState(false);

  useEffect(() => {
    if (initialValues) {
      setName(convertToEventTarget(initialValues.title));
      setDescription(convertToEventTarget(initialValues.content));
      setActive(initialValues.active);
    }
  }, [initialValues]);

  const handleFinish = () => {
    const data = {
      title: name,
      content: description,
      active,
    };

    setShowConfirm(false);
    onSubmit(data);
  };

  const handleSubmit = (e: any) => {
    e?.preventDefault();

    if (!name || !description) {
      message.error("Nama dan deskripsi harus diisi");
      return;
    }

    handleFinish();
  };

  const handleCancel = () => {
    navigate("/faq");
  };

  return (
    <Card p="32px">
      <form onSubmit={handleSubmit}>
        <Grid columns="repeat(2, 1fr)" gap="24px" mb="24px">
          <Input label="Judul" value={name} onChange={setName} required />
          <Switch
            label="Status"
            content="Aktif"
            checked={active}
            onChange={setActive}
            required
          />
        </Grid>
        <Textarea
          label="Deskripsi"
          value={description}
          onChange={setDescription}
          required
          autoSize
        />
        <Divider />
        <ButtonSubmit onCancel={handleCancel} />
      </form>
      <ModalDeactivate
        visible={showConfirm}
        onConfirm={handleFinish}
        onCancel={() => setShowConfirm(false)}
      />
    </Card>
  );
}

export default FormFAQ;
