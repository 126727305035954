import { Card, Flex } from "components/Base";
import { Datepicker } from "components/Form";
import InputSearch from "global/InputSearch";
import PageTitle from "global/PageTitle";
import useInput from "hooks/useInput";
import { useState } from "react";
import { useParams } from "react-router-dom";
import TablePointHistory from "./components/TablePointHistory";

function PointHistory() {
  const { id } = useParams();

  const [query, setQuery] = useInput("");
  const [from, setFrom] = useState<any>("");
  const [to, setTo] = useState<any>("");

  if (!id) {
    return null;
  }

  return (
    <>
      <PageTitle title="List Riwayat Perolehan Poin" />
      <Card>
        <Flex align="center" gap="24px">
          <InputSearch value={query} onChange={setQuery} />
          <Flex w="440px">
            <Datepicker
              type="range"
              value={[from, to]}
              onChange={(e: any) => {
                setFrom(e[0]);
                setTo(e[1]);
              }}
            />
          </Flex>
        </Flex>
        <TablePointHistory userId={id} keyword={query} from={from} to={to} />
      </Card>
    </>
  );
}

export default PointHistory;
