import { Avatar, ModalProps } from "antd";
import { Flex, Text } from "components/Base";
import { Modal } from "components/Feedback";
// import postStore from "lib/stores/slices/post";
import { useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { handleErrorImage } from "utils/image";
import usePostStore from "../stores/postStore";

interface ModalLikeProps extends ModalProps {
  idPost: number;
}

function ModalLike({ idPost, visible, ...props }: ModalLikeProps) {
  const likes = usePostStore((state) => state.likes);
  const getLikes = usePostStore((state) => state.getLikes);

  const [page, setPage] = useState(1);
  const limit = 15;

  useEffect(() => {
    return () => {
      setPage(1);
    };
  }, []);

  useEffect(() => {
    if (idPost && visible) {
      getLikes(idPost, { page, limit });
    }
  }, [idPost, visible]);

  const likeList = likes[idPost]?.data || [];

  const handleLoadMore = () => {
    const total = likes[idPost]?.total;
    if (likeList.length >= total) {
      return;
    }

    const newPage = page + 1;
    setPage(newPage);

    getLikes(idPost, { page: newPage, limit });
  };

  const length = likes[idPost]?.total || 0;
  const hasMore = likeList.length < length;

  return (
    <Modal
      title="Disukai oleh"
      visible={visible}
      footer={null}
      destroyOnClose
      {...props}
    >
      <InfiniteScroll
        next={handleLoadMore}
        dataLength={length}
        hasMore={hasMore}
        loader={
          <Text color="$coolGray300" size="$xs" align="center">
            Loading...
          </Text>
        }
        endMessage={null}
        style={{ maxHeight: "500px" }}
      >
        {likeList.map((item: any) => (
          <Flex
            key={item.id}
            alignItems="center"
            justify="space-between"
            mb="12px"
          >
            <Flex align="center" gap="12px">
              <Avatar
                size={40}
                src={
                  <img
                    src={item.user.photo}
                    alt={item.user.name}
                    onError={(e) => handleErrorImage(e)}
                  />
                }
              />
              <Text size="$md">{item.user.name}</Text>
            </Flex>
          </Flex>
        ))}
      </InfiniteScroll>
    </Modal>
  );
}

export default ModalLike;
