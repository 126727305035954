import { useQuery } from "@tanstack/react-query";
import useDebounce from "hooks/useDebounce";
import report from "lib/api/report";
import { useState } from "react";

interface UseReportListProps {
  keyword?: string;
}

function useReportList({ keyword }: UseReportListProps) {
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const debouncedKeyword = useDebounce(keyword, 500);

  const [total, setTotal] = useState(0);

  const { data, isLoading, refetch } = useQuery({
    queryKey: ["report-story-list", { page, limit, keyword: debouncedKeyword }],
    queryFn: async () => {
      try {
        // fetch data here
        const res = await report.getReportStoryList({
          page,
          limit,
          query: debouncedKeyword,
        });

        if (!res.data) {
          return [];
        }

        const { data, error } = res.data;

        if (error) {
          throw new Error(error);
        }

        setTotal(data.total);
        return data.data;
      } catch (e) {
        // do something
        return [];
      }
    },
  });

  const onChangePage = (newPage: number, newLimit: number) => {
    setPage(newPage);
    setLimit(newLimit);
  };

  return {
    data,
    isLoading,
    total,

    page,
    limit,
    onChangePage,
    refetch,
  };
}

export default useReportList;
