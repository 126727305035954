import { Divider, Select as AntSelect, message } from "antd";
import { Card, Button, Grid, Flex } from "components/Base";
import { Input, Select, Switch, Phone } from "components/Form";
import roleStore from "features/role-management/roleStore";
import If from "global/ErrorHandlers/If";
import ModalDeactivate from "global/Modals/ModalDeactivate";
import useInput from "hooks/useInput";
import admin from "lib/api/admin";
// import roleStore from "lib/stores/slices/role";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { convertToEventTarget } from "utils/input";
import { notifySuccess } from "utils/notification";

const { Option } = AntSelect;

interface EditableFormProps {
  initialValues?: any;
  onSubmit: (values: any) => void;
}

function FormUser({ initialValues, onSubmit }: EditableFormProps) {
  const navigate = useNavigate();
  const roleList = roleStore((state) => state.mini);
  const getRole = roleStore((state) => state.getMiniList);

  const [showDeactivate, setShowDeactivate] = useState(false);

  const [name, setName] = useInput("");
  const [email, setEmail] = useInput("");
  const [phone, setPhone] = useInput("");
  const [password, setPassword] = useInput("");
  const [confPassword, setConfPassword] = useInput("");

  const [active, setActive] = useState(true);
  const [role, setRole] = useState("");

  useEffect(() => {
    getRole();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (initialValues) {
      setName({ target: { value: initialValues.name } });
      setEmail({ target: { value: initialValues.email } });
      setPassword(convertToEventTarget("loremipsum"));
      setActive(initialValues.active);
      setRole(initialValues.id_role);
      setPhone({ target: { value: initialValues.phone } });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialValues]);

  const handleSubmit = (e: any) => {
    e?.preventDefault();

    if (!email || !name || !role || !phone) {
      message.error("Semua field harus diisi");
      return;
    }

    if (initialValues && !active) {
      setShowDeactivate(true);
      return;
    }

    if (!initialValues && password !== confPassword) {
      message.error("Password tidak sama");
      return;
    }

    const data: Record<string, any> = {
      name,
      email,
      active,
      id_role: role,
      phone,
    };

    if (!initialValues && password) {
      data.password = password;
    }

    onSubmit(data);
  };

  const handleDeactivate = () => {
    const data = {
      name,
      email,
      active: false,
      id_role: role,
      phone,
    };

    onSubmit(data);
  };

  const handleCancel = () => {
    navigate("/user-management");
  };

  const handlePasswordChange = async () => {
    const res = await admin.resetPassword(initialValues.id);

    if (!res.data) {
      message.error("Gagal meminta perubahan password");
      return;
    }

    const { error, message: msg } = res.data;

    if (error) {
      message.error(msg);
      return;
    }

    notifySuccess({
      message: "Berhasil meminta perubahan password",
      description: "Silakan cek email untuk melihat password baru",
    });
  };

  return (
    <>
      <Card br="$rounded-lg" boxShadow="$shadow-sm">
        <form onSubmit={handleSubmit}>
          <Grid columns="repeat(2, 1fr)" gap="12px 24px" p="16px">
            <Input
              value={name}
              onChange={setName}
              label="Nama lengkap"
              required
            />
            <Switch
              checked={active}
              onChange={setActive}
              label="Status"
              content="Aktif"
              required
            />
            <Input
              value={email}
              onChange={setEmail}
              label="Email"
              type="email"
              required
            />
            <Input
              value={password}
              onChange={setPassword}
              type="password"
              label="Password"
              disabled={!!initialValues}
              required
            />
            <Phone
              label="Nomor handphone"
              value={phone}
              onChange={setPhone}
              required
            />
            <If condition={!initialValues}>
              <Input
                value={confPassword}
                onChange={setConfPassword}
                label="Ulangi Kata Sandi"
                type="password"
                required
              />
            </If>
            <If condition={!!initialValues}>
              <Flex w="180px" mt="24px">
                <Button
                  htmlType="button"
                  color="secondary"
                  onClick={handlePasswordChange}
                >
                  Atur Ulang Kata Sandi
                </Button>
              </Flex>
            </If>
            <Select value={role} onChange={setRole} label="Role" required>
              {roleList.map((item) => (
                <Option key={item.id} value={item.id}>
                  {item.name}
                </Option>
              ))}
            </Select>
          </Grid>
          <Divider />
          <Flex align="center" justify="flex-end" gap="16px" p="8px">
            <Button htmlType="button" onClick={handleCancel}>
              Batalkan
            </Button>
            <Button htmlType="submit" type="primary">
              Simpan
            </Button>
          </Flex>
        </form>
      </Card>
      <ModalDeactivate
        visible={showDeactivate}
        onCancel={() => setShowDeactivate(false)}
        onConfirm={handleDeactivate}
      />
    </>
  );
}

export default FormUser;
