import { capitalizeFirstLetter } from "./general";

export const getMediaName = (unparsedUrl: string) => {
  if (!unparsedUrl) return "-";

  let url = unparsedUrl.substr(1 + unparsedUrl.lastIndexOf("/"));
  const splittedUrl = url.split("-");
  url = splittedUrl.length > 3 ? url.split("-").slice(3).join(" ") : url;

  return capitalizeFirstLetter(url);
};

export const getMediaExtension = (unparsedUrl: string) => {
  if (!unparsedUrl) return "-";

  const url = unparsedUrl.substr(1 + unparsedUrl.lastIndexOf("/"));
  const splittedUrl = url.split(".");
  return splittedUrl[splittedUrl.length - 1].toLowerCase();
};
