import authStore from "lib/stores/entities/auth";
import { useEffect } from "react";
import { Outlet, Route, useNavigate } from "react-router-dom";
import { getToken } from "utils/authStore";

function PrivateRoute() {
  const navigate = useNavigate();

  const authUser = authStore((state) => state.user);

  useEffect(() => {
    const token = authUser || getToken();

    if (!token) {
      navigate("/logout");
    }
  }, [authUser]);

  return <Outlet />;
}

export default PrivateRoute as typeof Route;
