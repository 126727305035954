import produce from "immer";
import configApi from "lib/api/config";
import { GetListParams } from "types/api";
import { IContactItem } from "types/responses/config";
import create from "zustand";

interface ContactStore {
  list: Array<IContactItem>;
  detail: Record<string, IContactItem>;
  total: number;
  getList: (params: GetListParams) => void;
  getDetail: (id: number) => void;
}

const useContactStore = create<ContactStore>((set, get) => ({
  list: [],
  detail: {},
  total: 0,
  getList: async (params) => {
    const res = await configApi.getContactList(params);

    if (!res.data) {
      return;
    }

    const { data, error } = res.data;

    if (error) {
      return;
    }

    set({ list: data.data, total: data.total });
  },
  getDetail: async (id: number) => {
    const res = await configApi.getContactDetail(id);

    if (!res.data) {
      return;
    }

    const { data, error } = res.data;

    if (error) {
      return;
    }

    return set(
      produce((state: ContactStore) => {
        state.detail[id] = data;
      }),
    );
  },
}));

export default useContactStore;
