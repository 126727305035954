import Reports from "features/7d/reports";
import MainLayout from "global/MainLayout";

function ReportsPage() {
  return (
    <MainLayout>
      <Reports />
    </MainLayout>
  );
}

export default ReportsPage;
