import { message } from "antd";
import PageTitle from "global/PageTitle";
import stepApi from "lib/api/7ds";
import { useNavigate } from "react-router-dom";
import { notifySuccess } from "utils/notification";
import FormSteps from "../components/FormSteps";

function AddStep7d() {
  const navigate = useNavigate();

  const handleSubmit = async (data: any) => {
    const res = await stepApi.addSteps(data);

    if (!res.data) {
      message.error("Gagal menambahkan 7D");
      return;
    }

    const { error } = res.data;

    if (error) {
      message.error(error);
      return;
    }

    notifySuccess({
      message: "Berhasil menambahkan 7d step",
    });

    // Redirect to list page
    navigate("/step-7d");
  };
  return (
    <>
      <PageTitle title="Menambahkan 7D" backUrl="/step-7d" />
      <FormSteps onSubmit={handleSubmit} />
    </>
  );
}

export default AddStep7d;
