import { Modal } from "antd";
import { Button, Flex, Text } from "components/Base";
import { Datepicker } from "components/Form";
import { format } from "date-fns";
import exportFromJSON from "export-from-json";
import dApi from "lib/api/7ds";
import { useState } from "react";

interface ModalDownloadProps {
  visible: boolean;
  onCancel: () => void;
}

function ModalDownload({ visible, onCancel }: ModalDownloadProps) {
  const [from, setFrom] = useState<any>("");
  const [to, setTo] = useState<any>("");

  const handleCancel = () => {
    setFrom("");
    setTo("");
    onCancel();
  };

  const mapDataReport = (data: Array<any>) => {
    return data.map((item) => ({
      ID: item.id,
      Nama: item.user.name,
      Judul: item.title,
      Deskripsi: item.content,
      Waktu: format(new Date(item.created_at), "HH:mm:ss"),
      Tanggal: format(new Date(item.created_at), "dd-MM-yyyy"),
      Lokasi: item.location || "-",
      Status: item["7d_take"].status,
    }));
  };

  const handleDownload = async () => {
    if (!from || !to) {
      return;
    }

    const fromDate = format(from, "yyyy-MM-dd");
    const toDate = format(to, "yyyy-MM-dd");

    const res = await dApi.downloadReport(fromDate, toDate);

    if (res.status === 200 && res.data.data) {
      const fileName = `report-7d-${fromDate}-${toDate}`;
      const exportType = exportFromJSON.types.xls;

      exportFromJSON({
        data: mapDataReport(res.data.data),
        fileName,
        exportType,
      });
      handleCancel();
    }
  };

  return (
    <Modal open={visible} onCancel={handleCancel} footer={null}>
      <Text size="$xl" weight="$bold" mb="25px">
        Download Data
      </Text>
      <Text color="$coolGray500" mb="12px">
        Untuk mendownload data Laporan 7D, silahkan filter range tanggal
        terlebih dahulu
      </Text>
      <Datepicker
        type="range"
        value={[from, to]}
        onChange={(e: any) => {
          setFrom(e[0]);
          setTo(e[1]);
        }}
      />
      <Flex justify="flex-end" mt="24px" gap="12px">
        <Button onClick={handleCancel}>Batalkan</Button>
        <Button
          disabled={!from || !to}
          color="primary"
          onClick={handleDownload}
        >
          Download
        </Button>
      </Flex>
    </Modal>
  );
}

export default ModalDownload;
