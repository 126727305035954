import { message } from "antd";
import PageTitle from "global/PageTitle";
import config from "lib/api/config";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { IAddFAQ } from "types/responses/config";
import { notifySuccess } from "utils/notification";
import FormFAQ from "./components/FormFAQ";
import useFAQStore from "./faqStore";

function EditFAQ() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [detail, getDetail] = useFAQStore((s) => [s.detail, s.getDetail]);

  useEffect(() => {
    if (id) {
      getDetail(Number(id));
    }
  }, [id]);

  const handleSubmit = async (data: IAddFAQ) => {
    const res = await config.updateFaq(Number(id), data);

    if (!res.data) {
      message.error("Gagal mengubah FAQ");
      return;
    }

    const { error } = res.data;

    if (error) {
      message.error(error);
      return;
    }

    notifySuccess({
      message: "Berhasil mengubah FAQ",
    });

    // Redirect to list page
    navigate("/faq");
  };

  if (!id || !detail[id]) {
    return null;
  }

  const data = detail[id];

  return (
    <>
      <PageTitle title="Mengubah Data FAQ" backUrl="/faq" />
      <FormFAQ initialValues={data} onSubmit={handleSubmit} />
    </>
  );
}

export default EditFAQ;
