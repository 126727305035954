import { Avatar as AntdAvatar } from "antd";
import { styled, config } from "configs/stitches.config";

export const Avatar = styled(AntdAvatar, {
  position: "relative",
  padding: "4px",
  background: "linear-gradient(45deg, #55B4E4, #76FA9B)",
  cursor: "pointer",

  img: {
    br: "inherit",
  },

  variants: {
    viewed: {
      true: {
        background: "$coolGray300",
        img: {
          filter: "grayscale(100%)",
        },
      },
    },
  },
});
