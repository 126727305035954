import EditContact from "features/contact/edit-contact";
import MainLayout from "global/MainLayout";

function EditContactPage() {
  return (
    <MainLayout>
      <EditContact />
    </MainLayout>
  );
}

export default EditContactPage;
