import { TableColumnsType } from "antd";
import { Flex, Image, Text } from "components/Base";
import TableList from "global/TableList";
import ButtonAction from "global/TableList/ButtonAction";
import { ILevelItem } from "types/responses/level";
import useLevelList from "../hooks/useLevelList";

interface TableLevelProps {
  keyword?: string;
}

function TableLevel({ keyword }: TableLevelProps) {
  const { data, total, page, limit, isLoading, onChangePage } = useLevelList({
    keyword,
  });

  const columns: TableColumnsType<ILevelItem> = [
    {
      title: "Level",
      key: "level-name",
      width: "25%",
      render: (_, { name, media }) => {
        return (
          <Flex alignItems="center" gap="12px">
            <Image size="42px" src={media} alt={`level-${name}`} />
            <Text>{name}</Text>
          </Flex>
        );
      },
    },
    {
      title: "Start Score",
      key: "start_score",
      dataIndex: "start_score",
    },
    {
      title: "End Score",
      key: "end_score",
      dataIndex: "end_score",
    },
    {
      title: "",
      key: "action",
      dataIndex: "id",
      render: (id) => (
        <ButtonAction id={id} url="/levels" view={false} delete={false} />
      ),
    },
  ];

  return (
    <TableList
      columns={columns}
      dataSource={data}
      page={page}
      limit={limit}
      total={total}
      loading={isLoading}
      onChangePage={onChangePage}
    />
  );
}

export default TableLevel;
