import EditStep7d from "features/7d/steps/edit-step";
import MainLayout from "global/MainLayout";

function EditStep7DPage() {
  return (
    <MainLayout>
      <EditStep7d />
    </MainLayout>
  );
}

export default EditStep7DPage;
