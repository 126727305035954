import AddMasterCategory from "features/master-data/category/add-category";
import MainLayout from "global/MainLayout";

function AddMasterCategoryPage() {
  return (
    <MainLayout>
      <AddMasterCategory />
    </MainLayout>
  );
}

export default AddMasterCategoryPage;
