import request from "configs/request";
import { APIPromiseResponse } from "types/api";

const ADMIN_URL = "/admin";

interface GetListParams {
  page?: number;
  limit?: number;
  query?: string;
}

const getList = ({
  page = 1,
  limit = 10,
  query = undefined,
}: GetListParams): APIPromiseResponse => {
  return request.get(`${ADMIN_URL}/list`, { params: { page, limit, query } });
};

const getDetail = (id: number): APIPromiseResponse => {
  return request.get(`${ADMIN_URL}/detail/${id}`);
};

const add = (data: any): APIPromiseResponse => {
  return request.post(`${ADMIN_URL}/add`, data);
};

const update = (id: number, data: any): APIPromiseResponse => {
  return request.put(`${ADMIN_URL}/update/${id}`, data);
};

const deleteAdmin = (id: number): APIPromiseResponse => {
  return request.delete(`${ADMIN_URL}/delete/${id}`);
};

const download = (): APIPromiseResponse => {
  return request.get(`${ADMIN_URL}/download`);
};

const resetPassword = (id: number): APIPromiseResponse => {
  return request.post(`${ADMIN_URL}/reset-password/${id}`);
};

export default {
  getList,
  getDetail,
  add,
  update,
  deleteAdmin,
  download,
  resetPassword,
};
