import { Button, Flex } from "components/Base";
import If from "global/ErrorHandlers/If";

interface ButtonSubmitProps {
  cancelText?: string;
  submitText?: string;
  onCancel?: () => void;
  cancel?: boolean;
}

function ButtonSubmit({
  cancelText = "Batalkan",
  submitText = "Simpan",
  onCancel,
  cancel = true,
}: ButtonSubmitProps) {
  return (
    <Flex align="center" justify="flex-end" gap="16px" p="8px">
      <If condition={cancel}>
        <Button htmlType="button" onClick={onCancel}>
          {cancelText}
        </Button>
      </If>
      <Button htmlType="submit" type="primary">
        {submitText}
      </Button>
    </Flex>
  );
}

export default ButtonSubmit;
