import roleApi from "lib/api/role";
import { StoreSlice } from "lib/stores/types";
import { GetListParams } from "types/api";
import createStore from "zustand";

interface RoleSlice {
  list: Record<string, any>[];
  modules: Record<string, any>[];
  total: number;
  mini: Record<string, any>[];
  getMiniList: () => void;
  getList: ({ page, limit, query }: GetListParams) => void;
  getModules: () => void;
  deleteRole: (id: number) => Promise<boolean>;
}

const roleSlice: StoreSlice<RoleSlice> = (set, get) => ({
  list: [],
  modules: [],
  mini: [],
  total: 0,
  getMiniList: async () => {
    const res = await roleApi.getRoleList("mini");

    if (!res.data) {
      return;
    }

    const { data, error } = res.data;

    if (error) {
      return;
    }

    set({ mini: data });
  },
  getList: async ({ page = 1, limit = 10, query = undefined }) => {
    const res = await roleApi.getRoleList("all", page, limit, query);

    if (!res.data) {
      return;
    }

    const { data, error } = res.data;

    if (error) {
      return;
    }

    set({ list: data.data, total: data.total });
  },
  getModules: async () => {
    const res = await roleApi.getRoleModules();

    if (res.status !== 200) {
      // if there will be error
      return;
    }

    const { data, error } = res.data;

    if (error) {
      // if there will be error
      return;
    }

    set({ modules: data });
  },
  deleteRole: async (id: number) => {
    const res = await roleApi.deleteRole(id);

    if (!res.data) {
      return false;
    }

    const { error } = res.data;

    if (error) {
      return false;
    }

    return true;
  },
});

const roleStore = createStore(roleSlice);

export default roleStore;
