import { message } from "antd";
import PageTitle from "global/PageTitle";
import level from "lib/api/level";
import { useNavigate, useParams } from "react-router-dom";
import { notifySuccess } from "utils/notification";
import FormLevel from "./components/FormLevel";
import useLevelDetail from "./hooks/useLevelDetail";

function EditLevel() {
  const { id } = useParams();
  const navigate = useNavigate();

  const { data } = useLevelDetail(id);

  const handleSubmit = async (data: any) => {
    const res = await level.update(Number(id), data);

    if (!res.data) {
      message.error("Gagal mengubah level");
      return;
    }

    const { error } = res.data;

    if (error) {
      message.error(error);
      return;
    }

    notifySuccess({
      message: "Berhasil mengubah level",
    });

    navigate("/levels");
  };

  if (!id || !data) {
    return null;
  }

  return (
    <>
      <PageTitle title="Edit Level" backUrl="/levels" />
      <FormLevel initialValues={data} onSubmit={handleSubmit} />
    </>
  );
}

export default EditLevel;
