import request from "configs/request";
import { APIPromiseResponse, GetListParams } from "types/api";
import { AddCategory } from "types/responses/category";

const URL = "/category";

const getList = (params: GetListParams) => {
  return request.get(`${URL}/list`, { params });
};

const getDetail = (id: number) => {
  return request.get(`${URL}/detail/${id}`);
};

const add = (data: AddCategory) => {
  return request.post(`${URL}/add`, data);
};

const update = (id: number, data: AddCategory) => {
  return request.put(`${URL}/update/${id}`, data);
};

const deleteCategory = (id: number) => {
  return request.delete(`${URL}/delete/${id}`);
};

export default {
  getList,
  getDetail,
  add,
  update,
  deleteCategory,
};
