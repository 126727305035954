import CeritaDesa from "features/users/cerita-desa";
import MainLayout from "global/MainLayout";

function CeritaDesaPage() {
  return (
    <MainLayout>
      <CeritaDesa />
    </MainLayout>
  );
}

export default CeritaDesaPage;
