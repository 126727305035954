import { Divider, message } from "antd";
import { Card, Grid } from "components/Base";
import { Input, Phone, Switch } from "components/Form";
import { BaseFormProps } from "components/Form/types";
import ButtonSubmit from "global/Form/ButtonSubmit";
import useInput from "hooks/useInput";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

function FormContact({ initialValues, onSubmit }: BaseFormProps) {
  const navigate = useNavigate();

  const [name, setName] = useInput("");
  const [phone, setPhone] = useInput("");

  const [active, setActive] = useState(true);

  useEffect(() => {
    if (initialValues) {
      const phoneNumber = cleanPhoneNumber(initialValues.phone);
      setName({ target: { value: initialValues.name } });
      setPhone({ target: { value: phoneNumber } });
      setActive(initialValues.active);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialValues]);

  const cleanPhoneNumber = (num: string) => {
    if (num.startsWith("62")) {
      return num.slice(2);
    } else if (num.startsWith("0")) {
      return num.slice(1);
    } else {
      return num;
    }
  };

  const handleSubmit = (e: any) => {
    e?.preventDefault();

    if (!name || !phone) {
      message.error("Nama dan nomor handphone harus diisi");
      return;
    }

    const data = {
      name,
      phone: `62${phone}`,
      active,
    };

    onSubmit(data);
  };

  const handleCancel = () => {
    navigate("/contact");
  };

  return (
    <Card p="32px">
      <form onSubmit={handleSubmit}>
        <Grid columns="repeat(2, 1fr)" gap="24px">
          <Input value={name} onChange={setName} label="Nama Admin" required />
          <Switch
            checked={active}
            onChange={setActive}
            label="Status"
            content="Aktif"
            required
          />
          <Phone
            label="Nomor handphone"
            value={phone}
            onChange={setPhone}
            required
          />
        </Grid>
        <Divider />
        <ButtonSubmit onCancel={handleCancel} />
      </form>
    </Card>
  );
}

export default FormContact;
