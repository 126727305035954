import { message } from "antd";
import produce from "immer";
import users from "lib/api/users";
import { GetListParams } from "types/api";
import { IUserItem } from "types/responses/users";
import create from "zustand";

interface UserStore {
  list: any[];
  detail: Record<string, IUserItem>;
  total: number;
  loading: boolean;
  getList: (params: GetListParams) => void;
  getDetail: (id: number) => void;
  delete: (id: number) => Promise<boolean>;
  toggleVerify: (id: number, verified: boolean) => void;
}

const useUserStore = create<UserStore>((set, get) => ({
  list: [],
  detail: {},
  total: 0,
  loading: false,
  getList: async (params) => {
    const res = await users.getUsers(params);

    if (!res.data) {
      return;
    }

    const { data, error } = res.data;

    if (error) {
      return;
    }

    set({ list: data.data, total: data.total });
  },
  getDetail: async (id) => {
    const res = await users.getUserDetail(id);

    if (!res.data) {
      return;
    }

    const { data, error } = res.data;

    if (error) {
      return;
    }

    set((state) =>
      produce(state, (draft) => {
        draft.detail[id] = data;
      }),
    );
  },
  delete: async (id) => {
    const res = await users.delete(id);

    if (!res.data) {
      return false;
    }

    const { error } = res.data;

    if (error) {
      return false;
    }

    return true;
  },
  toggleVerify: async (id, verified) => {
    try {
      set({ loading: true });

      const res = await users.toggleVerify(id, verified);

      if (!res.data) {
        return;
      }

      message.success("Berhasil mengubah status Verified Admin");
      // refetch the data upon success
      get().getDetail(id);
    } catch (e) {
      // do something
    } finally {
      set({ loading: false });
    }
  },
}));

export default useUserStore;
