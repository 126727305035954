import { message } from "antd";
import PageTitle from "global/PageTitle";
import role from "lib/api/role";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { notifySuccess } from "utils/notification";
import FormRole from "./components/FormRole";

function EditRole() {
  const { id } = useParams();
  const navigate = useNavigate();

  const [initialValues, setInitialValues] = useState<null | Record<
    string,
    any
  >>(null);

  useEffect(() => {
    if (id) {
      getRoleDetail();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const getRoleDetail = async () => {
    const res = await role.getRoleDetail(Number(id));

    if (!res.data) {
      message.error("Gagal mengambil detail role");
      return;
    }

    const { error, data } = res.data;

    if (error) {
      message.error(error);
      return;
    }

    setInitialValues(data);
  };

  const handleSubmit = async (data: any) => {
    const res = await role.updateRole(Number(id), data);
    if (!res.data) {
      message.error("Gagal mengubah role");
      return;
    }
    const { error } = res.data;
    if (error) {
      message.error("Terjadi kesalahan");
      return;
    }
    notifySuccess({
      message: "Berhasil mengubah role",
    });
    // Redirect to list page
    navigate("/roles");
  };

  return (
    <>
      <PageTitle title="Mengubah Data Role" backUrl="/roles" />
      <FormRole onSubmit={handleSubmit} initialValues={initialValues} />
    </>
  );
}

export default EditRole;
