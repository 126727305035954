import { Card } from "components/Base";
import ButtonAdd from "global/ButtonAdd";
import InputSearch from "global/InputSearch";
import PageTitle from "global/PageTitle";
import useInput from "hooks/useInput";
import TableRole from "./components/Table";

function RoleManagement() {
  const [query, setQuery] = useInput("");

  return (
    <>
      <PageTitle title="List Role">
        <ButtonAdd to="/roles/add" />
      </PageTitle>
      <Card>
        <InputSearch value={query} onChange={setQuery} />
        <TableRole keyword={query} />
      </Card>
    </>
  );
}

export default RoleManagement;
