import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import Sidebar from "./Sidebar";
import { MainContent } from "./styled";
import Header from "../Header";

const queryClient = new QueryClient();

interface MainLayoutProps {
  children: React.ReactNode;
}

function MainLayout({ children }: MainLayoutProps) {
  return (
    <QueryClientProvider client={queryClient}>
      <Header />
      <Sidebar />
      <MainContent>{children}</MainContent>
    </QueryClientProvider>
  );
}

export default MainLayout;
