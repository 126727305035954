import { message } from "antd";
import PageTitle from "global/PageTitle";
import admin from "lib/api/admin";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { notifySuccess } from "utils/notification";
import adminStore from "./adminStore";
import FormUser from "./components/FormUser";

function EditUser() {
  const detail = adminStore((state) => state.detail);
  const getDetail = adminStore((state) => state.getDetail);

  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (id) {
      getDetail(Number(id));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const handleSubmit = async (data: any) => {
    const res = await admin.update(Number(id), data);

    if (!res.data) {
      message.error("Gagal mengubah admin");
      return;
    }

    const { error } = res.data;

    if (error) {
      message.error(error);
      return;
    }
    notifySuccess({
      message: "Berhasil mengubah admin",
    });
    // Redirect to list page
    navigate("/user-management");
  };

  if (!id) {
    return null;
  }

  const detailUser = detail[id];

  if (!detailUser) {
    return null;
  }

  return (
    <>
      <PageTitle backUrl="/user-management" title="Ubah Data User Admin" />
      <FormUser onSubmit={handleSubmit} initialValues={detailUser} />
    </>
  );
}

export default EditUser;
