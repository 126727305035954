import request from "configs/request";
import { APIPromiseResponse, GetListParams } from "types/api";
import { IAddContact, IAddFAQ, UpdateAbout } from "types/responses/config";

const ABOUT_URL = "/about";
const FAQ_URL = "/faq";
const CONTACT_URL = "/contact";

const getAbout = (): APIPromiseResponse => {
  return request.get(`${ABOUT_URL}`);
};

const updateAbout = (params: UpdateAbout): APIPromiseResponse => {
  return request.put(`${ABOUT_URL}/update`, params);
};

// FAQ APIs

const getFaqList = (params: GetListParams): APIPromiseResponse => {
  return request.get(`${FAQ_URL}/list`, { params });
};

const getFaqDetail = (id: number): APIPromiseResponse => {
  return request.get(`${FAQ_URL}/detail/${id}`);
};

const addFaq = (data: IAddFAQ): APIPromiseResponse => {
  return request.post(`${FAQ_URL}/add`, data);
};

const updateFaq = (id: number, data: IAddFAQ): APIPromiseResponse => {
  return request.put(`${FAQ_URL}/update/${id}`, data);
};

const deleteFaq = (id: number): APIPromiseResponse => {
  return request.delete(`${FAQ_URL}/delete/${id}`);
};

// Contact APIs
const getContactList = (params: GetListParams): APIPromiseResponse => {
  return request.get(`${CONTACT_URL}/list`, { params });
};

const getContactDetail = (id: number): APIPromiseResponse => {
  return request.get(`${CONTACT_URL}/detail/${id}`);
};

const addContact = (data: IAddContact): APIPromiseResponse => {
  return request.post(`${CONTACT_URL}/add`, data);
};

const updateContact = (id: number, data: IAddContact): APIPromiseResponse => {
  return request.put(`${CONTACT_URL}/update/${id}`, data);
};

const deleteContact = (id: number): APIPromiseResponse => {
  return request.delete(`${CONTACT_URL}/delete/${id}`);
};

export default {
  getAbout,
  updateAbout,

  // FAQs
  getFaqList,
  getFaqDetail,
  addFaq,
  updateFaq,
  deleteFaq,

  // Contacts
  getContactList,
  getContactDetail,
  addContact,
  updateContact,
  deleteContact,
};
