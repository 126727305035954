import Contact from "features/contact";
import MainLayout from "global/MainLayout";

function ContactPage() {
  return (
    <MainLayout>
      <Contact />
    </MainLayout>
  );
}

export default ContactPage;
