import points from "lib/api/points";
import { ILeaderboardItem } from "types/responses/point";
import create from "zustand";

interface LeaderboardStore {
  list: Array<ILeaderboardItem>;
  total: number;
  getLeaderboard: () => void;
}

const useLeaderboardStore = create<LeaderboardStore>((set) => ({
  list: [],
  total: 0,
  getLeaderboard: async () => {
    const res = await points.getLeaderboard();

    if (!res.data) {
      return;
    }

    const { data, error } = res.data;

    if (error) {
      return;
    }

    set({ list: data.data, total: data.total });
  },
}));

export default useLeaderboardStore;
