import produce from "immer";
import points, { GetPointParams } from "lib/api/points";
import create from "zustand";

interface PointStore {
  list: Record<string, any>;
  total: number;
  getList: (userId: number, params: GetPointParams) => void;
}

const usePointStore = create<PointStore>((set) => ({
  list: [],
  total: 0,
  getList: async (id, params) => {
    const res = await points.getUserPointHistory(id, params);

    if (!res.data) {
      return;
    }

    const { data, error } = res.data;

    if (error) {
      return;
    }

    set((state) =>
      produce(state, (draft) => {
        draft.list[id] = data.data;
        draft.total = data.total;
      }),
    );
  },
}));

export default usePointStore;
