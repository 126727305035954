import { Menu } from "antd";
import { styled } from "configs/stitches.config";

const { SubMenu: AntSubMenu, Item: AntItem } = Menu;

export const SubMenu = styled(AntSubMenu, {
  "& .ant-menu-item-icon": {
    alignItems: "center",
    display: "flex",
  },

  "& .ant-menu-submenu-title": {
    borderRadius: "0",
    marginLeft: 0,
    marginRight: 0,
    width: "100%",
  },
});

export const Item = styled(AntItem, {
  "&.ant-menu-item": {
    borderRadius: "0",
    marginLeft: 0,
    marginRight: 0,
    width: "100%",
  },

  "& .ant-menu-item-icon": {
    alignItems: "center",
    display: "flex",
  },

  "&.ant-menu-item-selected": {
    fontWeight: "$semibold",
  },
});
