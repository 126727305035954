import { message } from "antd";
import { Card, Flex, Grid, Icon, Tag, Text } from "components/Base";
import { InfoItem } from "components/Info";
import PageTitle from "global/PageTitle";
import roleApi from "lib/api/role";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import roleStore from "./roleStore";
import { calculateModule, mapModules } from "./utils";

function DetailRole() {
  const navigate = useNavigate();
  const { id } = useParams();

  const [role, setInitialValues] = useState<null | Record<string, any>>(null);

  useEffect(() => {
    if (id) {
      getRoleDetail();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const getRoleDetail = async () => {
    const res = await roleApi.getRoleDetail(Number(id));

    if (!res.data) {
      message.error("Gagal mengambil detail role");
      return;
    }

    const { error, data } = res.data;

    if (error) {
      message.error(error);
      return;
    }

    setInitialValues(data);
  };

  if (!role) {
    return null;
  }

  const adminRole = calculateModule(role.modules) || [];

  return (
    <>
      <PageTitle title="Detail" backUrl="/roles" />
      <Card p="32px">
        <Grid columns="repeat(2, 1fr)" gap="24px">
          <InfoItem label="Nama Role" value={role.name} />
          <InfoItem label="Status">
            <Tag rounded color={role.active ? "green" : "red"}>
              {role.active ? "Aktif" : "Tidak aktif"}
            </Tag>
          </InfoItem>
          <InfoItem label="Jumlah Modul">
            <Flex direction="column" gap="8px">
              {adminRole.map((role) => (
                <Flex align="center" gap="8px" width="100%">
                  {role.icon && (
                    <Icon
                      alt="label"
                      src={role.icon}
                      mr="16px"
                      color="$primary"
                    />
                  )}
                  {role.feature.map((feat) => (
                    <Text fontSize="12px" as="span">
                      {feat.name}({feat.access.length})
                    </Text>
                  ))}
                </Flex>
              ))}
            </Flex>
          </InfoItem>
        </Grid>
      </Card>
    </>
  );
}

export default DetailRole;
