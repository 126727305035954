import { message } from "antd";
import PageTitle from "global/PageTitle";
import category from "lib/api/category";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { notifySuccess } from "utils/notification";
import useCategoryStore from "./categoryStore";
import FormCategory from "./components/FormCategory";

function EditMasterCategory() {
  const { id } = useParams();
  const navigate = useNavigate();

  const getDetail = useCategoryStore((s) => s.getDetail);
  const detail = useCategoryStore((s) => s.detail);

  useEffect(() => {
    if (id) {
      getDetail(Number(id));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const handleSubmit = async (values: any) => {
    const update = await category.update(Number(id), values);

    if (!update.data) {
      message.error("Gagal mengubah data kategori");
      return;
    }

    const { error } = update.data;

    if (error) {
      message.error(error);
      return;
    }

    notifySuccess({ message: "Berhasil mengubah data kategori" });

    // Redirect to list page
    navigate("/master-data/category");
  };

  if (!id || !detail[id]) {
    return null;
  }

  const detailCategory = detail[id];

  return (
    <>
      <PageTitle
        title="Mengubah Data Kategori"
        backUrl="/master-data/category"
      />
      <FormCategory onSubmit={handleSubmit} initialValues={detailCategory} />
    </>
  );
}

export default EditMasterCategory;
