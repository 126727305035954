import authStore from "lib/stores/entities/auth";
import { useEffect } from "react";
import { Outlet, Route, useNavigate } from "react-router-dom";
import { getToken } from "utils/authStore";

function RestrictedRoute() {
  const navigate = useNavigate();
  const authUser = authStore((state) => state.user);

  useEffect(() => {
    const token = authUser || getToken();

    if (token) {
      navigate("/dashboard");
    }
  }, [authUser]);

  return <Outlet />;
}

export default RestrictedRoute as typeof Route;
