import { MinusIcon } from "@radix-ui/react-icons";
import { Checkbox } from "antd";
import { Flex, Text } from "components/Base";
import { useEffect, useState } from "react";

const CheckboxGroup = Checkbox.Group;

interface ItemProps {
  label?: string;
  featId: string | number;
  initialValues?: Array<any>;
  onChange: (id: string | number, role: any) => void;
}

// ["Edit", "Delete", "Add", "View"];

const plainOptions = [
  { label: "Edit", value: "update_access" },
  { label: "Delete", value: "delete_access" },
  { label: "Add", value: "add_access" },
  { label: "View", value: "view_access" },
];

function Item({
  label,
  featId,
  onChange: handleChange,
  initialValues,
}: ItemProps) {
  const [checkedList, setCheckedList] = useState([]);
  const [indeterminate, setIndeterminate] = useState(false);
  const [checkAll, setCheckAll] = useState(false);

  useEffect(() => {
    if (initialValues && initialValues.length > 0) {
      const list = initialValues;
      // @ts-ignore
      setCheckedList(list);
      setIndeterminate(!!list.length && list.length < plainOptions.length);
      setCheckAll(list.length === plainOptions.length);
    }
  }, [initialValues]);

  const onChange = (list: any) => {
    setCheckedList(list);
    setIndeterminate(!!list.length && list.length < plainOptions.length);
    setCheckAll(list.length === plainOptions.length);
    handleChange(featId, list);
  };

  const onCheckAllChange = (e: any) => {
    // @ts-ignore
    const list = e.target.checked ? plainOptions.map((item) => item.value) : [];
    // @ts-ignore
    setCheckedList(list);
    handleChange(featId, list);

    setIndeterminate(false);
    setCheckAll(e.target.checked);
  };

  return (
    <Flex
      align="center"
      p="24px 16px"
      justify="space-between"
      css={{
        "&:nth-child(odd)": {
          backgroundColor: "#f0fdf4",
        },
      }}
    >
      <Flex align="center" color="$coolGray600">
        <MinusIcon style={{ marginRight: "12px", color: "currentcolor" }} />
        <Text color="inherit">{label}</Text>
      </Flex>
      <Flex
        align="center"
        gap="24px"
        css={{
          "& .ant-checkbox-group": {
            gap: "24px",
            display: "inline-flex",
          },
        }}
        color="$coolGray700"
      >
        <Checkbox
          indeterminate={indeterminate}
          onChange={onCheckAllChange}
          checked={checkAll}
        >
          Semua
        </Checkbox>
        <CheckboxGroup
          options={plainOptions}
          value={checkedList}
          onChange={onChange}
        />
      </Flex>
    </Flex>
  );
}

export default Item;
