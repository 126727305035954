import { Avatar, TableColumnsType } from "antd";
import { Button, Flex, Image, Text } from "components/Base";
import { Modal } from "components/Feedback";
import { format } from "date-fns";
import ModalApprove from "features/report-management/components/modals/ModalApprove";
import ModalReject from "features/report-management/components/modals/ModalReject";
import TableList from "global/TableList";
import { useState } from "react";
import { HiEye } from "react-icons/hi";
import { MdCheck, MdClose } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { IReportStoryItem } from "types/responses/reports";
import useActionStoryReport from "../useActionStoryReport";
import useReportList from "../useReportList";

interface TableReportListProps {
  keyword?: string;
}

function TableReportList({ keyword }: TableReportListProps) {
  const {
    data: reports,
    total,
    page,
    limit,
    isLoading,
    onChangePage,
    refetch,
  } = useReportList({ keyword });

  const navigate = useNavigate();
  const { approveReport, rejectReport } = useActionStoryReport();

  const [selectedReport, setSelectedReport] = useState<IReportStoryItem>(
    {} as IReportStoryItem,
  );
  const [visible, setVisible] = useState(false);
  const [selectedImage, setSelectedImage] = useState<string | null>(null);

  const [showApprove, setShowApprove] = useState(false);
  const [showReject, setShowReject] = useState(false);

  const handleShowStory = (image: string) => {
    setSelectedImage(image);
    setVisible(true);
  };

  const handleOpenAction = (
    report: IReportStoryItem,
    type: "approve" | "reject",
  ) => {
    setSelectedReport(report);

    if (type === "approve") {
      setShowApprove(true);
    } else {
      setShowReject(true);
    }
  };

  const handleCloseAction = () => {
    setShowApprove(false);
    setShowReject(false);
    setSelectedReport({} as IReportStoryItem);
  };

  const handleDetailUser = (id: number) => {
    navigate(`/users/detail/${id}`);
  };

  const handleApprove = () => {
    approveReport.mutate(selectedReport.id, {
      onSuccess: () => {
        refetch();
        handleCloseAction();
      },
    });
  };

  const handleReject = () => {
    rejectReport.mutate(selectedReport.id, {
      onSuccess: () => {
        refetch();
        handleCloseAction();
      },
    });
  };

  const columns: TableColumnsType<TableReportListProps> = [
    {
      title: "Nama",
      key: "name",
      dataIndex: ["story", "user"],
      width: "25%",
      render: ({ photo: avatar, name, id }) => (
        <Flex
          align="center"
          w="100%"
          gap="12"
          style={{ cursor: "pointer" }}
          onClick={() => handleDetailUser(id)}
        >
          <Avatar src={avatar} size={42} />
          <Text css={{ flex: "1" }}>{name}</Text>
        </Flex>
      ),
    },
    {
      title: "Dilihat",
      dataIndex: ["story", "_count", "story_view"],
      key: "seen",
    },
    {
      title: "Disukai",
      dataIndex: ["story", "_count", "story_like"],
      key: "liked",
    },
    {
      title: "Tanggal",
      dataIndex: "created_at",
      key: "date",
      render: (date: string) => format(new Date(date), "dd/MM/yyyy"),
    },
    {
      title: "Jam",
      dataIndex: "created_at",
      key: "jam",
      render: (date: string) => format(new Date(date), "HH:mm"),
    },
    {
      title: "Reporter",
      dataIndex: ["user", "name"],
      key: "reporter",
    },
    {
      title: "Reason",
      dataIndex: "reason",
      key: "reason",
      width: "200px",
      render: (reason: string) => {
        if (!reason) return null;

        return <Text color="$red500">{reason}</Text>;
      },
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      render: (_, report: any) => (
        <Flex alignItems="center" gap="12px">
          <Button
            icon={<HiEye />}
            onClick={() => handleShowStory(report.story.media)}
          />
          <Button
            color="primary"
            icon={<MdCheck />}
            // @ts-ignore
            onClick={() => handleOpenAction(report, "approve")}
          />
          <Button
            color="danger"
            icon={<MdClose />}
            // @ts-ignore
            onClick={() => handleOpenAction(report, "reject")}
          />
        </Flex>
      ),
    },
  ];

  return (
    <>
      <TableList
        columns={columns}
        dataSource={reports}
        page={page}
        limit={limit}
        total={total}
        loading={isLoading}
        onChangePage={onChangePage}
      />
      <Modal
        open={visible}
        onCancel={() => setVisible(false)}
        width={405}
        footer={null}
        className="modal-story"
      >
        {!!selectedImage && <Image src={selectedImage} w="100%" />}
      </Modal>
      <ModalApprove
        name={selectedReport?.user?.name}
        reportType="story"
        open={showApprove}
        onOk={handleApprove}
        onCancel={handleCloseAction}
      />
      <ModalReject
        reportType="story"
        open={showReject}
        onOk={handleReject}
        onCancel={handleCloseAction}
      />
    </>
  );
}

export default TableReportList;
