import { Divider } from "antd";
import { Card, Flex } from "components/Base";
import { Input, Textarea } from "components/Form";
import ButtonSubmit from "global/Form/ButtonSubmit";
import PageTitle from "global/PageTitle";
import useInput from "hooks/useInput";
import { useEffect } from "react";
import { convertToEventTarget } from "utils/input";
import useAboutStore from "./store";

function AboutConfig() {
  const [about, get, update] = useAboutStore((s) => [
    s.about,
    s.getAbout,
    s.updateAbout,
  ]);

  const [title, setTitle] = useInput("");
  const [description, setDescription] = useInput("");

  useEffect(() => {
    get();
  }, []);

  useEffect(() => {
    setTitle(convertToEventTarget(about?.name));
    setDescription(convertToEventTarget(about?.value));
  }, [about]);

  const handleSubmit = (e: any) => {
    e?.preventDefault();

    update({
      name: title,
      description,
    });
  };
  return (
    <>
      <PageTitle title="Tentang Sigap" />
      <Card>
        <form onSubmit={handleSubmit}>
          <Flex direction="column" gap="24px" w="100%">
            <Flex w="510px">
              <Input label="Judul" value={title} onChange={setTitle} required />
            </Flex>
            <Textarea
              label="Deskripsi"
              value={description}
              onChange={setDescription}
              autoSize
              required
            />
          </Flex>
          <Divider />
          <ButtonSubmit cancel={false} />
        </form>
      </Card>
    </>
  );
}

export default AboutConfig;
