import ReportStory from "features/report-management/story";
import MainLayout from "global/MainLayout";

function ReportStoryPage() {
  return (
    <MainLayout>
      <ReportStory />
    </MainLayout>
  );
}

export default ReportStoryPage;
