import { Divider } from "antd";
import { Card, Flex, Grid } from "components/Base";
import { Input, Textarea, Upload } from "components/Form";
import ButtonSubmit from "global/Form/ButtonSubmit";
import useInput from "hooks/useInput";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { IAddLevel, ILevelItem } from "types/responses/level";
import { handleUploadImage } from "utils/image";

interface FormLevelProps {
  initialValues?: ILevelItem;
  onSubmit: (values: IAddLevel) => void;
}

function FormLevel({ initialValues, onSubmit }: FormLevelProps) {
  const navigate = useNavigate();

  const [name, setName] = useInput("");
  const [startScore, setStartScore] = useInput("");
  const [endScore, setEndScore] = useInput("");
  const [benefit, setBenefit] = useInput("");
  const [image, setImage] = useState<any>("");

  useEffect(() => {
    if (initialValues) {
      setName(initialValues.name);
      setStartScore(initialValues.start_score);
      setEndScore(initialValues.end_score);
      setBenefit(initialValues.speciality);
      setImage(initialValues.media);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialValues]);

  const handleChangeImage = (img: any) => {
    setImage(img.file);
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    const media = await handleUploadImage(image, "level");

    const data: IAddLevel = {
      name,
      benefits: benefit,
      start_score: Number(startScore) || 0,
      end_score: Number(endScore) || 0,
      media,
    };

    onSubmit(data);
  };

  const handleCancel = () => {
    navigate("/levels");
  };

  return (
    <Card p="32px">
      <form onSubmit={handleSubmit}>
        <Flex direction="column" gap="24px">
          <Grid columns="repeat(2, 1fr)" gap="24px" w="100%">
            <Upload
              label="Badge Level"
              helper="Dimensi yang direkomendasikan 500 x 500. Maksimal 3MB dengan format PNG, JPEG"
              accept="image/jpg, image/jpeg, image/png, image/svg+xml"
              onChange={handleChangeImage}
              value={image}
              required
            />
            <Input
              label="Nama Level"
              value={name}
              onChange={setName}
              required
            />
            <Input
              label="Start Score"
              value={startScore}
              onChange={setStartScore}
              required
            />
            <Input
              label="End Score"
              value={endScore}
              onChange={setEndScore}
              required
            />
          </Grid>
          <Textarea
            label="Keuntungan Level"
            value={benefit}
            onChange={setBenefit}
            required
            helper="Pisahkan item-item keuntungan level dengan Enter"
          />
        </Flex>
        <Divider />
        <ButtonSubmit onCancel={handleCancel} />
      </form>
    </Card>
  );
}

export default FormLevel;
