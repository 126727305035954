import RoleManagement from "features/role-management";
import MainLayout from "global/MainLayout";

function RoleManagementPage() {
  return (
    <MainLayout>
      <RoleManagement />
    </MainLayout>
  );
}

export default RoleManagementPage;
