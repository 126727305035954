import EditNotification from "features/notification/edit-notification";
import MainLayout from "global/MainLayout";

function EditNotificationPage() {
  return (
    <MainLayout>
      <EditNotification />
    </MainLayout>
  );
}

export default EditNotificationPage;
