import { useQuery } from "@tanstack/react-query";
import level from "lib/api/level";

function useLevelDetail(id?: string) {
  const { data, isLoading, isError, refetch } = useQuery({
    queryKey: ["level-detail", { id }],
    queryFn: async () => {
      try {
        const res = await level.getDetail(Number(id));

        return res.data.data;
      } catch (e) {
        console.error(e);
      }
    },
    enabled: !!id,
  });

  return {
    data,
    isLoading,
    isError,
    refetch,
  };
}

export default useLevelDetail;
