import { Button, Card, Flex } from "components/Base";
import InputSearch from "global/InputSearch";
import PageTitle from "global/PageTitle";
import useInput from "hooks/useInput";
import { useState } from "react";
import { HiOutlineDownload } from "react-icons/hi";
import ModalDownload from "../components/ModalDownload";
import TableReports from "../components/TableReports";

function Reports() {
  const [query, setQuery] = useInput("");
  const [visible, setVisible] = useState(false);

  return (
    <>
      <PageTitle title="List 7D" />
      <Card>
        <Flex justify="space-between">
          <InputSearch value={query} onChange={setQuery} />
          <Button
            color="gray"
            icon={<HiOutlineDownload />}
            onClick={() => setVisible(true)}
          >
            Download Data
          </Button>
        </Flex>
        <TableReports keyword={query} />
      </Card>
      <ModalDownload visible={visible} onCancel={() => setVisible(false)} />
    </>
  );
}

export default Reports;
