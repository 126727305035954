import request from "configs/request";
import { APIPromiseResponse } from "types/api";
import { convertToFormData } from "utils/general";

const API_URL = "/media";

const uploadSingle = (data: any, store?: string): APIPromiseResponse => {
  const formData = convertToFormData({ image: data });
  return request.post(`${API_URL}`, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
    params: {
      store,
    },
  });
};

const uploadMultiple = (data: any, store?: string): APIPromiseResponse => {
  const formData = convertToFormData(data, "images");
  return request.post(`${API_URL}/multiple`, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
    params: {
      store,
    },
  });
};

export default {
  uploadSingle,
  uploadMultiple,
};
