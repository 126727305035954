import { Avatar, TableColumnsType, TableColumnType } from "antd";
import { Flex, Text } from "components/Base";
import { format } from "date-fns";
import TableList from "global/TableList";
import useDebounce from "hooks/useDebounce";
import useLoaded from "hooks/useLoaded";
import { useEffect, useState } from "react";
import usePointStore from "../stores/pointStore";

interface TableProps {
  userId: string;
  keyword?: string;
  from?: string;
  to?: string;
  url?: string;
}

function TablePointHistory({ keyword, from, to, url, userId }: TableProps) {
  const [data, total, getList] = usePointStore((s) => [
    s.list,
    s.total,
    s.getList,
  ]);
  const isLoaded = useLoaded();

  const list = data[userId];

  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const debouncedKeyword = useDebounce(keyword, 500);

  useEffect(() => {
    if (debouncedKeyword !== undefined && isLoaded) {
      const newPage = 1;
      setPage(newPage);

      const query = debouncedKeyword || undefined;
      getList(Number(userId), { page: newPage, limit, query, from, to });
    }
  }, [debouncedKeyword, isLoaded]);

  const handleChangePage = (page: number, pageSize: number) => {
    setPage(page);
    setLimit(limit);

    const query = debouncedKeyword || undefined;
    getList(Number(userId), { page, limit: pageSize, query, from, to });
  };

  const columns: TableColumnsType<TableProps> = [
    {
      title: "Nama",
      key: "name",
      dataIndex: "user",
      width: "25%",
      render: ({ name, photo: avatar }: any) => (
        <Flex alignItems="center" w="100%" gap="12px">
          <Avatar src={avatar} size={42} />
          <Text css={{ flex: "1" }}>{name}</Text>
        </Flex>
      ),
    },
    {
      title: "Point",
      dataIndex: "task",
      key: "point",
      render: (task: any) => {
        let prefix = "+";
        let color = "$primary";

        if (task.subtract) {
          prefix = "-";
          color = "$red500";
        }

        return (
          <Text as="span" color={color}>
            {prefix}
            {task.point}
          </Text>
        );
      },
    },
    {
      title: "Kegiatan",
      dataIndex: "task",
      key: "activity",
      width: "25%",
      render: (task) => task.name,
    },
    {
      title: "Jam",
      dataIndex: "created_at",
      key: "jam",
      render: (time) => format(new Date(time), "HH:mm"),
    },
    {
      title: "Tanggal",
      dataIndex: "created_at",
      key: "date",
      render: (time) => format(new Date(time), "dd/MM/yyyy"),
    },
  ];

  return (
    <TableList
      dataSource={list}
      columns={columns}
      page={page}
      total={total}
      limit={limit}
      onChangePage={handleChangePage}
    />
  );
}

export default TablePointHistory;
