import DetailReport from "features/7d/reports/detail-report";
import MainLayout from "global/MainLayout";

function DetailReportPage() {
  return (
    <MainLayout>
      <DetailReport />
    </MainLayout>
  );
}

export default DetailReportPage;
