import { Avatar, TableColumnsType } from "antd";
import { Button, Flex, Image, Text } from "components/Base";
import { format } from "date-fns";
import ModalDelete from "global/Modals/ModalDelete";
import TableList from "global/TableList";
import { TableBaseProps } from "global/TableList/types";
import useDebounce from "hooks/useDebounce";
import useLoaded from "hooks/useLoaded";
import { useEffect, useState } from "react";
import { HiEye, HiTrash } from "react-icons/hi";
import { Link } from "react-router-dom";
import { notifySuccess } from "utils/notification";
import usePostStore from "../stores/postStore";

interface TablePostProps extends TableBaseProps {
  userId: string;
}

function TableCerita({ keyword, userId }: TablePostProps) {
  const [user, total, getList, deletePost] = usePostStore((s) => [
    s.user,
    s.total,
    s.getByUser,
    s.deletePost,
  ]);
  const isLoaded = useLoaded();

  const list = user[userId];

  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const debouncedKeyword = useDebounce(keyword, 500);

  const [selectedId, setSelectedId] = useState<any>(null);
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    if (debouncedKeyword !== undefined && isLoaded) {
      const newPage = 1;
      setPage(newPage);

      const query = debouncedKeyword || undefined;
      getList({ page: newPage, limit, query, id_user: Number(userId) });
    }
  }, [debouncedKeyword, isLoaded]);

  const handleChangePage = (page: number, pageSize: number) => {
    setPage(page);
    setLimit(limit);

    const query = debouncedKeyword || undefined;
    getList({ page, limit: pageSize, query, id_user: Number(userId) });
  };

  const handleShowDelete = (id: number) => {
    setSelectedId(id);
    setVisible(true);
  };

  const handleDelete = async () => {
    const res = await deletePost(selectedId);

    if (res) {
      setVisible(false);
      setSelectedId(null);

      notifySuccess({
        message: "Berhasil menghapus inspirasi desa",
      });

      const query = debouncedKeyword || undefined;
      getList({ page, limit, query, id_user: Number(userId) });
    }
  };

  const columns: TableColumnsType = [
    {
      title: "Nama",
      key: "name",
      dataIndex: "user",
      width: "25%",
      render: ({ photo: avatar }, { content }: any) => (
        <Flex alignItems="center" w="100%" gap="12px">
          <Avatar src={avatar} size={42} shape="square" />
          <Flex
            css={{
              display: "-webkit-box",
              "-webkit-line-clamp": 2,
              "-webkit-box-orient": "vertical",
              overflow: "hidden",
              flex: 1,
            }}
          >
            <Text>{content}</Text>
          </Flex>
        </Flex>
      ),
    },
    {
      title: "Dilihat",
      dataIndex: "_count",
      key: "seen",
      render: (count) => count.post_view,
    },
    {
      title: "Suka",
      dataIndex: "_count",
      key: "like",
      render: (count) => count.post_like,
    },
    {
      title: "Komentar",
      dataIndex: "_count",
      key: "comment",
      render: (count) => count.post_comment,
    },
    {
      title: "Tanggal Buat",
      dataIndex: "created_at",
      key: "createdDate",
      render: (date: string) => format(new Date(date), "dd/MM/yyyy"),
    },
    {
      title: "",
      key: "action",
      render: (_, { id }: any) => {
        return (
          <Flex alignItems="center" gap="12px">
            <Link to={`/users/detail/${userId}/cerita-desa/${id}`}>
              <Button icon={<HiEye size={16} />} />
            </Link>
            <Button
              onClick={() => handleShowDelete(id)}
              icon={<HiTrash size={16} />}
            />
          </Flex>
        );
      },
    },
  ];

  return (
    <>
      <TableList
        dataSource={list}
        columns={columns}
        total={total}
        page={page}
        limit={limit}
        onChangePage={handleChangePage}
      />
      <ModalDelete
        visible={visible}
        onCancel={() => setVisible(false)}
        onDelete={handleDelete}
      />
    </>
  );
}

export default TableCerita;
