import { message, Divider } from "antd";
import { Card, Button, Grid, Flex } from "components/Base";
import { Input, Switch } from "components/Form";
import ModalDeactivate from "global/Modals/ModalDeactivate";
import useInput from "hooks/useInput";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

interface FormCategoryProps {
  initialValues?: any;
  onSubmit: (values: any) => void;
}

function FormCategory({ initialValues = null, onSubmit }: FormCategoryProps) {
  const navigate = useNavigate();

  const [name, setName] = useInput("");
  const [active, setActive] = useState(true);

  const [showConfirm, setShowConfirm] = useState(false);

  useEffect(() => {
    if (initialValues) {
      setName({ target: { value: initialValues.name } });
      setActive(initialValues.active);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialValues]);

  const handleFinish = () => {
    const data = {
      name,
      active,
    };

    setShowConfirm(false);

    onSubmit(data);
  };

  const handleSubmit = (e: any) => {
    e?.preventDefault();

    if (!name) {
      message.error("Nama harus diisi");
      return;
    }

    if (initialValues && !active) {
      setShowConfirm(true);
      return;
    }

    handleFinish();
  };

  const handleCancel = () => {
    navigate("/master-data/category");
  };

  return (
    <>
      <Card br="$rounded-lg" boxShadow="$shadow-sm">
        <form onSubmit={handleSubmit}>
          <Grid columns="repeat(2, 1fr)" gap="12px 24px" p="16px">
            <Input
              label="Nama Kategori"
              required
              value={name}
              onChange={setName}
            />
            <Switch
              checked={active}
              onChange={setActive}
              label="Status"
              content="Aktif"
              required
            />
          </Grid>
          <Divider />
          <Flex align="center" justify="flex-end" gap="16px" p="8px">
            <Button htmlType="button" onClick={handleCancel}>
              Batalkan
            </Button>
            <Button htmlType="submit" type="primary">
              Simpan
            </Button>
          </Flex>
        </form>
      </Card>
      <ModalDeactivate
        visible={showConfirm}
        onConfirm={handleFinish}
        onCancel={() => setShowConfirm(false)}
      />
    </>
  );
}

export default FormCategory;
