import { Checkbox, Divider } from "antd";
import { Card, Flex, Grid, Text } from "components/Base";
import { Input, Textarea, Upload } from "components/Form";
import { BaseFormProps } from "components/Form/types";
import ButtonSubmit from "global/Form/ButtonSubmit";
import useInput from "hooks/useInput";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { handleUploadImage } from "utils/image";
import { convertToEventTarget } from "utils/input";

const CheckboxGroup = Checkbox.Group;

const options = [
  { label: "Mobile", value: "mobile" },
  { label: "Web", value: "web" },
];

function FormNotif({ initialValues, onSubmit }: BaseFormProps) {
  const navigate = useNavigate();

  const [title, setTitle] = useInput("");
  const [description, setDescription] = useInput("");

  const [checkedList, setCheckedList] = useState<Array<any>>([]);
  const [indeterminate, setIndeterminate] = useState(false);
  const [checkAll, setCheckAll] = useState(false);

  const [image, setImage] = useState<any>("");

  useEffect(() => {
    if (initialValues) {
      setTitle(convertToEventTarget(initialValues.title));
      setDescription(convertToEventTarget(initialValues.description));

      if (initialValues.platform === "all") {
        const list = options.map((item) => item.value);
        setCheckedList(list);
      } else {
        setCheckedList([initialValues.platform]);
      }

      setIndeterminate(initialValues.platform !== "all");
      setCheckAll(initialValues.platform === "all");

      setCheckedList(initialValues.blast);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialValues]);

  const handleChangeImage = (img: any) => {
    setImage(img.file);
  };

  const onChange = (list: any) => {
    setCheckedList(list);
    setIndeterminate(!!list.length && list.length < options.length);
    setCheckAll(list.length === options.length);
  };

  const onCheckAllChange = (e: any) => {
    // @ts-ignore
    const list = e.target.checked ? options.map((item) => item.value) : [];
    // @ts-ignore
    setCheckedList(list);

    setIndeterminate(false);
    setCheckAll(e.target.checked);
  };

  const handleSubmit = async (e: any) => {
    e?.preventDefault();

    const media = await handleUploadImage(image, "notification");

    const data = {
      title,
      description,
      platform: checkAll ? "all" : checkedList[0],
      media,
    };

    onSubmit(data);
  };

  const handleCancel = () => {
    navigate("/notification");
  };

  return (
    <Card>
      <form onSubmit={handleSubmit}>
        <Flex direction="column" gap="24px">
          <Grid columns="repeat(2, 1fr)" gap="24px" w="100%">
            <Input label="Judul" value={title} onChange={setTitle} required />
            <Upload
              label="Icon"
              helper="Dimensi yang direkomendasikan 500x331 dengan format JPG, PNG"
              accept="image/jpg, image/png, image/jpeg"
              value={image}
              onChange={handleChangeImage}
            />
          </Grid>
          <Text fontSize="14px" mb="-4px">
            Blast{" "}
            <Text as="span" color="$red400">
              *
            </Text>
          </Text>
          <Flex
            align="baseline"
            gap="24px"
            css={{
              "& .ant-checkbox-group": {
                gap: "24px",
                display: "inline-flex",
              },
            }}
            color="$coolGray700"
          >
            <Checkbox
              indeterminate={indeterminate}
              onChange={onCheckAllChange}
              checked={checkAll}
            >
              Semua
            </Checkbox>
            <CheckboxGroup
              options={options}
              value={checkedList}
              onChange={onChange}
            />
          </Flex>
          <Textarea
            label="Deskripsi"
            value={description}
            onChange={setDescription}
            required
          />
        </Flex>
        <Divider />
        <ButtonSubmit onCancel={handleCancel} />
      </form>
    </Card>
  );
}

export default FormNotif;
