import { TableColumnsType } from "antd";
import { Flex, Text } from "components/Base";
import { format } from "date-fns";
import ModalDelete from "global/Modals/ModalDelete";
import TableList from "global/TableList";
import ButtonAction from "global/TableList/ButtonAction";
import { TableBaseProps } from "global/TableList/types";
import useDebounce from "hooks/useDebounce";
import useLoaded from "hooks/useLoaded";
import { useEffect, useState } from "react";
import useReportStore from "../reports/reportStore";

function TableReports({ keyword }: TableBaseProps) {
  const [list, total, getList, deleteReport] = useReportStore((s) => [
    s.list,
    s.total,
    s.getList,
    s.delete,
  ]);

  const loaded = useLoaded();

  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const debouncedKeyword = useDebounce(keyword, 500);

  const [selectedId, setSelectedId] = useState<any>(null);
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    if (debouncedKeyword !== undefined && loaded) {
      const newPage = 1;
      setPage(newPage);

      const query = debouncedKeyword || undefined;
      getList({ page: newPage, limit, query });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedKeyword, loaded]);

  const handleChangePage = (page: number, limit: number) => {
    setPage(page);
    setLimit(limit);

    const query = debouncedKeyword || undefined;
    getList({ page, limit, query });
  };

  const handleShowDelete = (id: number) => {
    setSelectedId(id);
    setVisible(true);
  };

  const handleDelete = async () => {
    const del = await deleteReport(selectedId);

    if (del) {
      setVisible(false);
      setSelectedId(0);

      const query = debouncedKeyword || undefined;
      getList({ page, limit, query });
    }
  };

  const columns: TableColumnsType<TableBaseProps> = [
    {
      title: "Nama",
      dataIndex: "user",
      key: "name",
      render: (user) => user.name,
    },
    {
      title: "Judul",
      dataIndex: "title",
      key: "title",
      width: "25%",
      render: (title) => (
        <Flex
          css={{
            display: "-webkit-box",
            "-webkit-line-clamp": 2,
            "-webkit-box-orient": "vertical",
            overflow: "hidden",
          }}
        >
          <Text>{title}</Text>
        </Flex>
      ),
    },
    {
      title: "Deskripsi",
      dataIndex: "content",
      key: "description",
      width: "20%",
      render: (description) => (
        <Flex
          css={{
            display: "-webkit-box",
            "-webkit-line-clamp": 2,
            "-webkit-box-orient": "vertical",
            overflow: "hidden",
          }}
        >
          <Text>{description}</Text>
        </Flex>
      ),
    },
    {
      title: "Waktu",
      dataIndex: "created_at",
      key: "created_at",
      render: (time) => format(new Date(time), "HH:mm"),
    },
    {
      title: "Tanggal Buat",
      dataIndex: "created_at",
      key: "created_date",
      render: (time) => format(new Date(time), "dd/MM/yyyy"),
    },
    {
      title: "",
      key: "action",
      render: (_, { id }: any) => (
        <ButtonAction
          id={id}
          edit={false}
          onDelete={() => handleShowDelete(id)}
          url="/report-7d"
        />
      ),
    },
  ];

  return (
    <>
      <TableList
        columns={columns}
        dataSource={list}
        total={total}
        page={page}
        limit={limit}
        onChangePage={handleChangePage}
        bordered
      />
      <ModalDelete
        visible={visible}
        onDelete={handleDelete}
        onCancel={() => setVisible(false)}
      />
    </>
  );
}

export default TableReports;
