import UserManagement from "features/user-management";
import MainLayout from "global/MainLayout";

function UserManagementPage() {
  return (
    <MainLayout>
      <UserManagement />
    </MainLayout>
  );
}

export default UserManagementPage;
