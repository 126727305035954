import { Button, Card, Flex, Text } from "components/Base";
import useLeaderboardStore from "features/leaderboard/leaderboardStore";
import If from "global/ErrorHandlers/If";
import InputSearch from "global/InputSearch";
import PageTitle from "global/PageTitle";
import { useEffect } from "react";
import { BiChevronRight } from "react-icons/bi";
import { Link } from "react-router-dom";
import { getColor } from "utils/general";
import LeaderCard from "./LeaderCard";
import LeaderList from "./LeaderList";

function LeaderboardList() {
  const [list, total, getList] = useLeaderboardStore((s) => [
    s.list,
    s.total,
    s.getLeaderboard,
  ]);

  useEffect(() => {
    getList();
  }, []);

  if (list.length === 0) return null;

  return (
    <>
      <PageTitle title="Leaderboard Point" />
      <Flex justify="flex-end" align="center" mb="40px">
        <Button style={{ cursor: "default" }}>Informasi Poin</Button>
      </Flex>
      <If condition={list.length > 0}>
        <Flex align="flex-start" gap="44px">
          <Flex direction="column" gap="24px" w="192px">
            <LeaderCard rank="gold" point={list?.[0]?.point} user={list?.[0]} />
            <LeaderCard
              rank="silver"
              point={list?.[1]?.point}
              user={list?.[1]}
            />
            <LeaderCard
              rank="bronze"
              point={list?.[2]?.point}
              user={list?.[2]}
            />
          </Flex>
          <Flex direction="column" gap="24px" css={{ flex: 1 }}>
            <If condition={list.length > 3}>
              {list?.slice(3, 9).map((user, idx) => (
                <LeaderList
                  rank={idx + 4}
                  point={user.point}
                  name={user.name}
                />
              ))}
            </If>
            <Card w="100%" p="0px">
              <Link to="/leaderboard">
                <Flex
                  justify="space-between"
                  h="72px"
                  align="center"
                  p="20px 40px 20px 20px"
                >
                  <Text fontSize="$xl" weight="$semibold" color="$primary600">
                    See all
                  </Text>
                  <BiChevronRight
                    size={24}
                    style={{ color: getColor("primary600") }}
                  />
                </Flex>
              </Link>
            </Card>
          </Flex>
        </Flex>
      </If>
    </>
  );
}

export default LeaderboardList;
