import { Avatar, TableColumnsType } from "antd";
import { Flex, Text } from "components/Base";
import ModalDelete from "global/Modals/ModalDelete";
import TableList from "global/TableList";
import ButtonAction from "global/TableList/ButtonAction";
import { TableBaseProps } from "global/TableList/types";
import useDebounce from "hooks/useDebounce";
import useLoaded from "hooks/useLoaded";
import { useEffect, useState } from "react";
import { notifySuccess } from "utils/notification";
import useUserStore from "../stores/userStore";

function TableUser({ keyword }: TableBaseProps) {
  const [data, total, getList, deleteUser] = useUserStore((s) => [
    s.list,
    s.total,
    s.getList,
    s.delete,
  ]);
  const isLoaded = useLoaded();

  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const debouncedKeyword = useDebounce(keyword, 500);

  const [selectedId, setSelectedId] = useState<any>(null);
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    if (debouncedKeyword !== undefined && isLoaded) {
      const newPage = 1;
      setPage(newPage);

      const query = debouncedKeyword || undefined;
      getList({ page: newPage, limit, query });
    }
  }, [debouncedKeyword, isLoaded]);

  const handleChangePage = (page: number, pageSize: number) => {
    setPage(page);
    setLimit(pageSize);

    const query = debouncedKeyword || undefined;
    getList({ page, limit: pageSize, query });
  };

  const handleShowDelete = (id: number) => {
    setSelectedId(id);
    setVisible(true);
  };

  const handleDelete = async () => {
    const del = await deleteUser(selectedId);

    if (del) {
      setVisible(false);
      setSelectedId(0);

      notifySuccess({
        message: "Berhasil menghapus user",
      });

      const query = debouncedKeyword || undefined;
      getList({ page, limit, query });
    }
  };

  const columns: TableColumnsType<TableBaseProps> = [
    {
      title: "Nama",
      key: "name",
      width: "25%",
      render: (_: any, { name, photo: avatar }: any) => (
        <Flex alignItems="center" w="100%" gap="12px">
          <Avatar src={avatar} size={42} />
          <Text css={{ flex: "1" }}>{name}</Text>
        </Flex>
      ),
    },
    {
      title: "Level",
      dataIndex: ["level", "name"],
      key: "level",
      render: (level) => level,
    },
    {
      title: "Jumlah Poin",
      dataIndex: "total_point",
      key: "point",
    },
    {
      title: "Jumlah Story",
      dataIndex: "total_story",
      key: "story",
    },
    {
      title: "Jumlah Inspirasi Desa",
      dataIndex: "total_post",
      key: "report",
    },
    {
      title: "",
      key: "action",
      render: (_: any, { id }: any) => (
        <ButtonAction
          id={id}
          url="/users"
          edit={false}
          onDelete={() => handleShowDelete(id)}
        />
      ),
    },
  ];

  return (
    <>
      <TableList
        dataSource={data}
        columns={columns}
        total={total}
        page={page}
        limit={limit}
        onChangePage={handleChangePage}
      />
      <ModalDelete
        visible={visible}
        onCancel={() => setVisible(false)}
        onDelete={handleDelete}
      />
    </>
  );
}

export default TableUser;
