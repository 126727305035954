import { Box, Text, Flex, Icon } from "components/Base";
import Item from "./Item";

interface FeatureItem {
  id: string | number;
  name: string;
}

interface ItemGroupProps {
  label: string;
  icon?: string;
  features: Array<FeatureItem>;
  values?: any;
  onChangeItem: (featId: string | number, role: any) => void;
}

function ItemGroup({
  label,
  icon,
  features,
  onChangeItem,
  values,
}: ItemGroupProps) {
  return (
    <Box mb="32px">
      <Flex align="center" mb="12px">
        {icon && <Icon alt="label" src={icon} mr="16px" color="$primary" />}
        <Text as="h2" weight="$semibold" color="$primary">
          {label}
        </Text>
      </Flex>
      <Box>
        {features.map(({ name: featureName, id }) => {
          const checkedList = values?.[id]?.access || [];

          return (
            <Item
              key={`${label}-feature-${id}`}
              label={featureName}
              featId={id}
              initialValues={checkedList}
              onChange={onChangeItem}
            />
          );
        })}
      </Box>
    </Box>
  );
}

export default ItemGroup;
