import { Card, Grid, Tag } from "components/Base";
import { InfoItem } from "components/Info";
import PageTitle from "global/PageTitle";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import useCategoryStore from "./categoryStore";

function DetailMasterCategory() {
  const [category, getDetail] = useCategoryStore((s) => [
    s.detail,
    s.getDetail,
  ]);
  const { id } = useParams();

  useEffect(() => {
    if (id) {
      getDetail(Number(id));
    }
  }, [id]);

  if (!id || !category[id]) {
    return null;
  }

  const detail = category[id];

  return (
    <>
      <PageTitle title="Detail" backUrl="/master-data/category" />
      <Card p="32px">
        <Grid columns="repeat(2, 1fr)" gap="24px">
          <InfoItem label="Nama Kategori" value={detail.name} />
          <InfoItem label="Status">
            <Tag rounded color={detail.active ? "green" : "red"}>
              {detail.active ? "Aktif" : "Tidak aktif"}
            </Tag>
          </InfoItem>
        </Grid>
      </Card>
    </>
  );
}

export default DetailMasterCategory;
