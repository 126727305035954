import { FILE_TYPE_IMAGES } from "constants/app";
import { Card, Flex, Image, Text } from "components/Base";
import { format, formatDistanceToNow } from "date-fns";
import If from "global/ErrorHandlers/If";
import PageTitle from "global/PageTitle";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { getMediaExtension } from "utils/media";
import useReportStore from "./reportStore";

function DetailReport() {
  const [report, getDetail] = useReportStore((s) => [s.detail, s.getDetail]);
  const { id } = useParams();

  useEffect(() => {
    if (id) {
      getDetail(Number(id));
    }
  }, [id]);

  if (!id || !report[id]) {
    return null;
  }

  const detail = report[id];

  const images = detail.media.filter((img) =>
    FILE_TYPE_IMAGES.includes(getMediaExtension(img.media)),
  );

  return (
    <>
      <PageTitle title="Detail" backUrl="/report-7d" />
      <Card p="32px">
        <Text
          as="h2"
          fontSize="18px"
          lineHeight="32px"
          mb="12px"
          weight="$bold"
        >
          {detail.title}
        </Text>
        <Flex align="center" gap="16px" mb="48px">
          <Text size="$sm">{detail.user.name}</Text>・
          <If condition={!!detail.location}>
            <Text size="$sm">{detail.location}</Text>・
          </If>
          <Text size="$sm">{format(new Date(), "d MMMM yyyy")}</Text>・
          <Text size="$sm">
            {formatDistanceToNow(new Date(detail.created_at))}
          </Text>
        </Flex>
        <Text lineHeight="1.5">{detail.content}</Text>
        <If condition={images.length > 0}>
          <Flex gap="12px" wrap="wrap" mt="20px">
            {images.map((img) => (
              <Image
                src={img.media}
                alt="Lampiran"
                h="260px"
                br="$rounded-sm"
              />
            ))}
          </Flex>
        </If>
      </Card>
    </>
  );
}

export default DetailReport;
