import SetPassword from "features/set-password";
import PublicLayout from "global/PublicLayout";

function SetPasswordPage() {
  return (
    <PublicLayout>
      <SetPassword />
    </PublicLayout>
  );
}

export default SetPasswordPage;
