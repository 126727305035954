import { Avatar, TableColumnsType } from "antd";
import { Button, Flex, Text } from "components/Base";
import { format, set } from "date-fns";
import ModalApprove from "features/report-management/components/modals/ModalApprove";
import ModalReject from "features/report-management/components/modals/ModalReject";
import TableList from "global/TableList";
import ButtonAction from "global/TableList/ButtonAction";
import { useState } from "react";
import { MdClose, MdCheck } from "react-icons/md";
import { IReportPostItem } from "types/responses/reports";
import useApproveRejectReport from "../useApproveRejectReport";
import useReportList from "../useReportList";

interface TableReportListProps {
  keyword?: string;
}

function TableReportList({ keyword }: TableReportListProps) {
  const {
    data: reports,
    total,
    page,
    limit,
    isLoading,
    onChangePage,
    refetch,
  } = useReportList({ keyword });
  const { approveReport, rejectReport } = useApproveRejectReport();

  const [selectedReport, setSelectedReport] = useState<IReportPostItem>(
    {} as IReportPostItem,
  );
  const [showApprove, setShowApprove] = useState(false);
  const [showReject, setShowReject] = useState(false);

  const handleOpenAction = (
    report: IReportPostItem,
    type: "approve" | "reject",
  ) => {
    setSelectedReport(report);

    if (type === "approve") {
      setShowApprove(true);
    } else {
      setShowReject(true);
    }
  };

  const handleCloseAction = () => {
    setShowApprove(false);
    setShowReject(false);
    setSelectedReport({} as IReportPostItem);
  };

  const handleApprove = () => {
    approveReport.mutate(selectedReport.id, {
      onSuccess: () => {
        refetch();
        handleCloseAction();
      },
    });
  };

  const handleReject = () => {
    rejectReport.mutate(selectedReport.id, {
      onSuccess: () => {
        refetch();
        handleCloseAction();
      },
    });
  };

  const columns: TableColumnsType<TableReportListProps> = [
    {
      title: "Nama",
      dataIndex: "post",
      key: "title",
      width: "25%",
      render: ({ photo: avatar, content }) => (
        <Flex alignItems="center" w="100%" gap="12px">
          <Avatar src={avatar} size={42} shape="square" />
          <Flex
            css={{
              display: "-webkit-box",
              "-webkit-line-clamp": 2,
              "-webkit-box-orient": "vertical",
              overflow: "hidden",
              flex: 1,
            }}
          >
            <Text>{content}</Text>
          </Flex>
        </Flex>
      ),
    },
    {
      title: "Dilihat",
      dataIndex: ["post", "_count", "post_view"],
      key: "seen",
    },
    {
      title: "Disukai",
      dataIndex: ["post", "_count", "post_like"],
      key: "liked",
    },
    {
      title: "Tanggal",
      dataIndex: "created_at",
      key: "date",
      render: (date: string) => format(new Date(date), "dd/MM/yyyy"),
    },
    {
      title: "Jam",
      dataIndex: "created_at",
      key: "jam",
      render: (date: string) => format(new Date(date), "HH:mm"),
    },
    {
      title: "Reporter",
      dataIndex: ["user", "name"],
      key: "reporter",
    },
    {
      title: "Reason",
      dataIndex: "reason",
      key: "reason",
      width: "200px",
      render: (reason: string) => {
        if (!reason) return null;

        return <Text color="$red500">{reason}</Text>;
      },
    },
    {
      title: "",
      dataIndex: "id",
      key: "action",
      render: (id, report) => (
        <Flex alignItems="center" gap="12px">
          <ButtonAction
            url="/report-post"
            view={true}
            edit={false}
            delete={false}
            id={id}
          />
          <Button
            color="primary"
            icon={<MdCheck />}
            // @ts-ignore
            onClick={() => handleOpenAction(report, "approve")}
          />
          <Button
            color="danger"
            icon={<MdClose />}
            // @ts-ignore
            onClick={() => handleOpenAction(report, "reject")}
          />
        </Flex>
      ),
    },
  ];

  return (
    <>
      <TableList
        columns={columns}
        dataSource={reports}
        page={page}
        limit={limit}
        total={total}
        loading={isLoading}
        onChangePage={onChangePage}
      />
      <ModalApprove
        name={selectedReport?.user?.name}
        reportType="post"
        open={showApprove}
        onOk={handleApprove}
        onCancel={handleCloseAction}
      />
      <ModalReject
        reportType="post"
        open={showReject}
        onOk={handleReject}
        onCancel={handleCloseAction}
      />
    </>
  );
}

export default TableReportList;
