import Step7D from "features/7d/steps";
import MainLayout from "global/MainLayout";

function Step7DPage() {
  return (
    <MainLayout>
      <Step7D />
    </MainLayout>
  );
}

export default Step7DPage;
