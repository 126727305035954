import EditUser from "features/user-management/edit-user";
import MainLayout from "global/MainLayout";

function EditUserPage() {
  return (
    <MainLayout>
      <EditUser />
    </MainLayout>
  );
}

export default EditUserPage;
