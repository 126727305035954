import { Card, Grid, Tag } from "components/Base";
import { InfoItem } from "components/Info";
import PageTitle from "global/PageTitle";
// import adminStore from "lib/stores/slices/admin";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import adminStore from "./adminStore";

function DetailUser() {
  const detail = adminStore((state) => state.detail);
  const getDetail = adminStore((state) => state.getDetail);

  const { id } = useParams();

  useEffect(() => {
    if (id) {
      getDetail(Number(id));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  if (!id) {
    return null;
  }

  const user = detail[id];

  if (!user) {
    return <div />;
  }

  return (
    <>
      <PageTitle backUrl="/user-management" title="Detail" />
      <Card br="$rounded-lg" p="32px" boxShadow="$shadow-sm">
        <Grid columns="repeat(2, 1fr)" gap="24px">
          <InfoItem label="Nama lengkap" value={user.name} />
          <InfoItem label="Nomor handphone" value={user.phone} />
          <InfoItem label="Email" value={user.email} />
          <InfoItem label="Status">
            <Tag rounded color={user.active ? "green" : "red"}>
              {user.active ? "Aktif" : "Tidak aktif"}
            </Tag>
          </InfoItem>
          <InfoItem label="Role">
            <Tag rounded color="purple">
              {user.role.name}
            </Tag>
          </InfoItem>
        </Grid>
      </Card>
    </>
  );
}

export default DetailUser;
