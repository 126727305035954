import AboutConfig from "features/about";
import MainLayout from "global/MainLayout";

function AboutConfigPage() {
  return (
    <MainLayout>
      <AboutConfig />
    </MainLayout>
  );
}

export default AboutConfigPage;
