import DetailRole from "features/role-management/detail-role";
import MainLayout from "global/MainLayout";

function DetailRolePage() {
  return (
    <MainLayout>
      <DetailRole />
    </MainLayout>
  );
}

export default DetailRolePage;
