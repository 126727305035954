import { Avatar } from "antd";
import { Flex, Text } from "components/Base";

interface LeaderCardProps {
  rank: "gold" | "silver" | "bronze";
  user: {
    name: string;
    photo: string;
    [key: string]: any;
  };
  point: number;
}

function LeaderCard({ rank, user, point }: LeaderCardProps) {
  const bgColor = {
    gold: "linear-gradient(135deg, #EAB308 0%, #FDE68A 100%)",
    silver: "linear-gradient(135deg, #9CA3AF 0%, #F0F9FF 100%)",
    bronze: "linear-gradient(135deg, #B45309 0%, #FCD34D 100%)",
  };
  const rankNumber = rank === "gold" ? 1 : rank === "silver" ? 2 : 3;

  if (!user || !point) return null;

  return (
    <Flex
      p="20px"
      br="$rounded-2xl"
      direction="column"
      css={{ background: bgColor[rank] }}
      w="100%"
      gap="10px"
      align="center"
    >
      <Flex align="flex-start">
        <Flex
          w="32px"
          h="32px"
          align="center"
          justify="center"
          br="$rounded-full"
          css={{
            background: "rgba(255, 255, 255, 0.2)",
          }}
        >
          {rankNumber}
        </Flex>
        <Avatar size={80} src={user.photo} />
      </Flex>
      <Text weight="$medium" lineHeight="1.5">
        {user.name}
      </Text>
      <Text weight="$semibold" fontSize="$2xl" lineHeight="1.5">
        {point} Point
      </Text>
    </Flex>
  );
}

export default LeaderCard;
