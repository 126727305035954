import { Card, Flex, Grid, Image, Tag, Text } from "components/Base";
import { InfoItem } from "components/Info";
import PageTitle from "global/PageTitle";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import useStepStore from "./stepStore";

function DetailStep7d() {
  const { id } = useParams();
  const [step, getStepDetail] = useStepStore((s) => [s.detail, s.getDetail]);

  useEffect(() => {
    if (id) {
      getStepDetail(Number(id));
    }
  }, [id]);

  if (!id || !step[id]) {
    return null;
  }

  const detail = step[id];

  return (
    <>
      <PageTitle title="Detail" backUrl="/step-7d" />
      <Card p="32px">
        <Grid columns="repeat(2, 1fr)" gap="24px" mb="48px">
          <Flex align="center" gap="16px" w="100%">
            <Image
              src={detail.image}
              alt={detail.title}
              autofit
              br="$rounded-lg"
              size="72px"
            />
            <Text fontSize="18px" weight="$medium">
              {detail.title}
            </Text>
          </Flex>
          <InfoItem label="Status">
            <Tag rounded color={detail.active ? "green" : "red"}>
              {detail.active ? "Aktif" : "Tidak aktif"}
            </Tag>
          </InfoItem>
        </Grid>
        <Text lineHeight="2">{detail.content}</Text>
      </Card>
    </>
  );
}

export default DetailStep7d;
