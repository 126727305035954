import Notification from "features/notification";
import MainLayout from "global/MainLayout";

function NotificationPage() {
  return (
    <MainLayout>
      <Notification />
    </MainLayout>
  );
}

export default NotificationPage;
