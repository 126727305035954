import { ModalProps } from "antd";
import { Text } from "components/Base";
import { Modal } from "components/Feedback";

interface ModalApproveProps extends ModalProps {
  name: string;
  reportType: "post" | "story";
}

function ModalApprove({ name, reportType, ...props }: ModalApproveProps) {
  const wording = reportType === "post" ? "Cerita Desa" : "Story";

  return (
    <Modal
      title="Konfirmasi Setuju"
      okText="Ya, Hapus"
      cancelText="Batalkan"
      {...props}
    >
      <Text>
        Apakah Kamu yakin ingin menghapus {wording} ini karena terbukti
        melanggar aturan SIGAP? Jika kamu setuju kami akan memberikan
        pemberitahuan tentang report ini kepada <strong>{name}</strong>.
      </Text>
    </Modal>
  );
}

export default ModalApprove;
