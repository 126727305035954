import { Avatar, Switch, message } from "antd";
import { Button, Card, Flex, Grid, Image, Text } from "components/Base";
import { InfoItem } from "components/Info";
import PageTitle from "global/PageTitle";
import users from "lib/api/users";
import { useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { notifySuccess } from "utils/notification";
import useUserStore from "./stores/userStore";

function DetailUser() {
  const { id } = useParams();
  const [user, getDetail] = useUserStore((s) => [s.detail, s.getDetail]);
  const [loading, toggleVerify] = useUserStore((s) => [
    s.loading,
    s.toggleVerify,
  ]);

  useEffect(() => {
    if (id) {
      getDetail(Number(id));
    }
  }, [id]);

  const resetPassword = async () => {
    if (!id || !user[id]) {
      return;
    }

    const res = await users.resetPassword(detail.id);

    if (!res.data) {
      message.error("Gagal meminta perubahan password");
      return;
    }

    const { error, message: msg } = res.data;

    if (error) {
      message.error(msg);
      return;
    }

    notifySuccess({
      message: "Berhasil meminta perubahan password",
      description: "Silakan cek email untuk mereset password",
    });
  };

  const handleToggleVerify = () => {
    if (!id) {
      return;
    }

    toggleVerify(Number(id), !user[id].verified);
  };

  if (!id || !user[id]) {
    return null;
  }

  const detail = user[id];

  return (
    <>
      <PageTitle title="Detail" backUrl="/users" />
      <Card p="32px">
        <Flex position="relative" direction="column" mb="52px">
          <Image
            w="100%"
            h="160px"
            src={detail.cover}
            autofit
            css={{ br: "8px 8px 0 0" }}
          />
          <Avatar
            size={160}
            src={detail.photo}
            style={{ marginLeft: 50, marginTop: -80 }}
          />
        </Flex>
        <Grid columns="repeat(2, 1fr)" gap="24px">
          <InfoItem label="Nama Lengkap" value={detail.name} />
          <InfoItem label="Level" value={detail.level.name} />

          <InfoItem label="Email" value={detail.email} />
          <InfoItem label="Jumlah Inspirasi Desa">
            <Flex align="center" gap="16px">
              <Text fontSize="$sm">{detail.total_post}</Text>
              <Link to={`/users/detail/${id}/cerita-desa`}>
                <Text fontSize="$sm" color={"$cyan500"}>
                  Lihat Inspirasi Desa
                </Text>
              </Link>
            </Flex>
          </InfoItem>

          <InfoItem label="Nomor Handphone" value={detail.phone} />
          <InfoItem label="Jumlah Story">
            <Flex align="center" gap="16px">
              <Text fontSize="$sm">{detail.total_story}</Text>
              <Link to={`/users/detail/${id}/story`}>
                <Text fontSize="$sm" color={"$cyan500"}>
                  Lihat Story
                </Text>
              </Link>
            </Flex>
          </InfoItem>

          <InfoItem label="Deskripsi" value={detail.bio} />
          <InfoItem label="Jumlah Poin">
            <Flex align="center" gap="16px">
              <Text fontSize="$sm">{detail.total_point}</Text>
              <Link to={`/users/detail/${id}/point-history`}>
                <Text fontSize="$sm" color={"$cyan500"}>
                  Lihat History Perolehan Poin
                </Text>
              </Link>
            </Flex>
          </InfoItem>

          <InfoItem label="Klik Tombol dibawah ini untuk membantu user mengatur ulang kata sandi ">
            <Button color="secondary" onClick={resetPassword}>
              Atur Ulang Kata Sandi
            </Button>
          </InfoItem>

          <InfoItem label="Verified Admin SIGAP">
            <Flex align="center" gap="16px">
              <Switch
                checked={user[id].verified}
                title="Aktif"
                loading={loading}
                onChange={handleToggleVerify}
              />
              <Text fontSize="$sm">
                {user[id].verified ? "Aktif" : "Non Aktif"}
              </Text>
            </Flex>
          </InfoItem>
        </Grid>
      </Card>
    </>
  );
}

export default DetailUser;
