import { produce } from "immer";
import adminApi from "lib/api/admin";
import { StoreSlice } from "lib/stores/types";
import { GetListParams } from "types/api";
import createStore from "zustand";

interface AdminSlice {
  list: Array<Record<string, any>>;
  detail: Record<string, any>;
  total: number;
  getList: ({ page, limit, query }: GetListParams) => void;
  getDetail: (id: number) => void;
  deleteAdmin: (id: number) => Promise<boolean>;
}

const adminSlice: StoreSlice<AdminSlice> = (set, get) => ({
  list: [],
  detail: {},
  total: 0,
  getList: async ({ page = 1, limit = 10, query = undefined }) => {
    const res = await adminApi.getList({ page, limit, query });

    if (!res.data) {
      return;
    }

    const { data, error } = res.data;

    if (error) {
      return;
    }

    set({ list: data.data, total: data.total });
  },
  getDetail: async (id: number) => {
    const res = await adminApi.getDetail(id);

    if (!res.data) {
      return;
    }

    const { data, error } = res.data;

    if (error) {
      return;
    }

    return set(
      produce((state) => {
        state.detail[id] = data;
      }),
    );
  },
  deleteAdmin: async (id: number) => {
    const res = await adminApi.deleteAdmin(id);

    if (!res.data) {
      return false;
    }

    const { error } = res.data;

    if (error) {
      return false;
    }

    return true;
  },
});

const adminStore = createStore(adminSlice);

export default adminStore;
