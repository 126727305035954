import { Card } from "components/Base";
import ButtonAdd from "global/ButtonAdd";
import InputSearch from "global/InputSearch";
import PageTitle from "global/PageTitle";
import useInput from "hooks/useInput";
import TableFAQ from "./components/TableFAQ";

function FAQ() {
  const [query, setQuery] = useInput("");

  return (
    <>
      <PageTitle title="List FAQ">
        <ButtonAdd to="/faq/add" />
      </PageTitle>
      <Card>
        <InputSearch value={query} onChange={setQuery} />
        <TableFAQ keyword={query} />
      </Card>
    </>
  );
}

export default FAQ;
