import AddNotification from "features/notification/add-notification";
import MainLayout from "global/MainLayout";

function AddNotificationPage() {
  return (
    <MainLayout>
      <AddNotification />
    </MainLayout>
  );
}

export default AddNotificationPage;
