import ForgotPassword from "features/forgot-password";
import PublicLayout from "global/PublicLayout";

function ForgotPasswordPage() {
  return (
    <PublicLayout>
      <ForgotPassword />
    </PublicLayout>
  );
}

export default ForgotPasswordPage;
