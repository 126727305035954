import Users from "features/users";
import MainLayout from "global/MainLayout";

function UsersPage() {
  return (
    <MainLayout>
      <Users />
    </MainLayout>
  );
}

export default UsersPage;
