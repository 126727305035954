import { message } from "antd";
import PageTitle from "global/PageTitle";
import notification from "lib/api/notification";
import { useNavigate } from "react-router-dom";
import { notifySuccess } from "utils/notification";
import FormNotif from "./components/FormNotif";

function AddNotification() {
  const navigate = useNavigate();

  const handleSubmit = async (values: any) => {
    const res = await notification.add(values);

    if (!res.data) {
      message.error("Gagal menambahkan notifikasi");
      return;
    }

    const { error } = res.data;

    if (error) {
      message.error(error);
      return;
    }

    notifySuccess({
      message: "Berhasil menambahkan notifikasi",
    });

    // Redirect to list page
    navigate("/notification");
  };
  return (
    <>
      <PageTitle title="Menambahkan Notification" backUrl="/notification" />
      <FormNotif onSubmit={handleSubmit} />
    </>
  );
}

export default AddNotification;
