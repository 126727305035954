import { Avatar, Modal, TableColumnsType } from "antd";
import { Button, Flex, Image, Text } from "components/Base";
import { format } from "date-fns";
import If from "global/ErrorHandlers/If";
import ModalDelete from "global/Modals/ModalDelete";
import TableList from "global/TableList";
import { TableBaseProps } from "global/TableList/types";
import useDebounce from "hooks/useDebounce";
import useLoaded from "hooks/useLoaded";
import { useEffect, useState } from "react";
import { HiEye, HiTrash } from "react-icons/hi";
import { notifySuccess } from "utils/notification";
import useStoryStore from "../stores/storyStore";

interface TableStoryProps extends TableBaseProps {
  userId: string;
}

function TableStory({ keyword, userId }: TableStoryProps) {
  const [user, total, getByUser, deleteStory] = useStoryStore((s) => [
    s.user,
    s.total,
    s.getByUser,
    s.deleteStory,
  ]);
  const isLoaded = useLoaded();

  const list = user[userId];

  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const debouncedKeyword = useDebounce(keyword, 500);

  const [selectedImage, setSelectedImage] = useState<any>(null);
  const [visible, setVisible] = useState(false);

  const [selectedId, setSelectedId] = useState<any>(null);
  const [visibleDelete, setVisibleDelete] = useState(false);

  useEffect(() => {
    if (debouncedKeyword !== undefined && isLoaded) {
      const newPage = 1;
      setPage(newPage);

      const query = debouncedKeyword || undefined;
      getByUser(Number(userId), { page: newPage, limit, query });
    }
  }, [debouncedKeyword, isLoaded]);

  const handleChangePage = (page: number, pageSize: number) => {
    setPage(page);
    setLimit(limit);

    const query = debouncedKeyword || undefined;
    getByUser(Number(userId), { page, limit: pageSize, query });
  };

  const handleShowStory = (image: string) => {
    setSelectedImage(image);
    setVisible(true);
  };

  const handleShowDelete = (id: number) => {
    setSelectedId(id);
    setVisibleDelete(true);
  };

  const handleDelete = async () => {
    const del = await deleteStory(selectedId);

    if (del) {
      setVisibleDelete(false);
      setSelectedId(0);

      notifySuccess({
        message: "Berhasil menghapus story",
      });

      const query = debouncedKeyword || undefined;
      getByUser(Number(userId), { page, limit, query });
    }
  };

  const columns: TableColumnsType = [
    {
      title: "Nama",
      key: "name",
      dataIndex: "user",
      width: "25%",
      render: ({ photo: avatar, name }) => (
        <Flex align="center" w="100%" gap="12">
          <Avatar src={avatar} size={42} />
          <Text css={{ flex: "1" }}>{name}</Text>
        </Flex>
      ),
    },
    {
      title: "Dilihat",
      dataIndex: ["_count", "story_view"],
      key: "seen",
    },
    {
      title: "Disukai",
      dataIndex: ["_count", "story_like"],
      key: "seen",
    },
    {
      title: "Tanggal",
      dataIndex: "created_at",
      key: "date",
      render: (date: string) => format(new Date(date), "dd/MM/yyyy"),
    },
    {
      title: "Jam",
      dataIndex: "created_at",
      key: "hour",
      render: (date: string) => format(new Date(date), "HH:mm"),
    },
    {
      title: "",
      key: "action",
      render: (_, { id, media }: any) => {
        return (
          <Flex alignItems="center" gap="12px">
            <Button
              onClick={() => handleShowStory(media)}
              icon={<HiEye size={16} />}
            />
            <Button
              onClick={() => handleShowDelete(id)}
              icon={<HiTrash size={16} />}
            />
          </Flex>
        );
      },
    },
  ];

  return (
    <>
      <TableList
        dataSource={list}
        columns={columns}
        total={total}
        page={page}
        limit={limit}
        onChangePage={handleChangePage}
      />
      <Modal
        open={visible}
        onCancel={() => setVisible(false)}
        width={405}
        footer={null}
        className="modal-story"
      >
        <If condition={selectedImage}>
          <Image src={selectedImage} w="100%" />
        </If>
      </Modal>
      <ModalDelete
        visible={visibleDelete}
        onCancel={() => setVisibleDelete(false)}
        onDelete={handleDelete}
      />
    </>
  );
}

export default TableStory;
