import { message } from "antd";
import PageTitle from "global/PageTitle";
import category from "lib/api/category";
import { useNavigate } from "react-router-dom";
import { notifySuccess } from "utils/notification";
import FormCategory from "./components/FormCategory";

function AddMasterCategory() {
  const navigate = useNavigate();

  const handleSubmit = async (data: any) => {
    const add = await category.add(data);

    if (!add.data) {
      message.error("Gagal menambahkan kategori");
      return;
    }

    const { error } = add.data;

    if (error) {
      message.error(error);
      return;
    }

    notifySuccess({
      message: "Berhasil menambahkan kategori",
    });

    // Redirect to list page
    navigate("/master-data/category");
  };

  return (
    <>
      <PageTitle title="Menambahkan Kategori" backUrl="/master-data/category" />
      <FormCategory onSubmit={handleSubmit} />
    </>
  );
}

export default AddMasterCategory;
