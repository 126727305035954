import { Avatar } from "antd";
import { Card, Flex, Grid, Image, Text } from "components/Base";
import { InfoItem } from "components/Info";
import { format } from "date-fns";
import If from "global/ErrorHandlers/If";
import { useState } from "react";
import { IPostItem } from "types/responses/post";
import ModalComment from "./ModalComment";
import ModalLike from "./ModalLike";

interface CardDetailCeritaProps {
  detail: IPostItem;
  storyId: number;
}

function CardDetailCerita({ detail, storyId }: CardDetailCeritaProps) {
  const [showLike, setShowLike] = useState(false);
  const [showComment, setShowComment] = useState(false);

  return (
    <>
      <Card p="32px">
        <Flex align="center" gap="16px" mb="28px">
          <Avatar src={detail.user.photo} size={52} />
          <Text fontSize="18px" weight="$semibold">
            {detail.user.name}
          </Text>
        </Flex>
        <Text as="h2" size="$xl" weight="$semibold" mb="12px" height="35px">
          Inspirasi Desa
        </Text>
        <Flex align="center" gap="12px" mb="48px">
          <Text size="$sm">{format(new Date(), "dd/MM/yyyy")}</Text>・
          <Text size="$sm">{detail.location}</Text>
        </Flex>
        <Text lineHeight="24px" align="justify" mb="48px">
          {detail.content}
        </Text>
        <If condition={detail.post_media.length > 0}>
          <Text mb="12px">Lampiran</Text>
          <Flex direction="column" gap="32px" mb="48px">
            <Flex gap="12px" wrap="wrap">
              {detail.post_media.map((img) => (
                <Image
                  src={img.media}
                  alt="Lampiran"
                  w="150px"
                  h="98px"
                  br="$rounded-sm"
                  autofit
                />
              ))}
            </Flex>
            {/* <If condition={!!detail.docs}>
              <Button icon={<DocumentText />} color="secondary">
                {getMediaName(detail.docs)}
              </Button>
            </If> */}
          </Flex>
        </If>
        {detail?.partner?.length > 0 && (
          <>
            <Text mb="12px">Bersama Warga</Text>
            <Grid columns="repeat(5, 1fr)" gap="20px" mb="48px">
              {detail.partner.map((item) => (
                <Flex align="center" gap="16px">
                  <Avatar src={item.photo} size={40} />
                  <Text weight="$medium">{item.name}</Text>
                </Flex>
              ))}
            </Grid>
          </>
        )}
        <Grid columns="repeat(2, 1fr)" gap="24px">
          <InfoItem label="Suka">
            <Flex align="center" gap="16px">
              <Text as="span" size="$sm">
                {detail._count.post_like}
              </Text>
              <Text
                size="$sm"
                as="span"
                color="$lightBlue500"
                css={{ cursor: "pointer" }}
                onClick={() => setShowLike(true)}
              >
                Lihat
              </Text>
            </Flex>
          </InfoItem>
          <InfoItem label="Komentar">
            <Flex align="center" gap="16px">
              <Text as="span" size="$sm">
                {detail._count.post_comment}
              </Text>
              <Text
                size="$sm"
                as="span"
                color="$lightBlue500"
                css={{ cursor: "pointer" }}
                onClick={() => setShowComment(true)}
              >
                Lihat
              </Text>
            </Flex>
          </InfoItem>
        </Grid>
      </Card>
      <ModalLike
        idPost={Number(storyId)}
        visible={showLike}
        onCancel={() => setShowLike(false)}
      />
      <ModalComment
        idPost={Number(storyId)}
        visible={showComment}
        onCancel={() => setShowComment(false)}
      />
    </>
  );
}

export default CardDetailCerita;
