import { ModalProps } from "antd";
import { Modal } from "components/Feedback";
import usePostStore from "features/users/stores/postStore";
// import postStore from "lib/stores/slices/post";
import { useEffect } from "react";
import Comment from "./Comment";

interface ModalCommentProps extends ModalProps {
  idPost: number;
}

function ModalComment({ idPost, visible, ...props }: ModalCommentProps) {
  const comments = usePostStore((state) => state.comments);
  const getComments = usePostStore((state) => state.getComments);

  useEffect(() => {
    if (idPost && visible) {
      getComments(idPost, {});
    }
  }, [idPost, visible]);

  const commentList = comments[idPost]?.data || [];

  return (
    <Modal
      title="Komentar"
      visible={visible}
      footer={null}
      width="900px"
      {...props}
    >
      {commentList.map((comment: any) => (
        <>
          <Comment
            key={comment.id}
            id={comment.id}
            name={comment.user.name}
            comment={comment.comment}
            date={comment.created_at}
            profile={comment.user.media}
          />
          {comment.replies.map((reply: any) => (
            <Comment
              key={reply.id}
              id={reply.id}
              name={reply.user.name}
              comment={reply.comment}
              date={reply.created_at}
              profile={reply.user.media}
              isReply
            />
          ))}
        </>
      ))}
    </Modal>
  );
}

export default ModalComment;
