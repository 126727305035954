import { Card, Flex, Text } from "components/Base";

interface LeaderListProps {
  rank: number;
  name: string;
  point: number;
}

function LeaderList({ rank, name, point }: LeaderListProps) {
  return (
    <Card p="0px" boxShadow={"$shadow-xs"} w="100%">
      <Flex align="center">
        <Flex
          br="8px 0 0 8px"
          w="74px"
          h="72px"
          backgroundColor="$primary100"
          color="$primary600"
          align="center"
          justify="center"
        >
          <Text as="span" weight="$semibold">
            {rank}
          </Text>
        </Flex>
        <Flex css={{ flex: 1 }} p="18.5px 40px 18.5px" justify="space-between">
          <Text fontSize="$xl" weight="$semibold">
            {name}
          </Text>
          <Text fontSize="$xl" weight="$semibold" color="$primary">
            {point} Point
          </Text>
        </Flex>
      </Flex>
    </Card>
  );
}

export default LeaderList;
