import { UseQueryResult, useQuery } from "@tanstack/react-query";
import report from "lib/api/report";
import { IReportPostItem } from "types/responses/reports";

function useReportDetail(id: number): UseQueryResult<IReportPostItem> {
  const detail = useQuery({
    queryKey: ["report-detail", { id }],
    queryFn: async () => {
      try {
        const res = await report.getReportPostDetail(id);

        if (!res.data) {
          return null;
        }

        const { data, error } = res.data;

        if (error) {
          throw new Error(error);
        }

        return data;
      } catch (e) {
        return {};
      }
    },
    enabled: !!id,
  });

  return detail;
}

export default useReportDetail;
