import { TableColumnsType } from "antd";
import { Tag } from "components/Base";
import ModalDelete from "global/Modals/ModalDelete";
import TableList from "global/TableList";
import ButtonAction from "global/TableList/ButtonAction";
import useDebounce from "hooks/useDebounce";
import { useEffect, useState } from "react";
import useCategoryStore from "../categoryStore";

interface TableCategoryProps {
  keyword?: string;
}

function TableCategory({ keyword }: TableCategoryProps) {
  const categories = useCategoryStore((s) => s.list);
  const total = useCategoryStore((s) => s.total);
  const getList = useCategoryStore((s) => s.getList);
  const deleteCategory = useCategoryStore((s) => s.deleteCategory);

  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [loaded, setLoaded] = useState(false);
  const debouncedKeyword = useDebounce(keyword, 500);

  // Modal state
  const [visible, setVisible] = useState(false);
  const [selectedId, setSelectedId] = useState(0);

  useEffect(() => {
    setLoaded(true);
  }, []);

  useEffect(() => {
    if (debouncedKeyword !== undefined && loaded) {
      const newPage = 1;
      setPage(newPage);

      const query = debouncedKeyword || undefined;
      getList({ page: newPage, limit, query });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedKeyword, loaded]);

  const handleChangePage = (currentPage: number, pageSize: number) => {
    setPage(currentPage);
    setLimit(pageSize);

    // re-fetch the data
    const query = debouncedKeyword || undefined;
    getList({ page: currentPage, limit: pageSize, query });
  };

  const handleShowDelete = (id: number) => {
    setSelectedId(id);
    setVisible(true);
  };

  const handleDelete = async () => {
    const del = await deleteCategory(selectedId);

    if (del) {
      // Re-fetch the list
      const query = debouncedKeyword || undefined;
      getList({ page, limit, query });

      // Close modal
      setVisible(false);
      setSelectedId(0);
    }
  };

  const columns: TableColumnsType<TableCategoryProps> = [
    {
      title: "Nama Kategori",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Status",
      dataIndex: "active",
      key: "status",
      render: (status: boolean) => (
        <Tag rounded color={status ? "green" : "red"}>
          {status ? "Aktif" : "Non Aktif"}
        </Tag>
      ),
    },
    {
      title: "",
      key: "action",
      render: (text: any, { id }: Record<string, any>) => (
        <ButtonAction
          id={id}
          url="/master-data/category"
          onDelete={() => handleShowDelete(id)}
        />
      ),
    },
  ];

  return (
    <>
      <TableList
        columns={columns}
        dataSource={categories}
        page={page}
        limit={limit}
        total={total}
        onChangePage={handleChangePage}
      />
      <ModalDelete
        visible={visible}
        onCancel={() => setVisible(false)}
        onDelete={handleDelete}
      />
    </>
  );
}

export default TableCategory;
