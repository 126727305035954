import { TableColumnsType } from "antd";
import TableList from "global/TableList";
import useDebounce from "hooks/useDebounce";
import useLoaded from "hooks/useLoaded";
import { useEffect, useMemo, useState } from "react";
import { BsTriangleFill } from "react-icons/bs";
import { FaMinus } from "react-icons/fa";
import { ILeaderboardItem } from "types/responses/point";
import { getColor } from "utils/general";
import useLeaderboardStore from "./leaderboardStore";

interface TableLeaderboardProps {
  keyword?: string;
}

function TableLeaderboard({ keyword }: TableLeaderboardProps) {
  const [list, total, getList] = useLeaderboardStore((s) => [
    s.list,
    s.total,
    s.getLeaderboard,
  ]);

  const [page, setPage] = useState<number>(1);
  const [limit, setLimit] = useState<number>(10);

  useEffect(() => {
    // Get list upon first render
    getList();
  }, []);

  useEffect(() => {
    // Reset page upon change keyword
    setPage(1);
  }, [keyword]);

  const handleChangePage = (currentPage: number, pageSize: number) => {
    setPage(currentPage);
    setLimit(pageSize);
  };

  const columns: TableColumnsType<TableLeaderboardProps> = [
    {
      title: "Rank",
      key: "rank",
      render: (_, __, index) => (page - 1) * limit + (index + 1),
      width: 50,
    },
    {
      title: "Nama",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Point",
      dataIndex: "point",
      key: "point",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (status) => {
        if (status === "up") {
          return <BsTriangleFill color={getColor("green500")} />;
        }

        if (status === "down") {
          return (
            <BsTriangleFill
              color={getColor("red500")}
              style={{ transform: "rotate(180deg)" }}
            />
          );
        }

        return <FaMinus color={getColor("orange500")} />;
      },
    },
  ];

  const filteredData = useMemo(() => {
    if (!keyword) return list;

    return list.filter((item) =>
      item.name.toLowerCase().includes(keyword.toLowerCase()),
    );
  }, [list, keyword]);

  return (
    <TableList
      columns={columns}
      dataSource={filteredData}
      rowKey={(record) => record.id_user}
      page={page}
      limit={limit}
      total={total}
      onChangePage={handleChangePage}
    />
  );
}

export default TableLeaderboard;
