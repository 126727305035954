import produce from "immer";
import story from "lib/api/story";
import { GetListParams } from "types/api";
import create from "zustand";

interface StoryStore {
  all: any[];
  user: Record<string, any>;
  total: number;
  story: Record<string, any>;
  getAll: () => void;
  getByUser: (id: number, params: GetListParams) => void;
  getDetail: (id: number) => void;
  deleteStory: (id: number) => Promise<boolean>;
}

const useStoryStore = create<StoryStore>((set) => ({
  all: [],
  user: {},
  total: 0,
  story: {},
  getAll: async () => {
    const res = await story.getAllStory();

    if (!res.data) {
      return;
    }

    const { data, error } = res.data;

    if (error) {
      return;
    }

    set({ all: data });
  },
  getByUser: async (id, params) => {
    const res = await story.getUserStory(id, params);

    if (!res.data) {
      return;
    }

    const { data, error } = res.data;

    if (error) {
      return;
    }

    set((state) =>
      produce(state, (draft) => {
        draft.user[id] = data.data;
        draft.total = data.total;
      }),
    );
  },
  getDetail: async (id) => {
    const res = await story.getDetailStory(id);

    if (!res.data) {
      return;
    }

    const { data, error } = res.data;

    if (error) {
      return;
    }

    set((state) =>
      produce(state, (draft) => {
        draft.story[id] = data;
      }),
    );
  },
  deleteStory: async (id) => {
    const res = await story.delete(id);

    if (!res.data) {
      return false;
    }

    const { error } = res.data;

    if (error) {
      return false;
    }

    return true;
  },
}));

export default useStoryStore;
