import { Avatar } from "antd";
import { Flex, Text } from "components/Base";
import { formatDistanceToNow } from "date-fns";
import localeId from "date-fns/locale/id";
import { handleErrorImage } from "utils/image";

interface CommentProps {
  id: number;
  name: string;
  profile: string;
  comment: string;
  date: string;
  isReply?: boolean;
}

function Comment({ name, profile, comment, date, isReply }: CommentProps) {
  return (
    <Flex
      alignItems="flex-start"
      gap="12px"
      css={{ ml: isReply ? "54px" : "0px" }}
      w={isReply ? "calc(100% - 54px)" : "100%"}
      mb="16px"
    >
      <Avatar
        size={41}
        src={
          <img src={profile} alt={name} onError={(e) => handleErrorImage(e)} />
        }
      />
      <Flex
        border="solid 2px"
        borderColor="$coolGray200"
        br="$rounded-md"
        p="8px"
        direction="column"
        w="calc(100% - 54px)"
      >
        <Flex w="100%" justify="space-between">
          <Text color="$black" weight="$bold" size="$md">
            {name}
          </Text>
          <Text color="$coolGray400">
            {formatDistanceToNow(new Date(date), { locale: localeId })}
          </Text>
        </Flex>
        <Text color="$coolGray500">{comment}</Text>
      </Flex>
    </Flex>
  );
}

export default Comment;
