import { TableProps, TableColumnsType } from "antd";
import { Tag, Flex, Button } from "components/Base";
import ModalDelete from "global/Modals/ModalDelete";
import TableList from "global/TableList";
import useDebounce from "hooks/useDebounce";
// import adminStore from "lib/stores/slices/admin";
import { useEffect, useState } from "react";
import { FaEdit } from "react-icons/fa";
import { HiEye, HiTrash } from "react-icons/hi";
import { Link } from "react-router-dom";
import { getRandomColor } from "utils/general";
import adminStore from "../adminStore";
import { AdminData } from "../types";

interface TableAdminProps extends TableProps<AdminData> {
  keyword?: string;
  [key: string]: any;
}

function TableAdmin({ keyword }: TableAdminProps) {
  const list = adminStore((state) => state.list);
  const total = adminStore((state) => state.total);
  const getList = adminStore((state) => state.getList);
  const deleteAdmin = adminStore((state) => state.deleteAdmin);

  // Table state
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [loaded, setLoaded] = useState(false);
  const debouncedKeyword = useDebounce(keyword, 500);

  // Modal delete state
  const [visible, setVisible] = useState(false);
  const [selectedId, setSelectedId] = useState(0);

  useEffect(() => {
    setLoaded(true);
    const query = debouncedKeyword || undefined;
    getList({ page, limit, query });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (debouncedKeyword !== undefined && loaded) {
      const newPage = 1;
      setPage(newPage);

      getList({ page: newPage, limit, query: debouncedKeyword });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedKeyword]);

  const handleChangePage = (currentPage: number, pageSize: number) => {
    setPage(currentPage);
    setLimit(pageSize);

    // re-fetch the data
    const query = debouncedKeyword || undefined;
    getList({ page: currentPage, limit: pageSize, query });
  };

  const handleShowDelete = (id: number) => {
    setSelectedId(id);
    setVisible(true);
  };

  const handleDelete = async () => {
    const del = await deleteAdmin(selectedId);

    if (del) {
      // Re-fetch the list
      const query = debouncedKeyword || undefined;
      getList({ page, limit, query });

      // Close modal
      setVisible(false);
      setSelectedId(0);
    }
  };

  const columns: TableColumnsType<TableAdminProps> = [
    {
      title: "Nama",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "No. Handphone",
      dataIndex: "phone",
      key: "phone",
    },
    {
      title: "Tanggal Buat",
      dataIndex: "created_at",
      key: "phone",
    },
    {
      title: "Role",
      key: "role",
      // @ts-ignore
      render: (_, { role }: AdminData) => (
        <Tag color={getRandomColor()} rounded>
          {role.name}
        </Tag>
      ),
    },
    {
      title: "Status",
      key: "status",
      dataIndex: "active",
      render: (status: boolean) => (
        <Tag rounded color={status ? "green" : "red"}>
          {status ? "Aktif" : "Non Aktif"}
        </Tag>
      ),
    },
    {
      title: "",
      key: "action",
      // @ts-ignore
      render: (_: any, { id }: AdminData) => (
        <Flex alignItems="center" gap="8px">
          <Link to={`/user-management/detail/${id}`}>
            <Button icon={<HiEye size={16} />} />
          </Link>
          <Link to={`/user-management/edit/${id}`}>
            <Button icon={<FaEdit size={16} />} />
          </Link>
          <Button
            onClick={() => handleShowDelete(id)}
            icon={<HiTrash size={16} />}
          />
        </Flex>
      ),
    },
  ];

  return (
    <>
      <TableList
        columns={columns}
        dataSource={list}
        page={page}
        limit={limit}
        total={total}
        onChangePage={handleChangePage}
      />
      <ModalDelete
        visible={visible}
        onDelete={handleDelete}
        onCancel={() => setVisible(false)}
      />
    </>
  );
}

export default TableAdmin;
