import DetailUser from "features/users/detail-user";
import MainLayout from "global/MainLayout";

function DetailUserPage() {
  return (
    <MainLayout>
      <DetailUser />
    </MainLayout>
  );
}

export default DetailUserPage;
