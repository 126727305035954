import { Button, Flex, Text } from "components/Base";
import If from "global/ErrorHandlers/If";
import { Link } from "react-router-dom";

interface StatCardProps {
  label: string;
  value: any;
  url?: string;
  color?: string;
}

function StatCard({ label, value, url = "", color }: StatCardProps) {
  const cardColor = `$${color}500`;
  const buttonColor = `$${color}400`;
  return (
    <Flex
      direction="column"
      br="$rounded-2xl"
      gap="20px"
      p="20px"
      align="center"
      backgroundColor={cardColor}
    >
      <Text
        fontSize="$2xl"
        color="$white"
        width="100%"
        weight="$bold"
        align="left"
      >
        {label}
      </Text>
      <Text fontSize="$4xl" weight="$bold" color="$white">
        {value}
      </Text>
      <If condition={!!url}>
        <Link to={url}>
          <Button
            shape="circle"
            css={{
              border: "none",
              backgroundColor: buttonColor,
              color: "$white",
              transition: "all 0.2s ease-in-out",

              "&:hover": {
                backgroundColor: `$${color}300`,
              },
            }}
            size="large"
          >
            <Text as="span" weight="$bold" px="10px" color="$white">
              Lihat Semua
            </Text>
          </Button>
        </Link>
      </If>
    </Flex>
  );
}

export default StatCard;
