import { message } from "antd";
import PageTitle from "global/PageTitle";
import admin from "lib/api/admin";
import { useNavigate } from "react-router-dom";
import { notifySuccess } from "utils/notification";
import FormUser from "./components/FormUser";

function AddUser() {
  const navigate = useNavigate();

  const handleSubmit = async (data: any) => {
    const res = await admin.add(data);
    if (!res.data) {
      message.error("Gagal menambahkan admin");
      return;
    }
    const { error } = res.data;
    if (error) {
      message.error(error);
      return;
    }
    notifySuccess({
      message: "Berhasil menambahkan admin",
    });
    // Redirect to list page
    navigate("/user-management");
  };

  return (
    <>
      <PageTitle backUrl="/user-management" title="Menambahkan User Admin" />
      <FormUser onSubmit={handleSubmit} />
    </>
  );
}

export default AddUser;
