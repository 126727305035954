import PointHistory from "features/users/point-history";
import MainLayout from "global/MainLayout";

function PointHistoryPage() {
  return (
    <MainLayout>
      <PointHistory />
    </MainLayout>
  );
}

export default PointHistoryPage;
