import { useEffect, useState } from "react";

function useLoaded() {
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    setLoaded(true);

    return () => {
      setLoaded(false);
    };
  }, []);

  return loaded;
}

export default useLoaded;
