import { message } from "antd";
import PageTitle from "global/PageTitle";
import config from "lib/api/config";
import { useNavigate } from "react-router-dom";
import { notifySuccess } from "utils/notification";
import FormFAQ from "./components/FormFAQ";

function AddFAQ() {
  const navigate = useNavigate();

  const handleSubmit = async (data: any) => {
    const res = await config.addFaq(data);

    if (!res.data) {
      message.error("Gagal menambahkan FAQ");
      return;
    }

    const { error } = res.data;

    if (error) {
      message.error(error);
      return;
    }

    notifySuccess({
      message: "Berhasil menambahkan FAQ",
    });

    // Redirect to list page
    navigate("/faq");
  };

  return (
    <>
      <PageTitle title="Menambahkan FAQ" backUrl="/faq" />
      <FormFAQ onSubmit={handleSubmit} />
    </>
  );
}

export default AddFAQ;
