import request from "configs/request";
import { GetListParams, APIPromiseResponse } from "types/api";

const URL = "/user";

const getUsers = (params: GetListParams): APIPromiseResponse => {
  return request.get(`${URL}/list`, { params });
};

const getUserDetail = (id: number): APIPromiseResponse => {
  return request.get(`${URL}/detail/${id}`);
};

const deleteUser = (id: number): APIPromiseResponse => {
  return request.delete(`${URL}/delete/${id}`);
};

const resetPassword = (id: number): APIPromiseResponse => {
  return request.post(`${URL}/reset-password/${id}`);
};

const toggleVerify = (id: number, verified: boolean): APIPromiseResponse => {
  return request.post(`${URL}/verify/${id}`, { verified });
};

export default {
  getUsers,
  getUserDetail,
  delete: deleteUser,
  resetPassword,
  toggleVerify,
};
