import AddFAQ from "features/faq/add-faq";
import MainLayout from "global/MainLayout";

function AddFAQPage() {
  return (
    <MainLayout>
      <AddFAQ />
    </MainLayout>
  );
}

export default AddFAQPage;
