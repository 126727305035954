import EditLevel from "features/level-management/edit-level";
import MainLayout from "global/MainLayout";

function EditLevelPage() {
  return (
    <MainLayout>
      <EditLevel />
    </MainLayout>
  );
}

export default EditLevelPage;
