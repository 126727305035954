/* eslint-disable max-len */
import ReportsPage from "pages/7d/reports";
import DetailReportPage from "pages/7d/reports/detail-report";
import Step7DPage from "pages/7d/steps";
import AddStep7DPage from "pages/7d/steps/add-step";
import DetailStep7DPage from "pages/7d/steps/detail-step";
import EditStep7DPage from "pages/7d/steps/edit-step";
import AboutConfigPage from "pages/about";
import ContactPage from "pages/contact";
import EditContactPage from "pages/contact/edit-contact";
import DashboardPage from "pages/dashboard";
import FAQPage from "pages/faq";
import AddFAQPage from "pages/faq/add-faq";
import DetailFAQPage from "pages/faq/detail-faq";
import EditFAQPage from "pages/faq/edit-faq";
import ForgotPasswordPage from "pages/forgot-password";
import LeaderboardPage from "pages/leaderboard";
import LevelPage from "pages/level-management";
import EditLevelPage from "pages/level-management/edit-level";
import LoginPage from "pages/login";
import LogoutPage from "pages/logout";
import MasterCategoryPage from "pages/master-data/category";
import AddMasterCategoryPage from "pages/master-data/category/add-category";
import DetailMasterCategoryPage from "pages/master-data/category/detail-category";
import EditMasterCategoryPage from "pages/master-data/category/edit-category";
import NotFoundPage from "pages/not-found";
import NotificationPage from "pages/notification";
import AddNotificationPage from "pages/notification/add-notification";
import DetailNotificationPage from "pages/notification/detail-notification";
import EditNotificationPage from "pages/notification/edit-notification";
import ReportPostPage from "pages/report-management/post";
import DetailReportPostPage from "pages/report-management/post/detail";
import ReportStoryPage from "pages/report-management/story";
import RoleManagementPage from "pages/role-management";
import AddRolePage from "pages/role-management/add-role";
import DetailRolePage from "pages/role-management/detail-role";
import EditRolePage from "pages/role-management/edit-role";
import SetPasswordPage from "pages/set-password";
import UserManagementPage from "pages/user-management";
import AddUserPage from "pages/user-management/add-user";
import DetailAdminPage from "pages/user-management/detail-user";
import EditUserPage from "pages/user-management/edit-user";
import UsersPage from "pages/users";
import CeritaDesaPage from "pages/users/cerita-desa";
import DetailCeritaDesaPage from "pages/users/detail-cerita-desa";
import DetailUserPage from "pages/users/detail-user";
import PointHistoryPage from "pages/users/point-history";
import UserStoryPage from "pages/users/story";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import PrivateRoute from "./PrivateRoute";
import RestrictedRoute from "./RestrictedRoute";

function AppRoutes() {
  return (
    <Router>
      <Routes>
        <Route path="/logout" element={<LogoutPage />} />

        <Route element={<RestrictedRoute />}>
          <Route path="/" element={<LoginPage />} />
          <Route path="/login" element={<LoginPage />} />
          <Route path="/forgot-password" element={<ForgotPasswordPage />} />
          <Route path="/set-password" element={<SetPasswordPage />} />
          <Route path="/reset-password" element={<SetPasswordPage />} />
        </Route>

        {/* Private routes will be wrapped here */}
        <Route element={<PrivateRoute />}>
          <Route path="/dashboard" element={<DashboardPage />} />

          {/* </Route> */}

          {/* Admin/User management */}
          <Route path="/user-management" element={<UserManagementPage />} />
          <Route path="/user-management/add" element={<AddUserPage />} />
          <Route path="/user-management/edit/:id" element={<EditUserPage />} />
          <Route
            path="/user-management/detail/:id"
            element={<DetailAdminPage />}
          />

          <Route path="/roles" element={<RoleManagementPage />} />
          <Route path="/roles/add" element={<AddRolePage />} />
          <Route path="/roles/edit/:id" element={<EditRolePage />} />
          <Route path="/roles/detail/:id" element={<DetailRolePage />} />

          {/* Master data category page */}
          <Route
            path="/master-data/category"
            element={<MasterCategoryPage />}
          />
          <Route
            path="/master-data/category/add"
            element={<AddMasterCategoryPage />}
          />
          <Route
            path="/master-data/category/edit/:id"
            element={<EditMasterCategoryPage />}
          />
          <Route
            path="/master-data/category/detail/:id"
            element={<DetailMasterCategoryPage />}
          />

          {/* Report management */}
          <Route path="/report-post" element={<ReportPostPage />} />
          <Route
            path="/report-post/detail/:id"
            element={<DetailReportPostPage />}
          />

          <Route path="/report-story" element={<ReportStoryPage />} />
          <Route path="/report-story/detail/:id" element={<ReportPostPage />} />

          {/* User */}
          <Route path="/leaderboard" element={<LeaderboardPage />} />

          <Route path="/users" element={<UsersPage />} />
          <Route path="/users/detail/:id" element={<DetailUserPage />} />
          <Route
            path="/users/detail/:id/point-history"
            element={<PointHistoryPage />}
          />
          <Route path="/users/detail/:id/story" element={<UserStoryPage />} />
          <Route
            path="/users/detail/:id/cerita-desa"
            element={<CeritaDesaPage />}
          />
          <Route
            path="/users/detail/:id/cerita-desa/:storyId"
            element={<DetailCeritaDesaPage />}
          />

          {/* Notification Setting  */}
          <Route path="/notification" element={<NotificationPage />} />
          <Route path="/notification/add" element={<AddNotificationPage />} />
          <Route
            path="/notification/edit/:id"
            element={<EditNotificationPage />}
          />
          <Route
            path="/notification/detail/:id"
            element={<DetailNotificationPage />}
          />

          {/* 7D */}
          <Route path="/step-7d" element={<Step7DPage />} />
          <Route path="/step-7d/add" element={<AddStep7DPage />} />
          <Route path="/step-7d/edit/:id" element={<EditStep7DPage />} />
          <Route path="/step-7d/detail/:id" element={<DetailStep7DPage />} />

          <Route path="/report-7d" element={<ReportsPage />} />
          {/* <Route path="/report-7d/add" element={<AddStep7DPage />} />
        <Route path="/report-7d/edit/:id" element={<EditStep7DPage />} /> */}
          <Route path="/report-7d/detail/:id" element={<DetailReportPage />} />

          {/* Level management */}
          <Route path="/levels" element={<LevelPage />} />
          <Route path="/levels/edit/:id" element={<EditLevelPage />} />

          {/* Config */}
          <Route path="/about" element={<AboutConfigPage />} />

          <Route path="/faq" element={<FAQPage />} />
          <Route path="/faq/add" element={<AddFAQPage />} />
          <Route path="/faq/edit/:id" element={<EditFAQPage />} />
          <Route path="/faq/detail/:id" element={<DetailFAQPage />} />

          <Route path="/contact" element={<ContactPage />} />
          <Route path="/contact/edit/:id" element={<EditContactPage />} />
        </Route>

        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    </Router>
  );
}

export default AppRoutes;
