import DetailFAQ from "features/faq/detail-faq";
import MainLayout from "global/MainLayout";

function DetailFAQPage() {
  return (
    <MainLayout>
      <DetailFAQ />
    </MainLayout>
  );
}

export default DetailFAQPage;
