import { TableColumnsType } from "antd";
import { Tag } from "components/Base";
import ModalDelete from "global/Modals/ModalDelete";
import TableList from "global/TableList";
import ButtonAction from "global/TableList/ButtonAction";
import { TableBaseProps } from "global/TableList/types";
import useDebounce from "hooks/useDebounce";
import useLoaded from "hooks/useLoaded";
import { useEffect, useState } from "react";
import useFAQStore from "../faqStore";

interface TableFAQProps extends TableBaseProps {}

function TableFAQ({ keyword }: TableFAQProps) {
  const [list, total, getList, deleteFaq] = useFAQStore((s) => [
    s.list,
    s.total,
    s.getList,
    s.deleteFAQ,
  ]);

  const loaded = useLoaded();

  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const debouncedKeyword = useDebounce(keyword, 500);

  const [visible, setVisible] = useState(false);
  const [selectedId, setSelectedId] = useState(0);

  useEffect(() => {
    if (debouncedKeyword !== undefined && loaded) {
      const newPage = 1;
      setPage(newPage);

      const query = debouncedKeyword || undefined;
      getList({ page: newPage, limit, query });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedKeyword, loaded]);

  const handleChangePage = (page: number, limit: number) => {
    setPage(page);
    setLimit(limit);

    const query = debouncedKeyword || undefined;
    getList({ page, limit, query });
  };

  const handleShowDelete = (id: number) => {
    setSelectedId(id);
    setVisible(true);
  };

  const handleDelete = async () => {
    const del = await deleteFaq(selectedId);

    if (del) {
      const query = debouncedKeyword || undefined;
      getList({ page, limit, query });

      // await deleteAdmin({ id: selected
      setVisible(false);
      setSelectedId(0);
    }
  };

  const columns: TableColumnsType<TableFAQProps> = [
    {
      title: "Judul",
      dataIndex: "title",
      key: "name",
      width: "25%",
    },
    {
      title: "Deskripsi",
      dataIndex: "content",
      key: "description",
      width: "45%",
    },
    {
      title: "Status",
      dataIndex: "active",
      key: "status",
      render: (status: boolean) => (
        <Tag rounded color={status ? "green" : "red"}>
          {status ? "Aktif" : "Non Aktif"}
        </Tag>
      ),
    },
    {
      title: "",
      key: "action",
      render: (_, { id }: any) => (
        <ButtonAction
          id={id}
          url="/faq"
          onDelete={() => handleShowDelete(id)}
        />
      ),
    },
  ];

  return (
    <>
      <TableList
        dataSource={list}
        columns={columns}
        total={total}
        page={page}
        limit={limit}
        onChangePage={handleChangePage}
      />
      <ModalDelete
        visible={visible}
        onCancel={() => setVisible(false)}
        onDelete={handleDelete}
      />
    </>
  );
}

export default TableFAQ;
