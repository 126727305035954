import produce from "immer";
import categoryApi from "lib/api/category";
import { GetListParams } from "types/api";
import { ICategoryItem } from "types/responses/category";
import create from "zustand";

interface CategoryStore {
  list: Array<ICategoryItem>;
  detail: Record<string, ICategoryItem>;
  total: number;
  getList: (params: GetListParams) => void;
  getDetail: (id: number) => void;
  deleteCategory: (id: number) => Promise<boolean>;
}

const useCategoryStore = create<CategoryStore>((set, get) => ({
  list: [],
  detail: {},
  total: 0,
  getList: async ({ page = 1, limit = 10, query = undefined }) => {
    const res = await categoryApi.getList({ page, limit, query });

    if (!res.data) {
      return;
    }

    const { data, error } = res.data;

    if (error) {
      return;
    }

    set({ list: data.data, total: data.total });
  },
  getDetail: async (id: number) => {
    const res = await categoryApi.getDetail(id);

    if (!res.data) {
      return;
    }

    const { data, error } = res.data;

    if (error) {
      return;
    }

    return set(
      produce((state) => {
        state.detail[id] = data;
      }),
    );
  },
  deleteCategory: async (id: number) => {
    const res = await categoryApi.deleteCategory(id);

    if (!res.data) {
      return false;
    }

    const { error } = res.data;

    if (error) {
      return false;
    }

    return true;
  },
}));

export default useCategoryStore;
