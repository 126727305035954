import { Modal } from "antd";
import If from "global/ErrorHandlers/If";
import ReactInstaStories from "react-insta-stories";
import { StoryItem } from "types/responses/story";

interface ModalStoryProps {
  visible: boolean;
  onCancel: () => void;
  story: StoryItem | null;
  onStoryEnd: () => void;
}

function ModalStory({ visible, onCancel, story, onStoryEnd }: ModalStoryProps) {
  const userStories = story?.stories || [];

  const stories = userStories.map((s) => ({
    url: s.media,
    type: s.type,
    header: {
      heading: story?.user?.name || "",
      subheading: "",
      profileImage: story?.user?.photo || "",
    },
  }));

  return (
    <Modal
      open={visible}
      onCancel={onCancel}
      width={405}
      footer={null}
      className="modal-story"
      destroyOnClose
    >
      <If condition={!!story}>
        <ReactInstaStories
          width="100%"
          stories={stories}
          keyboardNavigation
          onAllStoriesEnd={onStoryEnd}
        />
      </If>
    </Modal>
  );
}

export default ModalStory;
