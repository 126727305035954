import request from "configs/request";
import { APIPromiseResponse, GetListParams } from "types/api";

const URL = "/story";

const getAllStory = (): APIPromiseResponse => {
  return request.get(`${URL}/all`);
};

const getDetailStory = (id: number): APIPromiseResponse => {
  return request.get(`${URL}/detail/${id}`);
};

const getUserStory = (
  id: number,
  params: GetListParams,
): APIPromiseResponse => {
  return request.get(`${URL}/user/${id}`, { params });
};

const deleteStory = (id: number): APIPromiseResponse => {
  return request.delete(`${URL}/delete/${id}`);
};

export default {
  getAllStory,
  getDetailStory,
  getUserStory,
  delete: deleteStory,
};
