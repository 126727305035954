import { Button, Card, Flex } from "components/Base";
import CardDetailCerita from "features/users/components/CardDetailCerita";
import If from "global/ErrorHandlers/If";
import PageTitle from "global/PageTitle";
import { useState } from "react";
import { useParams } from "react-router-dom";
import useApproveRejectReport from "./useApproveRejectReport";
import useReportDetail from "./useReportDetail";
import ModalApprove from "../components/modals/ModalApprove";
import ModalReject from "../components/modals/ModalReject";

function DetailReportPost() {
  const { id } = useParams();
  const { data, isLoading, refetch } = useReportDetail(Number(id));
  const { approveReport, rejectReport } = useApproveRejectReport();

  const [showApprove, setShowApprove] = useState(false);
  const [showReject, setShowReject] = useState(false);

  const handleApprove = () => {
    approveReport.mutate(Number(id), {
      onSuccess: () => {
        refetch();
        setShowApprove(false);
      },
    });
  };

  const handleReject = () => {
    rejectReport.mutate(Number(id), {
      onSuccess: () => {
        refetch();
        setShowReject(false);
      },
    });
  };

  if (isLoading) {
    return <p>Loading...</p>;
  }

  if (!data) {
    return null;
  }

  return (
    <>
      <PageTitle title="Detail" backUrl="/report-post" />
      <CardDetailCerita storyId={data.post.id} detail={data.post} />
      <If condition={data.report_status === "pending"}>
        <Card mt="16px">
          <Flex justifyContent="flex-end" gap="16px">
            <Button color="danger" onClick={() => setShowReject(true)}>
              Tolak
            </Button>
            <Button color="primary" onClick={() => setShowApprove(true)}>
              Setujui
            </Button>
          </Flex>
        </Card>
      </If>
      <ModalApprove
        name={data.user.name}
        reportType="post"
        open={showApprove}
        onOk={handleApprove}
        onCancel={() => setShowApprove(false)}
      />
      <ModalReject
        reportType="post"
        open={showReject}
        onOk={handleReject}
        onCancel={() => setShowReject(false)}
      />
    </>
  );
}

export default DetailReportPost;
