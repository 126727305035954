import CardLogin from "components/Auth/CardLogin";
import { Button, Text } from "components/Base";
import { Input } from "components/Form";
import useInput from "hooks/useInput";
import authApi from "lib/api/auth";
import { HiOutlineArrowLeft as ArrowLeft } from "react-icons/hi";
import { Link } from "react-router-dom";
import { notifyError, notifySuccess } from "utils/notification";

function ForgotPassword() {
  const [email, setEmail] = useInput("");

  const submitForgotPassword = async () => {
    const forgot = await authApi.forgotPassword(email);

    if (!forgot.data) {
      return null;
    }

    const { error } = forgot.data;

    if (error) {
      return null;
    }

    return true;
  };

  const handleSubmit = async (e: any) => {
    e?.preventDefault();
    if (!email) {
      return;
    }

    // post forgot password
    const forgot = await submitForgotPassword();

    if (!forgot) {
      notifyError({
        message: "Terjadi kesalahan",
        description: "Silahkan coba lagi",
      });
      return;
    }

    notifySuccess({
      message: "Berhasil mengirim email",
      description: "Silakan cek email Kamu untuk melakukan reset kata sandi",
    });
  };

  return (
    <CardLogin title="Lupa Kata Sandi">
      <form onSubmit={handleSubmit}>
        <Input
          value={email}
          onChange={setEmail}
          type="email"
          label="Email"
          placeholder="Ketikan email"
        />
        <Button type="primary" htmlType="submit" block css={{ mb: "12px" }}>
          Reset kata sandi
        </Button>
      </form>
      <Link to="/login">
        <Text
          color="$primary"
          size="$sm"
          weight="$medium"
          align="left"
          display="flex"
          alignItems="center"
        >
          <ArrowLeft style={{ marginRight: "10px" }} />
          Login sekarang
        </Text>
      </Link>
    </CardLogin>
  );
}

export default ForgotPassword;
