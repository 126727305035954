import Levels from "features/level-management";
import MainLayout from "global/MainLayout";

function LevelPage() {
  return (
    <MainLayout>
      <Levels />
    </MainLayout>
  );
}

export default LevelPage;
