import DetailMasterCategory from "features/master-data/category/detail-category";
import MainLayout from "global/MainLayout";

function DetailMasterCategoryPage() {
  return (
    <MainLayout>
      <DetailMasterCategory />
    </MainLayout>
  );
}

export default DetailMasterCategoryPage;
