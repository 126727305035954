import { message } from "antd";
import PageTitle from "global/PageTitle";
import stepApi from "lib/api/7ds";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { notifySuccess } from "utils/notification";
import useStepStore from "./stepStore";
import FormSteps from "../components/FormSteps";

function EditStep7d() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [step, getDetail] = useStepStore((s) => [s.detail, s.getDetail]);

  useEffect(() => {
    if (id) {
      getDetail(Number(id));
    }
  }, [id]);

  const handleSubmit = async (data: any) => {
    const res = await stepApi.updateSteps(Number(id), data);

    if (!res.data) {
      message.error("Gagal mengubah 7D");
      return;
    }

    const { error } = res.data;

    if (error) {
      message.error(error);
      return;
    }

    notifySuccess({
      message: "Berhasil mengubah 7D step",
    });

    // Redirect to list page
    navigate("/step-7d");
  };

  if (!id || !step[id]) {
    return null;
  }

  const detail = step[id];

  return (
    <>
      <PageTitle title="Mengubah Data 7D" backUrl="/step-7d" />
      <FormSteps initialValues={detail} onSubmit={handleSubmit} />
    </>
  );
}

export default EditStep7d;
