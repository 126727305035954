import { Card, Flex, Grid, Tag } from "components/Base";
import { InfoItem } from "components/Info";
import { format } from "date-fns";
import PageTitle from "global/PageTitle";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import useNotificationStore from "./notifStore";

function DetailNotification() {
  const [detail, getDetail] = useNotificationStore((s) => [
    s.detail,
    s.getDetail,
  ]);
  const { id } = useParams();

  useEffect(() => {
    if (id) {
      getDetail(Number(id));
    }
  }, [id]);

  if (!id || !detail[id]) {
    return null;
  }

  const notif = detail[id];

  return (
    <>
      <PageTitle title="Detail" backUrl="/notification" />
      <Card p="32px">
        <Grid columns="repeat(2, 1fr)" gap="24px">
          <Flex direction="column" gap="24px">
            <InfoItem label="Judul" value={notif.title} />
            <InfoItem label="Platform" value={notif.platform} />
            <InfoItem
              label="Waktu"
              value={format(new Date(notif.created_at), "HH:mm")}
            />
          </Flex>
          <Flex direction="column" gap="24px">
            <InfoItem label="Deskripsi" value={notif.description} />
            <InfoItem
              label="Tanggal"
              value={format(new Date(notif.created_at), "dd/MM/yyyy")}
            />
          </Flex>
        </Grid>
      </Card>
    </>
  );
}

export default DetailNotification;
