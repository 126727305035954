import FAQ from "features/faq";
import MainLayout from "global/MainLayout";

function FAQPage() {
  return (
    <MainLayout>
      <FAQ />
    </MainLayout>
  );
}

export default FAQPage;
