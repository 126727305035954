import request from "configs/request";
import { APIPromiseResponse, GetListParams } from "types/api";
import { IAddLevel, IEditLevel, ILevelItem } from "types/responses/level";

const URL = "/level";

const getList = (params: GetListParams): APIPromiseResponse => {
  return request.get(`${URL}/list`, { params });
};

const getDetail = (id: number): APIPromiseResponse<ILevelItem> => {
  return request.get(`${URL}/detail/${id}`);
};

const add = (data: IAddLevel): APIPromiseResponse => {
  return request.post(`${URL}/add`, data);
};

const update = (id: number, data: IEditLevel): APIPromiseResponse => {
  return request.put(`${URL}/update/${id}`, data);
};

const deleteLevel = (id: number): APIPromiseResponse => {
  return request.delete(`${URL}/delete/${id}`);
};

export default {
  getList,
  getDetail,
  add,
  update,
  delete: deleteLevel,
};
