import { message } from "antd";
import PageTitle from "global/PageTitle";
import config from "lib/api/config";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { notifySuccess } from "utils/notification";
import FormContact from "./components/FormContact";
import useContactStore from "./contactStore";

function EditContact() {
  const { id }: { id?: string } = useParams();
  const navigate = useNavigate();

  const [detail, getDetail] = useContactStore((s) => [s.detail, s.getDetail]);

  useEffect(() => {
    if (id) {
      getDetail(Number(id));
    }
  }, [id]);

  const handleSubmit = async (data: any) => {
    const res = await config.updateContact(Number(id), data);

    if (!res.data) {
      message.error("Gagal mengubah data kontak");
      return;
    }

    const { error } = res.data;

    if (error) {
      message.error(error);
      return;
    }

    notifySuccess({
      message: "Berhasil mengubah data kontak",
    });

    // Redirect to list page
    navigate("/contact");
  };

  if (!id || !detail[id]) {
    return null;
  }

  const contact = detail[id];

  return (
    <>
      <PageTitle title="Edit Bantuan" backUrl="/contact" />
      <FormContact onSubmit={handleSubmit} initialValues={contact} />
    </>
  );
}

export default EditContact;
