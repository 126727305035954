import produce from "immer";
import post, { GetPostParams } from "lib/api/post";
import { GetListParams } from "types/api";
import { IPostItem } from "types/responses/post";
import create from "zustand";

interface PostStore {
  list: any[];
  user: Record<string, any>;
  total: number;
  detail: Record<string, IPostItem>;
  likes: Record<string, any>;
  comments: Record<string, any>;
  getByUser: (params: GetPostParams) => void;
  getDetail: (id: number) => void;
  getLikes: (id: number, params: GetListParams) => void;
  getComments: (id: number, params: GetListParams) => void;
  deletePost: (id: number) => Promise<boolean>;
}

const usePostStore = create<PostStore>((set) => ({
  list: [],
  user: {},
  total: 0,
  detail: {},
  likes: {},
  comments: {},
  getByUser: async (params) => {
    const res = await post.getPosts(params);

    if (!res.data) {
      return;
    }

    const { data, error } = res.data;

    if (error) {
      return;
    }

    set((state) =>
      produce(state, (draft) => {
        if (params.id_user) {
          draft.user[params.id_user] = data.data;
        } else {
          draft.list = data.data;
        }
        draft.total = data.total;
      }),
    );
  },
  getDetail: async (id) => {
    const res = await post.getPostDetail(id);

    if (!res.data) {
      return;
    }

    const { data, error } = res.data;

    if (error) {
      return;
    }

    set((state) =>
      produce(state, (draft) => {
        draft.detail[id] = data;
      }),
    );
  },
  getLikes: async (id, params) => {
    const res = await post.getPostLikes(id, params);

    if (!res.data) {
      return;
    }

    const { data, error } = res.data;

    if (error) {
      return;
    }

    set((state) =>
      produce(state, (draft) => {
        draft.likes[id] = data;
      }),
    );
  },
  getComments: async (id, params) => {
    const res = await post.getPostComments(id, params);

    if (!res.data) {
      return;
    }

    const { data, error } = res.data;

    if (error) {
      return;
    }

    set((state) =>
      produce(state, (draft) => {
        draft.comments[id] = data;
      }),
    );
  },
  deletePost: async (id) => {
    const res = await post.delete(id);

    if (!res.data) {
      return false;
    }

    const { error } = res.data;

    if (error) {
      return false;
    }

    return true;
  },
}));

export default usePostStore;
