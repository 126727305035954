import request from "configs/request";
import { APIPromiseResponse } from "types/api";

const URL = "/stats";

const getStats = (): APIPromiseResponse => {
  return request.get(URL);
};

export default {
  getStats,
};
