import DetailStep7d from "features/7d/steps/detail-step";
import MainLayout from "global/MainLayout";

function DetailStep7DPage() {
  return (
    <MainLayout>
      <DetailStep7d />
    </MainLayout>
  );
}

export default DetailStep7DPage;
