import request from "configs/request";
import { APIPromiseResponse, GetListParams } from "types/api";

const REPORT_STORY_URL = "/report/story";
const REPORT_POST_URL = "/report/post";

const getReportPostList = (params: GetListParams): APIPromiseResponse => {
  return request.get(`${REPORT_POST_URL}/list`, { params });
};

const getReportPostDetail = (id: number): APIPromiseResponse => {
  return request.get(`${REPORT_POST_URL}/detail/${id}`);
};

const approveReportPost = (id: number): APIPromiseResponse => {
  return request.post(`${REPORT_POST_URL}/approve/${id}`);
};

const rejectReportPost = (id: number): APIPromiseResponse => {
  return request.post(`${REPORT_POST_URL}/reject/${id}`);
};

// Story

const getReportStoryList = (params: GetListParams): APIPromiseResponse => {
  return request.get(`${REPORT_STORY_URL}/list`, { params });
};

const approveReportStory = (id: number): APIPromiseResponse => {
  return request.post(`${REPORT_STORY_URL}/approve/${id}`);
};

const rejectReportStory = (id: number): APIPromiseResponse => {
  return request.post(`${REPORT_STORY_URL}/reject/${id}`);
};
export default {
  getReportPostList,
  getReportPostDetail,
  approveReportPost,
  rejectReportPost,

  getReportStoryList,
  approveReportStory,
  rejectReportStory,
};
