import UserStory from "features/users/story";
import MainLayout from "global/MainLayout";

function UserStoryPage() {
  return (
    <MainLayout>
      <UserStory />
    </MainLayout>
  );
}

export default UserStoryPage;
