import { Card } from "components/Base";
import InputSearch from "global/InputSearch";
import PageTitle from "global/PageTitle";
import useInput from "hooks/useInput";
import TableReportList from "./components/TableReportList";

function ReportPost() {
  const [query, setQuery] = useInput("");

  return (
    <>
      <PageTitle title="Report Management - Post" />
      <Card>
        <InputSearch value={query} onChange={setQuery} />
        <TableReportList keyword={query} />
      </Card>
    </>
  );
}

export default ReportPost;
