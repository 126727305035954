import request from "configs/request";
import { APIPromiseResponse, GetListParams } from "types/api";

const URL = "/points";

export interface GetPointParams extends GetListParams {
  from?: string;
  to?: string;
}

const getUserPointHistory = (
  id: number,
  params: GetPointParams,
): APIPromiseResponse => {
  return request.get(`${URL}/history/user/${id}`, { params });
};

const getLeaderboard = (): APIPromiseResponse => {
  return request.get(`${URL}/leaderboard`);
};

export default {
  getUserPointHistory,
  getLeaderboard,
};
