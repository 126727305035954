import request from "configs/request";
import { APIPromiseResponse, GetListParams } from "types/api";

const URL = "/post";

export interface GetPostParams extends GetListParams {
  from?: string;
  to?: string;
  id_user?: number;
}

const getPosts = (params: GetPostParams): APIPromiseResponse => {
  return request.get(`${URL}/list`, { params });
};

const getPostDetail = (id: number): APIPromiseResponse => {
  return request.get(`${URL}/detail/${id}`);
};

const getPostComments = (
  id: number,
  params: GetListParams,
): APIPromiseResponse => {
  return request.get(`${URL}/comments/${id}`, { params });
};

const getPostLikes = (
  id: number,
  params: GetListParams,
): APIPromiseResponse => {
  return request.get(`${URL}/likes/${id}`, { params });
};

const deletePost = (id: number): APIPromiseResponse => {
  return request.delete(`${URL}/delete/${id}`);
};

export default {
  getPosts,
  getPostDetail,
  getPostComments,
  getPostLikes,
  delete: deletePost,
};
