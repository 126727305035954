import AddStep7d from "features/7d/steps/add-step";
import MainLayout from "global/MainLayout";

function AddStep7DPage() {
  return (
    <MainLayout>
      <AddStep7d />
    </MainLayout>
  );
}

export default AddStep7DPage;
