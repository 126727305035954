import { Divider, message } from "antd";
import { Card, Button, Flex } from "components/Base";
import { Input, Switch } from "components/Form";
import roleStore from "features/role-management/roleStore";
import useInput from "hooks/useInput";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ItemGroup from "./ItemGroup";
import { mapModules } from "../../utils";

interface RoleFormProps {
  initialValues?: any;
  onSubmit: (values: any) => void;
  [key: string]: any;
}

function FormRole({ initialValues = null, onSubmit }: RoleFormProps) {
  const modules = roleStore((state) => state.modules);
  const getModule = roleStore((state) => state.getModules);

  const navigate = useNavigate();

  const [name, setName] = useInput("");
  const [active, setActive] = useState(true);
  const [features, setFeatures] = useState({});
  const [error, setError] = useState("");

  useEffect(() => {
    if (modules.length === 0) {
      getModule();
    }

    if (!initialValues) {
      if (modules.length > 0) {
        // @ts-ignore
        const mapped = mapModules(modules);

        setFeatures(mapped);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modules, initialValues]);

  // TODO: Handle if form used to edit data
  useEffect(() => {
    if (initialValues) {
      // Do something about it bruh
      setName({ target: { value: initialValues.name } });
      setActive(initialValues.active);
      const mapped = mapModules(initialValues.modules);
      setFeatures(mapped);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialValues]);

  const handleSubmit = (e: any) => {
    e?.preventDefault();
    if (!name) {
      message.error("Nama role harus diisi");
      setError("Nama harus diisi");
      return;
    }

    const data = {
      name,
      active,
      roles: Object.values(features),
    };

    onSubmit(data);
  };

  const handleCancel = () => {
    navigate("/admin/role");
  };

  const handleChangeFeature = (featId: string | number, role: any) => {
    setFeatures((prevState) => ({
      ...prevState,
      [featId]: {
        // @ts-ignore
        ...prevState[featId],
        access: role,
      },
    }));
  };

  return (
    <Card br="$rounded-lg" p="24px 32px">
      <form onSubmit={handleSubmit}>
        <Flex align="flex-start" gap="24px" mb="64px">
          <Input
            value={name}
            onChange={setName}
            label="Nama Role"
            required
            errorMessage={error}
          />
          <Switch
            checked={active}
            onChange={setActive}
            label="Status"
            required
            content="Aktif"
          />
        </Flex>
        {modules.map((item) => (
          <ItemGroup
            icon={item.icon || undefined}
            key={`group-${item.id}`}
            label={item.name}
            features={item.feature}
            values={features}
            onChangeItem={handleChangeFeature}
          />
        ))}
        <Divider style={{ margin: "8px 0 16px 0" }} />
        <Flex align="center" justify="flex-end" gap="16px">
          <Button htmlType="button" onClick={handleCancel}>
            Batalkan
          </Button>
          <Button htmlType="submit" type="primary">
            Simpan
          </Button>
        </Flex>
      </form>
    </Card>
  );
}

export default FormRole;
